import React from 'react';
import "./../styles.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const data = [
    {
        s_no: 1,
        title: "Keep and maintain record of all visitors strictly.",
    },
    {
        s_no: 2,
        title: "Visiting cards be issued to every visitors.",
    },
    {
        s_no: 3,
        title: "Every employee should display his/her Identity card on the Shoulder.",
    },
    {
        s_no: 4,
        title: "Raise the Boundary wall of the Institute upto 10 feet.",
    },
    {
        s_no: 5,
        title: "Ensure the safety/security of water as well as foods sell in the canteens of the schools, so that no one can mix/put the dangerous chemical/poison in the same.",
    },
    {
        s_no: 6,
        title: "All schools administration should know the names and addresses of the nearby hospitals so that they approach the same in emergency situation in less/minimum time period.",
    },
    {
        s_no: 7,
        title: "All schools administration must display the numbers of C.PI.C, Police help line, Rangers, Fire Brigade etc at Notice Boards of the school in order to contact an emergency.",
    },
    {
        s_no: 8,
        title: "All schools administration must furnish complete information regarding the number of students, number of teachers and Non-teaching staff to the relevant Police stations.",
    },
    {
        s_no: 9,
        title: "Get Rehearsal/Practices to the students and staff on emergency Alarm.",
    },
    {
        s_no: 10,
        title: "Appointment of General Staff and Chowkidar be made in the school after Police Verification.",
    },
    {
        s_no: 11,
        title: "Arrange installation of Walk through Gate at the main entrance of the schools.",
    },
    {
        s_no: 12,
        title: "Parking should not be allowed around outside the school premises.",
    },
    {
        s_no: 13,
        title: "C.C.TV Camera should be installed at schools by the experts.",
    },
    {
        s_no: 14,
        title: "Bio-Metric/Turnstile be arranges at the schools.",
    },
    {
        s_no: 15,
        title: "Get use of Weapon Training by the Police/Rangers.",
    },
    {
        s_no: 16,
        title: "Parents Teachers Association may be functionalize.",
    },
]

const generatePDF = async () => {
    const input = document.getElementById("print");
    html2canvas(input)
        .then((canvas) => {
            var imgData = canvas.toDataURL('image/png');

            /*
            Here are the numbers (paper width and height) that I found to work. 
            It still creates a little overlap part between the pages, but good enough for me.
            if you can find an official number from jsPDF, use them.
            */
            var imgWidth = 210;
            var pageHeight = 297;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('forma.pdf');

        });

}

const handleClick = async () => {
    await generatePDF();
}

const OpenDoc = () => {
    return (
        <>
            <div style={{ marginBottom: '10%' }}>
                <div style={{ margin: '2%', border: '2px solid #000' }}>
                    <div style={{ marginTop: '10%' }} className="my-application-header">
                        <span style={{ marginTop: '1%', textAlign: 'center', fontSize: '3vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                            <u>
                                COLLEGE EDUCATION DEPARTMENT <br />
                                GOVERNMENT FO SINDH
                            </u>
                        </span>
                    </div>
                    <div style={{ marginTop: '5%' }} className="my-application-header">
                        <span style={{ marginTop: '1%', textAlign: 'center', fontSize: '3vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                            <u>
                                R E G I S T R A T I O N <br />
                                F O R M "A"
                            </u>
                        </span>
                    </div>
                    <div style={{ marginTop: '20%' }} className="my-application-header">
                        <span style={{ marginTop: '1%', textAlign: 'center' }} className="my-application-header-title-full">
                            <img className="my-main-logo2" src="images/logo.png" />
                        </span>
                    </div>
                    <div style={{ marginTop: '20%' }} className="my-application-header">
                        <span style={{ marginTop: '1%', textAlign: 'center', fontSize: '3vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                            <u>
                                DIRECTORATE OF INSPECTION / REGISTRATION <br />
                                OF PRIVATE INSTITUTIONS SINDH
                            </u>
                        </span>
                    </div>
                    <div className="my-application-header">
                        <span style={{ width: '100%', marginTop: '3%', fontSize: '2.3vw', fontWeight: '500' }} className="my-application-header-title-full">
                            <u>
                                Ground Floor Govt. Woman College, Shahrah-e-Liaquat, Karachi.
                            </u>
                        </span>

                    </div>
                    <div style={{ marginBottom: '15%' }} className="my-application-header">
                        <span style={{ width: '100%', marginTop: '1%', fontSize: '2.3vw', fontWeight: '500' }} className="my-application-header-title-full">
                            Contact # 021-99217489-90
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OpenDoc;