import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CustomTextBox from './CustomTextBox';
import CustomSelectBox from './CustomSelectBox';
import axios from 'axios';
import isEmpty, {isNumber, isFloat, isEmail} from "../../helpers/validation";
import { Link } from 'react-router-dom';
import { BiHappyAlt } from "react-icons/bi";

const steps = [
  {
    label: 'Profiling of College',
    description: ``,
  },
  {
    label: 'Building Details',
    description:
      '',
  },
  {
    label: 'Enrollment',
    description: ``,
  },
  {
    label: 'Teaching Staff',
    description: ``,
  },
  {
    label: 'Non-Teaching Staff',
    description:
      '',
  },
  {
    label: 'Students Admitted in last 3 Years',
    description: ``,
  },
  {
    label: 'Students Appeared in Board Examination in last 3 Years',
    description: ``,
  },
  {
    label: 'Students Passed in last 3 Years',
    description: ``,
  },
  {
    label: 'Salary & Fee Structure',
    description: ``,
  },
  {
    label: 'Choose Email and Password',
    description: ``,
  },
];

export default function VerticalLinearStepper() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [collegeLevels, setCollegeLevels] = useState([]);
  const [collegeShifts, setCollegeShifts] = useState([]);
  const [collegeGenders, setCollegeGenders] = useState([]);
  const [collegesManagedBy, setCollegesManagedBy] = useState([]);
  const [facultyClassGenderEnrollment, setFacultyClassGenderEnrollment] = useState([]);
  const [collegesDegrees, setCollegesDegrees] = useState([]);
  const [buildingOwnerships, setBuildingOwnerships] = useState([]);
  const [buildingTypes, setBuildingTypes] = useState([]);
  const [classroomsStatus, setClassroomsStatus] = useState([]);
  const [labs, setLabs] = useState([]);
  const [facilities, setFacilities] = useState([]);

  const [collegeProfileData, setCollegeProfileData] = React.useState({
    college_name: '',
    college_level: '',
    college_shift: '',
    college_gender: '',
    college_address: '',
    college_location: '',
    college_region: '',
    college_district: '',
    college_town: '',
    college_committee_name: '',
    college_managed_by: '',
    college_managed_by_name: '',
    college_owner_name: '',
    college_principal_name: '',
    contact_no: '',
    college_phone_no: '',
    college_email: '',
    longitude: '',
    latitude: '',
    year_of_establishment: '',
    date_of_registration: '',
  });

  const [collegeProfileErrors, setCollegeProfileErrors] = useState({
    college_name: '',
    college_level: '',
    college_shift: '',
    college_gender: '',
    college_address: '',
    college_location: '',
    college_region: '',
    college_district: '',
    college_town: '',
    college_committee_name: '',
    college_managed_by: '',
    college_managed_by_name: '',
    college_owner_name: '',
    college_principal_name: '',
    contact_no: '',
    college_phone_no: '',
    college_email: '',
    longitude: '',
    latitude: '',
    year_of_establishment: '',
    date_of_registration: '',
  });

  const [collegeBuildingData, setCollegeBuildingData] = React.useState({
    area_of_plot: '',
    ownership: '',
    type_of_building: '',
    total_no_of_rooms: '',
    total_no_of_class_rooms: '',
    total_no_of_admin_office_rooms: '',
    no_of_congested_class_rooms: '',
    total_seating_capacity_of_college: '',
    average_seating_capacity_of_class_rooms: '',
    status_of_class_rooms: '',
    corridor_used_as_class_rooms: '',
    office: '',
    staff_room: '',
    students_common_room: '',
    store: '',
    library: '',
    reading_room: '',
    gymnasium: '',
    auditorium: '',
    canteen: '',
    labs_available: [],
    facilities_available: [],
  });

  const [collegeBuildingErrors, setCollegeBuildingErrors] = React.useState({
    area_of_plot: '',
    ownership: '',
    type_of_building: '',
    total_no_of_rooms: '',
    total_no_of_class_rooms: '',
    total_no_of_admin_office_rooms: '',
    no_of_congested_class_rooms: '',
    total_seating_capacity_of_college: '',
    average_seating_capacity_of_class_rooms: '',
    status_of_class_rooms: '',
    corridor_used_as_class_rooms: '',
    office: '',
    staff_room: '',
    students_common_room: '',
    store: '',
    library: '',
    reading_room: '',
    gymnasium: '',
    auditorium: '',
    canteen: '',
    labs_available: '',
    facilities_available: '',
  });

  const [collegeEnrollmentData, setCollegeEnrollmentData] = React.useState([]);
  const [collegeEnrollmentDegreeData, setCollegeEnrollmentDegreeData] = React.useState([]);

  const [collegeTeachingStaffData, setCollegeTeachingStaffData] = React.useState({
    no_of_male_teaching_staff: '',
    no_of_female_teaching_staff: '',
  });

  const [collegeTeachingStaffErrors, setCollegeTeachingStaffErrors] = React.useState({
    no_of_male_teaching_staff: '',
    no_of_female_teaching_staff: '',
  });

  const [collegeNonTeachingStaffData, setCollegeNonTeachingStaffData] = React.useState({
    no_of_male_non_teaching_staff: '',
    no_of_female_non_teaching_staff: '',
  });

  const [collegeNonTeachingStaffErrors, setCollegeNonTeachingStaffErrors] = React.useState({
    no_of_male_non_teaching_staff: '',
    no_of_female_non_teaching_staff: '',
  });

  const [studentsAdmittedData, setStudentsAdmittedData] = React.useState({
    no_of_boys_admitted_in_last_3_years: '',
    no_of_girls_admitted_in_last_3_years: '',
  });

  const [studentsAdmittedErrors, setStudentsAdmittedErrors] = React.useState({
    no_of_boys_admitted_in_last_3_years: '',
    no_of_girls_admitted_in_last_3_years: '',
  });

  const [studentsAppearedData, setStudentsAppearedData] = React.useState({
    no_of_boys_appeared_in_board_in_last_3_years: '',
    no_of_girls_appeared_in_board_in_last_3_years: '',
  });

  const [studentsAppearedErrors, setStudentsAppearedErrors] = React.useState({
    no_of_boys_appeared_in_board_in_last_3_years: '',
    no_of_girls_appeared_in_board_in_last_3_years: '',
  });

  const [studentsPassedData, setStudentsPassedData] = React.useState({
    no_of_boys_passed_in_last_3_years: '',
    no_of_girls_passed_in_last_3_years: '',
  });

  const [studentsPassedErrors, setStudentsPassedErrors] = React.useState({
    no_of_boys_passed_in_last_3_years: '',
    no_of_girls_passed_in_last_3_years: '',
  });

  const [salaryFeeStructureData, setSalaryFeeStructureData] = React.useState({
    min_salary_teaching_staff: '',
    max_salary_teaching_staff: '',
    min_salary_non_teaching_staff: '',
    max_salary_non_teaching_staff: '',
    min_fee: '',
    max_fee: '',
  });

  const [salaryFeeStructureErrors, setSalaryFeeStructureErrors] = React.useState({
    min_salary_teaching_staff: '',
    max_salary_teaching_staff: '',
    min_salary_non_teaching_staff: '',
    max_salary_non_teaching_staff: '',
    min_fee: '',
    max_fee: '',
  });

  const [emailPasswordData, setEmailPasswordData] = React.useState({
    email: '',
    password: '',
    confirm_password: '',
  });

  const [emailPasswordErrors, setEmailPasswordErrors] = React.useState({
    email: '',
    password: '',
    confirm_password: '',
  });

  const handleChange = async (e) => {
    const {name, value} = e.target;

    if (name === 'college_region') {
      await setCollegeProfileData({...collegeProfileData, college_district: '', college_town: ''});
      await setDistricts([]);
      await setTowns([]);
      await getDistrictsByRegionId(value);
    }

    if (name === 'college_district') {
      await setCollegeProfileData({...collegeProfileData, college_town: ''});
      await setTowns([]);
      await getTownsByDistrictId(value);
    }

    if (name === 'contact_no' || name === 'college_phone_no') {
      if ((isNumber(value) || value === '') && value.length < 12){
        setCollegeProfileData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else if (name === 'year_of_establishment') {
      if ((isNumber(value) || value === '') && value.length < 5) {
        console.log(isNumber(value));
        setCollegeProfileData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else if (name === 'latitude' || name === 'longitude') {
      if (isFloat(value) || value === '') {
        console.log(isNumber(value));
        setCollegeProfileData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else {
      setCollegeProfileData((preValue) => {
        return {
          ...preValue,
          [name]: value,
        }
      });
    }
    
  }

  const handleChangeBuildingData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    if (name === "area_of_plot") {
      if (isFloat(value)) {
        setCollegeBuildingData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else if (name === 'labs_available' || name === 'facilities_available') {
      setCollegeBuildingData((preValue) => {
        return {
          ...preValue,
          [name]: [...preValue[name], value],
        }
      });
    } else {
      setCollegeBuildingData((preValue) => {
        return {
          ...preValue,
          [name]: value,
        }
      });
    }
  }

  const handleChangeCollegeEnrollmentData = async (e, item) => {
    item.enrollment = e.target.value;
    let name = `${item.faculty_id}_${item.class_id}_${item.gender_id}`;
    await setCollegeEnrollmentData((preValue) => {
      return {
        ...preValue,
        [name]: item,
      }
    });
  }

  const handleChangeCollegeEnrollmentDegreeData = async (e, item) => {
    item.enrollment = e.target.value;
    let name = `${item.id}`;
    await setCollegeEnrollmentDegreeData((preValue) => {
      return {
        ...preValue,
        [name]: item,
      }
    });
  }

  const handleChangeTeachingStaffData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setCollegeTeachingStaffData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleChangeNonTeachingStaffData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setCollegeNonTeachingStaffData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleChangeStudentsAdmittedData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setStudentsAdmittedData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleChangeStudentsAppearedData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setStudentsAppearedData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleChangeStudentsPassedData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setStudentsPassedData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleChangeSalaryFeeStructureData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setSalaryFeeStructureData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleChangeEmailPasswordData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setEmailPasswordData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleNext = async () => {
    setButtonDisabled(true);
    if (activeStep === 0) {
      setCollegeProfileErrors({});
      if (isEmpty(collegeProfileData?.college_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_name: "College Name is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_level?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_level: "College Level is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_shift?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_shift: "College Shift is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_gender?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_gender: "College Gender is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_address?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_address: "College Address is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_location?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_location: "College Location is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_region?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_region: "College Region is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_district?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_district: "College District is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_town?.trim()) && towns?.length > 0) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_town: "College Subdivision is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_committee_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_committee_name: "This Field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_managed_by?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_managed_by: "College Managed by is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_managed_by_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_managed_by_name: "This Field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_owner_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_owner_name: "College Owner Name is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_principal_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_principal_name: "College Principal Name is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.contact_no?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                contact_no: "Contact no is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_phone_no?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_phone_no: "College Phone no is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.college_email?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_email: "College Email is required",
            }
        });
        setButtonDisabled(false);
      } else if (!isEmail(collegeProfileData?.college_email?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_email: "College Email should be valid",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.longitude?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                longitude: "College Longitude is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.latitude?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                latitude: "College Latitude is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.year_of_establishment?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                year_of_establishment: "College Year of Establishment is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfileData?.date_of_registration?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                date_of_registration: "College Date of Registration is required",
            }
        });
        setButtonDisabled(false);
      } else {

        const isCollegeName = await checkCollegeName(collegeProfileData?.college_name?.trim());
        const isCollegeAddress = await checkCollegeAddress(collegeProfileData?.college_address?.trim());
        const isContactNo = await checkContactNo(collegeProfileData?.contact_no?.trim());
        const isCollegePhoneNo = await checkCollegePhoneNo(collegeProfileData?.college_phone_no?.trim());
        const isCollegeEmail = await checkCollegeEmail(collegeProfileData?.college_email?.trim());
        const isLongitudeLatitude = await checkLongitudeLatitude(collegeProfileData?.longitude?.trim(), collegeProfileData?.latitude?.trim());
        if (isCollegeName) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_name: "College Name already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isCollegeAddress) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_address: "College Address already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isContactNo) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                contact_no: "Contact no already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isCollegePhoneNo) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_phone_no: "College Phone no already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isCollegeEmail) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_email: "College Email already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isLongitudeLatitude) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                longitude: "College Longitude and Latitude already exists",
            }
          });
          setButtonDisabled(false);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setButtonDisabled(false);
        }
        
      }
    } else if (activeStep === 1) {

      setCollegeBuildingErrors({});

      if (isEmpty(collegeBuildingData?.area_of_plot?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                area_of_plot: "Area of Plot is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.ownership?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                ownership: "Ownership is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.type_of_building?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                type_of_building: "Type of Building is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.total_no_of_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_no_of_rooms: "Total no of Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.total_no_of_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_no_of_class_rooms: "Total no of Class Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.total_no_of_admin_office_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_no_of_admin_office_rooms: "Total no of Admin Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.no_of_congested_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                no_of_congested_class_rooms: "Total no of Congested Class Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.total_seating_capacity_of_college?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_seating_capacity_of_college: "Total Seating Capacity of College is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.average_seating_capacity_of_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                average_seating_capacity_of_class_rooms: "Average Seating Capacity of College is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.status_of_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                status_of_class_rooms: "Status of Classrooms is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.corridor_used_as_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                corridor_used_as_class_rooms: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.office?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                office: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.staff_room?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                staff_room: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.students_common_room?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                students_common_room: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.store?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                store: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.library?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                library: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.reading_room?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                reading_room: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.gymnasium?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                gymnasium: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.auditorium?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                auditorium: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.canteen?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                canteen: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingData?.labs_available)) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                labs_available: "Please select atleast one option",
            }
        });
        setButtonDisabled(false);
      } else {

        await getFacultyClassGenderEnrollment(collegeProfileData?.college_gender);
        await setActiveStep((prevActiveStep) => prevActiveStep + 1);
        await setButtonDisabled(false);

      }

    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setButtonDisabled(false);
    } else if (activeStep === 3) {
      setCollegeTeachingStaffErrors({});
      if (isEmpty(collegeTeachingStaffData?.no_of_male_teaching_staff?.trim())) {
        setCollegeTeachingStaffErrors((preValue) => {
            return {
                ...preValue,
                no_of_male_teaching_staff: "No of Male Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeTeachingStaffData?.no_of_female_teaching_staff?.trim())) {
        setCollegeTeachingStaffErrors((preValue) => {
            return {
                ...preValue,
                no_of_female_teaching_staff: "No of Female Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonDisabled(false);
      }
    } else if (activeStep === 4) {
      setCollegeNonTeachingStaffErrors({});
      if (isEmpty(collegeNonTeachingStaffData?.no_of_male_non_teaching_staff?.trim())) {
        setCollegeNonTeachingStaffErrors((preValue) => {
            return {
                ...preValue,
                no_of_male_non_teaching_staff: "No of Male Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeNonTeachingStaffData?.no_of_female_non_teaching_staff?.trim())) {
        setCollegeNonTeachingStaffErrors((preValue) => {
            return {
                ...preValue,
                no_of_female_non_teaching_staff: "No of Female Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonDisabled(false);
      }
    } else if (activeStep === 5) {
      setStudentsAdmittedErrors({});
      if (isEmpty(studentsAdmittedData?.no_of_boys_admitted_in_last_3_years?.trim())) {
        setStudentsAdmittedErrors((preValue) => {
            return {
                ...preValue,
                no_of_boys_admitted_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(studentsAdmittedData?.no_of_girls_admitted_in_last_3_years?.trim())) {
        setStudentsAdmittedErrors((preValue) => {
            return {
                ...preValue,
                no_of_girls_admitted_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonDisabled(false);
      }
    } else if (activeStep === 6) {
      setStudentsAppearedErrors({});
      if (isEmpty(studentsAppearedData?.no_of_boys_appeared_in_board_in_last_3_years?.trim())) {
        setStudentsAppearedErrors((preValue) => {
            return {
                ...preValue,
                no_of_boys_appeared_in_board_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(studentsAppearedData?.no_of_girls_appeared_in_board_in_last_3_years?.trim())) {
        setStudentsAppearedErrors((preValue) => {
            return {
                ...preValue,
                no_of_girls_appeared_in_board_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonDisabled(false);
      }
    } else if (activeStep === 7) {
      setStudentsPassedErrors({});
      if (isEmpty(studentsPassedData?.no_of_boys_passed_in_last_3_years?.trim())) {
        setStudentsPassedErrors((preValue) => {
            return {
                ...preValue,
                no_of_boys_passed_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(studentsPassedData?.no_of_girls_passed_in_last_3_years?.trim())) {
        setStudentsPassedErrors((preValue) => {
            return {
                ...preValue,
                no_of_girls_passed_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonDisabled(false);
      }
    } else if (activeStep === 8) {
      setSalaryFeeStructureErrors({});
      if (isEmpty(salaryFeeStructureData?.min_salary_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                min_salary_teaching_staff: "Minimum Salary of Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.max_salary_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                max_salary_teaching_staff: "Maximum Salary of Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.min_salary_non_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                min_salary_non_teaching_staff: "Minimum Salary of Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.max_salary_non_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                max_salary_non_teaching_staff: "Maximum Salary of Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.min_fee?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                min_fee: "Minimum Fee is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.max_fee?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                max_fee: "Maximum Fee is required",
            }
        });
        setButtonDisabled(false);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setButtonDisabled(false);
      }
    } else if (activeStep === 9) {
      setEmailPasswordErrors({});
      if (isEmpty(emailPasswordData?.email?.trim())) {
        setEmailPasswordErrors((preValue) => {
            return {
                ...preValue,
                email: "Email is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(emailPasswordData?.password?.trim())) {
        setEmailPasswordErrors((preValue) => {
            return {
                ...preValue,
                password: "Password is required",
            }
        });
        setButtonDisabled(false);
      } else if (emailPasswordData?.password?.trim().length < 8) {
        setEmailPasswordErrors((preValue) => {
            return {
                ...preValue,
                password: "Password should be minimum 8 characters",
            }
        });
        setButtonDisabled(false);
      } else if (emailPasswordData?.password?.trim() !== emailPasswordData?.confirm_password?.trim()) {
        setEmailPasswordErrors((preValue) => {
            return {
                ...preValue,
                confirm_password: "Password and Confirm Password should be same",
            }
        });
        setButtonDisabled(false);
      } else if (!isEmail(emailPasswordData?.email)) {
        setEmailPasswordErrors((preValue) => {
            return {
                ...preValue,
                email: "Email is not valid",
            }
        });
        setButtonDisabled(false);
      } else {
        const isEmail = await checkEmail(emailPasswordData?.email?.trim());
        if (isEmail) {
          setEmailPasswordErrors((preValue) => {
            return {
              ...preValue,
              email: "Email already exists",
            }
          });
          setButtonDisabled(false);
        } else {
          const enrollment = Object.values(collegeEnrollmentData);
          const enrollmentDegree = Object.values(collegeEnrollmentDegreeData);
          const data = {
            collegeProfileData,
            collegeBuildingData,
            enrollment,
            enrollmentDegree,
            collegeTeachingStaffData,
            collegeNonTeachingStaffData,
            studentsAdmittedData,
            studentsAppearedData,
            studentsPassedData,
            salaryFeeStructureData,
            emailPasswordData,
          }

          const res = await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/AddNewCollege', data);

          if (res?.data?.status === 'success') {
            if (res?.data?.data === true) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setButtonDisabled(false);
            } else {
              console.log("Something went wrong, Please try again!");
              setButtonDisabled(false);
            }
          }else if (res?.data?.error?.message) {

            console.log(res?.data?.error?.message);
            setButtonDisabled(false);
          } else {
            console.log("Something went wrong, Please try again!");
            setButtonDisabled(false);
          }
          setButtonDisabled(false);
        }
        
      }
    }
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getCollegeLevels = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeLevels');
    if (res?.data?.data) {
      await setCollegeLevels(res?.data?.data);
    }
  }

  const getCollegeShifts = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeShifts');
    if (res?.data?.data) {
      await setCollegeShifts(res?.data?.data);
    }
  }

  const getCollegeGenders = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeGenders');
    if (res?.data?.data) {
      await setCollegeGenders(res?.data?.data);
    }
  }

  const getCollegesManagedBy = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegesManagedBy');
    if (res?.data?.data) {
      await setCollegesManagedBy(res?.data?.data);
    }
  }

  const getRegions = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getRegions');
    if (res?.data?.data) {
      await setRegions(res?.data?.data);
    }
  }

  const getDistrictsByRegionId = async (region_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getDistrictsByRegionId/${region_id}`);
    if (res?.data?.data) {
      await setDistricts(res?.data?.data);
    }
  }

  const getTownsByDistrictId = async (district_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getTownsByDistrictId/${district_id}`);
    if (res?.data?.data) {
      await setTowns(res?.data?.data);
    }
  }

  const getFacultyClassGenderEnrollment = async (gender_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getFacultyClassGenderEnrollment/${gender_id}`);
    if (res?.data?.data) {
      await setFacultyClassGenderEnrollment(res?.data?.data);
    }
  }

  const getCollegesDegrees = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegesDegrees`);
    if (res?.data?.data) {
      await setCollegesDegrees(res?.data?.data);
    }
  }

  const getBuildingOwnerships = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getBuildingOwnerships`);
    if (res?.data?.data) {
      await setBuildingOwnerships(res?.data?.data);
    }
  }

  const getBuildingTypes = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getBuildingTypes`);
    if (res?.data?.data) {
      await setBuildingTypes(res?.data?.data);
    }
  }

  const getClassroomsStatus = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getClassroomsStatus`);
    if (res?.data?.data) {
      await setClassroomsStatus(res?.data?.data);
    }
  }

  const getLabs = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getLabs`);
    if (res?.data?.data) {
      await setLabs(res?.data?.data);
    }
  }

  const getFacilities = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getFacilities`);
    if (res?.data?.data) {
      await setFacilities(res?.data?.data);
    }
  }

  const checkCollegeName = async (college_name) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegeName/${college_name}`);
    return res?.data?.data;
  }

  const checkCollegeAddress = async (college_address) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegeAddress/${college_address}`);
    return res?.data?.data;
  }

  const checkContactNo = async (contact_no) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkContactNo/${contact_no}`);
    return res?.data?.data;
  }

  const checkCollegePhoneNo = async (college_phone_no) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegePhoneNo/${college_phone_no}`);
    return res?.data?.data;
  }

  const checkCollegeEmail = async (college_email) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegeEmail/${college_email}`);
    return res?.data?.data;
  }

  const checkLongitudeLatitude = async (longitude, latitude) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkLongitudeLatitude/${longitude}/${latitude}`);
    return res?.data?.data;
  }

  const checkEmail = async (email) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/users/checkEmail/${email}`);
    return res?.data?.data;
  }

  

  useEffect(() => {
    setButtonDisabled(false);
    async function getData() {
      await getCollegeLevels();
      await getCollegeShifts();
      await getCollegeGenders();
      await getCollegesManagedBy();
      await getRegions();
      await getCollegesDegrees();
      await getBuildingOwnerships();
      await getBuildingTypes();
      await getClassroomsStatus();
      await getLabs();
      await getFacilities();
    }
    getData();
  } ,[]);

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step 
            key={step.label}
            sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#075985', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#075985', // circle color (ACTIVE)
              fontSize: '1.5rem',
            },
            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                color: 'common.white', // Just text label (ACTIVE)
              },
            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
              fill: 'white', // circle's number (ACTIVE)
            },
          }}>
            
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {activeStep === 0 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                    <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                      <div className="">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <CustomTextBox
                            label="Name of College"
                            name="college_name"
                            type="text"
                            value={collegeProfileData?.college_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_name}</p>}

                          <CustomSelectBox
                            label="Level of College"
                            name="college_level"
                            value={collegeProfileData?.college_level}
                            options={collegeLevels}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_level && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_level}</p>}

                          <CustomSelectBox
                            label="Shift of College"
                            name="college_shift"
                            value={collegeProfileData?.college_shift}
                            options={collegeShifts}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_shift && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_shift}</p>}

                          <CustomSelectBox
                            label="Gender"
                            name="college_gender"
                            value={collegeProfileData?.college_gender}
                            options={collegeGenders}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_gender && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_gender}</p>}

                          <CustomTextBox
                            label="Address of College"
                            name="college_address"
                            type="text"
                            value={collegeProfileData?.college_address}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_address && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_address}</p>}

                          
                          <div className="mt-8 mb-2 w-full flex">
                            <div className="flex items-center mr-6">
                              <input
                                id="urban"
                                name="college_location"
                                type="radio"
                                value="1"
                                onChange={handleChange}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label htmlFor="urban" className="ml-3 block text-sm font-medium text-gray-700">
                                Urban
                              </label>
                              
                            </div>
                            <div className="flex items-center">
                              <input
                                id="rural"
                                name="college_location"
                                type="radio"
                                value="2"
                                onChange={handleChange}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label htmlFor="rural" className="ml-3 block text-sm font-medium text-gray-700">
                                Rural
                              </label>
                            </div>
                          </div>
                          {collegeProfileErrors?.college_location && <p className="mb-4 text-red-500 text-xs italic">{collegeProfileErrors?.college_location}</p>}
                          

                          <CustomSelectBox
                            label="Division / Region"
                            name="college_region"
                            value={collegeProfileData?.college_region}
                            options={regions}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_region && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_region}</p>}

                          <CustomSelectBox
                            label="District"
                            name="college_district"
                            value={collegeProfileData?.college_district}
                            options={districts}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_district && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_district}</p>}

                          <CustomSelectBox
                            label="Tehsil / Town / Taluka"
                            name="college_town"
                            value={collegeProfileData?.college_town}
                            options={towns}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_town && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_town}</p>}

                          <CustomTextBox
                            label="Muncipal Committee / Town Committee / Union Committee / Union Council"
                            name="college_committee_name"
                            type="text"
                            value={collegeProfileData?.college_committee_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_committee_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_committee_name}</p>}

                          <CustomSelectBox
                            label="Managed by"
                            name="college_managed_by"
                            value={collegeProfileData?.college_managed_by}
                            options={collegesManagedBy}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_managed_by && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_managed_by}</p>}

                          <CustomTextBox
                            label="Name of Society / Trust / Company / Individual / NGO / Other"
                            name="college_managed_by_name"
                            type="text"
                            value={collegeProfileData?.college_managed_by_name}
                            onChange={handleChange}
                          />
                           {collegeProfileErrors?.college_managed_by_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_managed_by_name}</p>}

                          <CustomTextBox
                            label="Owner / Gen. Secretary's Name"
                            name="college_owner_name"
                            type="text"
                            value={collegeProfileData?.college_owner_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_owner_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_owner_name}</p>}

                          <CustomTextBox
                            label="Name of Principal"
                            name="college_principal_name"
                            type="text"
                            value={collegeProfileData?.college_principal_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_principal_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_principal_name}</p>}

                          <CustomTextBox
                            label="Contact no"
                            name="contact_no"
                            type="text"
                            value={collegeProfileData?.contact_no}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.contact_no && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.contact_no}</p>}

                          <CustomTextBox
                            label="College Phone no"
                            name="college_phone_no"
                            type="text"
                            value={collegeProfileData?.college_phone_no}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_phone_no && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_phone_no}</p>}
                          
                          <CustomTextBox
                            label="College Email"
                            name="college_email"
                            type="text"
                            value={collegeProfileData?.college_email}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_email && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_email}</p>}
                          
                          <div className="mt-8 mb-6 w-full flex">
                            <div className="mr-6 w-full">
                              <label htmlFor="longitude" className="block text-sm font-medium text-gray-700 mb-1">
                                Longitude
                              </label>
                              <input
                                type="text"
                                name="longitude"
                                id="longitude"
                                autoComplete="longitude"
                                value={collegeProfileData?.longitude}
                                onChange={handleChange}
                                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                              />
                              {collegeProfileErrors?.longitude && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.longitude}</p>}
                            </div>
                            
                            <div className="w-full">
                              <label htmlFor="latitude" className="block text-sm font-medium text-gray-700 mb-1">
                                Latitude
                              </label>
                              <input
                                type="text"
                                name="latitude"
                                id="latitude"
                                autoComplete="latitude"
                                value={collegeProfileData?.latitude}
                                onChange={handleChange}
                                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                              />
                              {collegeProfileErrors?.latitude && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.latitude}</p>}
                            </div>
                            
                          </div>
                          
                          

                          <CustomTextBox
                            label="Year of Establishment"
                            name="year_of_establishment"
                            type="text"
                            value={collegeProfileData?.year_of_establishment}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.year_of_establishment && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.year_of_establishment}</p>}

                          <CustomTextBox
                            label="Date of Registration"
                            name="date_of_registration"
                            type="date"
                            value={collegeProfileData?.date_of_registration}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.date_of_registration && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.date_of_registration}</p>}
                          
                        </div>
                      </div>
                    </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                    <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                      <div className="">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <CustomTextBox
                            label="Area of Plot"
                            name="area_of_plot"
                            type="text"
                            value={collegeBuildingData?.area_of_plot}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.area_of_plot && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.area_of_plot}</p>}

                          <CustomSelectBox
                            label="Ownership"
                            name="ownership"
                            value={collegeBuildingData?.ownership}
                            options={buildingOwnerships}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.ownership && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.ownership}</p>}

                          <CustomSelectBox
                            label="Type of Building"
                            name="type_of_building"
                            value={collegeBuildingData?.type_of_building}
                            options={buildingTypes}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.type_of_building && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.type_of_building}</p>}

                          <CustomTextBox
                            label="Total No. of Rooms"
                            name="total_no_of_rooms"
                            type="number"
                            value={collegeBuildingData?.total_no_of_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_no_of_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_no_of_rooms}</p>}

                          <CustomTextBox
                            label="Total No. of Class Rooms"
                            name="total_no_of_class_rooms"
                            type="number"
                            value={collegeBuildingData?.total_no_of_class_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_no_of_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_no_of_class_rooms}</p>}

                          <CustomTextBox
                            label="Total No. of Admin Office Rooms"
                            name="total_no_of_admin_office_rooms"
                            type="number"
                            value={collegeBuildingData?.total_no_of_admin_office_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_no_of_admin_office_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_no_of_admin_office_rooms}</p>}

                          <CustomSelectBox
                            label="Status of Class Rooms"
                            name="status_of_class_rooms"
                            value={collegeBuildingData?.status_of_class_rooms}
                            options={classroomsStatus}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.status_of_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.status_of_class_rooms}</p>}

                          <CustomTextBox
                            label="No. of Congested Class Rooms"
                            name="no_of_congested_class_rooms"
                            type="number"
                            value={collegeBuildingData?.no_of_congested_class_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.no_of_congested_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.no_of_congested_class_rooms}</p>}

                          <CustomTextBox
                            label="Total Seating Capacity of College"
                            name="total_seating_capacity_of_college"
                            type="number"
                            value={collegeBuildingData?.total_seating_capacity_of_college}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_seating_capacity_of_college && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_seating_capacity_of_college}</p>}

                          <CustomTextBox
                            label="Average Seating Capacity of Class Rooms"
                            name="average_seating_capacity_of_class_rooms"
                            type="number"
                            value={collegeBuildingData?.average_seating_capacity_of_class_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.average_seating_capacity_of_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.average_seating_capacity_of_class_rooms}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Varenda / Corridor used as Class Rooms</legend>
                            </div>
                            <div className="mt-4 flex">
                              <div className="flex items-center mr-4">
                                <input
                                  id="corridor_used_as_class_rooms_yes"
                                  name="corridor_used_as_class_rooms"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="corridor_used_as_class_rooms_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Available
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="corridor_used_as_class_rooms_no"
                                  name="corridor_used_as_class_rooms"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="corridor_used_as_class_rooms_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  Not Available
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.corridor_used_as_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.corridor_used_as_class_rooms}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <div className="text-base font-medium text-gray-700 text-left">Office</div>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="office_yes"
                                  name="office"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="office_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="office_no"
                                  name="office"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="office_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.office && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.office}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Staff Room</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="staff_room_yes"
                                  name="staff_room"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="staff_room_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="staff_room_no"
                                  name="staff_room"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="staff_room_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.staff_room && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.staff_room}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Students Common Room</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="students_common_room_yes"
                                  name="students_common_room"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="students_common_room_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="students_common_room_no"
                                  name="students_common_room"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="students_common_room_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.students_common_room && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.students_common_room}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Store</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="store_yes"
                                  name="store"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="store_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="store_no"
                                  name="store"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="store_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.store && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.store}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Library</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="library_yes"
                                  name="library"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="library_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="library_no"
                                  name="library"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="library_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.library && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.library}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Reading Room</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="reading_room_yes"
                                  name="reading_room"
                                  type="radio"
                                  value="1"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="reading_room_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="reading_room_no"
                                  name="reading_room"
                                  type="radio"
                                  value="2"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="reading_room_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.reading_room && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.reading_room}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Gymnasium</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="gymnasium_yes"
                                  name="gymnasium"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="gymnasium_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="gymnasium_no"
                                  name="gymnasium"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="gymnasium_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.gymnasium && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.gymnasium}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Auditorium</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="auditorium_yes"
                                  name="auditorium"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="auditorium_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="auditorium_no"
                                  name="auditorium"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="auditorium_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.auditorium && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.auditorium}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Canteen</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="canteen_yes"
                                  name="canteen"
                                  value="1"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="canteen_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="canteen_no"
                                  name="canteen"
                                  value="2"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="canteen_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.canteen && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.canteen}</p>}

                          <fieldset className="mt-8 w-full">
                            <legend className="text-base font-medium text-gray-700 text-left">Labs Available</legend>
                            <div className="mt-4 space-y-4">
                              {labs?.length > 0 && labs.map((lab, index) => (
                                <div key={index} className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id={lab?.lab}
                                      name="labs_available"
                                      value={lab?.id}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                      onChange={handleChangeBuildingData}
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label htmlFor={lab?.lab} className="font-medium text-gray-700">
                                      {lab?.lab}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.labs_available && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.labs_available}</p>}

                          <fieldset className="mt-8 w-full">
                            <legend className="text-base font-medium text-gray-700 text-left">Available Facilities</legend>
                            <div className="mt-4 space-y-4">
                            {facilities?.length > 0 && facilities.map((facility, index) => (
                                <div key={index} className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id={facility?.facility}
                                      name="facilities_available"
                                      value={facility?.id}
                                      type="checkbox"
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                      onChange={handleChangeBuildingData}
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label htmlFor={facility?.facility} className="font-medium text-gray-700">
                                      {facility?.facility}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.facilities_available && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.facilities_available}</p>}
    
                        </div>
                      </div>
                    </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <div className="md:grid md:grid-cols-2 md:gap-6">

                              {facultyClassGenderEnrollment?.map((item, index) => (
                                <div key={index} className="mt-2 w-full">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {`${item?.class} ${item?.faculty} ${item?.gender}`}
                                  </label>
                                    <input
                                      type="number"
                                      name={`${item?.class}_${item?.faculty}_${item?.gender}`}
                                      onChange={(e) => handleChangeCollegeEnrollmentData(e, item)}
                                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                    />
                                </div>
                              ))}

                            {collegesDegrees?.map((item, index) => (
                                <div key={index} className="mt-2 w-full">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {`${item?.degree}`}
                                  </label>
                                    <input
                                      type="number"
                                      name={`${item?.degree}}`}
                                      onChange={(e) => handleChangeCollegeEnrollmentDegreeData(e, item)}
                                      className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                    />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 3 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="Total no. of Male Teaching Staff"
                                name="no_of_male_teaching_staff"
                                type="number"
                                value={collegeTeachingStaffData?.no_of_male_teaching_staff}
                                onChange={handleChangeTeachingStaffData}
                              />
                              {collegeTeachingStaffErrors?.no_of_male_teaching_staff && <p className="text-red-500 text-xs italic">{collegeTeachingStaffErrors?.no_of_male_teaching_staff}</p>}

                              <CustomTextBox
                                label="Total no. of Female Teaching Staff"
                                name="no_of_female_teaching_staff"
                                type="number"
                                value={collegeTeachingStaffData?.no_of_female_teaching_staff}
                                onChange={handleChangeTeachingStaffData}
                              />
                              {collegeTeachingStaffErrors?.no_of_female_teaching_staff && <p className="text-red-500 text-xs italic">{collegeTeachingStaffErrors?.no_of_female_teaching_staff}</p>}
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 4 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="Total no. of Male Non-Teaching Staff"
                                name="no_of_male_non_teaching_staff"
                                type="number"
                                value={collegeNonTeachingStaffData?.no_of_male_non_teaching_staff}
                                onChange={handleChangeNonTeachingStaffData}
                              />
                              {collegeNonTeachingStaffErrors?.no_of_male_non_teaching_staff && <p className="text-red-500 text-xs italic">{collegeNonTeachingStaffErrors?.no_of_male_non_teaching_staff}</p>}

                              <CustomTextBox
                                label="Total no. of Female Non-Teaching Staff"
                                name="no_of_female_non_teaching_staff"
                                type="number"
                                value={collegeNonTeachingStaffData?.no_of_female_non_teaching_staff}
                                onChange={handleChangeNonTeachingStaffData}
                              />
                              {collegeNonTeachingStaffErrors?.no_of_female_non_teaching_staff && <p className="text-red-500 text-xs italic">{collegeNonTeachingStaffErrors?.no_of_female_non_teaching_staff}</p>}
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 5 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="No. of Boys Admitted in Last 3 years"
                                name="no_of_boys_admitted_in_last_3_years"
                                type="number"
                                value={studentsAdmittedData?.no_of_boys_admitted_in_last_3_years}
                                onChange={handleChangeStudentsAdmittedData}
                              />
                              {studentsAdmittedErrors?.no_of_boys_admitted_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsAdmittedErrors?.no_of_boys_admitted_in_last_3_years}</p>}

                              <CustomTextBox
                                label="No. of Girls Admitted in Last 3 years"
                                name="no_of_girls_admitted_in_last_3_years"
                                type="number"
                                value={studentsAdmittedData?.no_of_girls_admitted_in_last_3_years}
                                onChange={handleChangeStudentsAdmittedData}
                              />
                              {studentsAdmittedErrors?.no_of_girls_admitted_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsAdmittedErrors?.no_of_girls_admitted_in_last_3_years}</p>}
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 6 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="No. of Boys Appeared in Board Examination in Last 3 years"
                                name="no_of_boys_appeared_in_board_in_last_3_years"
                                type="number"
                                value={studentsAppearedData?.no_of_boys_appeared_in_board_in_last_3_years}
                                onChange={handleChangeStudentsAppearedData}
                              />
                              {studentsAppearedErrors?.no_of_boys_appeared_in_board_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsAppearedErrors?.no_of_boys_appeared_in_board_in_last_3_years}</p>}

                              <CustomTextBox
                                label="No. of Girls Appeared in Board Examination in Last 3 years"
                                name="no_of_girls_appeared_in_board_in_last_3_years"
                                type="number"
                                value={studentsAppearedData?.no_of_girls_appeared_in_board_in_last_3_years}
                                onChange={handleChangeStudentsAppearedData}
                              />
                              {studentsAppearedErrors?.no_of_girls_appeared_in_board_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsAppearedErrors?.no_of_girls_appeared_in_board_in_last_3_years}</p>}
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 7 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="No. of Boys Passed in Last 3 years"
                                name="no_of_boys_passed_in_last_3_years"
                                type="number"
                                value={studentsPassedData?.no_of_boys_passed_in_last_3_years}
                                onChange={handleChangeStudentsPassedData}
                              />
                              {studentsPassedErrors?.no_of_boys_passed_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsPassedErrors?.no_of_boys_passed_in_last_3_years}</p>}

                              <CustomTextBox
                                label="No. of Girls Passed in Last 3 years"
                                name="no_of_girls_passed_in_last_3_years"
                                type="number"
                                value={studentsPassedData?.no_of_girls_passed_in_last_3_years}
                                onChange={handleChangeStudentsPassedData}
                              />
                              {studentsPassedErrors?.no_of_girls_passed_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsPassedErrors?.no_of_girls_passed_in_last_3_years}</p>}
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 8 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <div className="flex">
                              <div className="mt-4 w-full mr-2">
                                <label htmlFor="min_salary" className="block text-sm font-medium text-gray-700 mb-1">
                                  Minimum Salary of Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="min_salary_teaching_staff"
                                    value={salaryFeeStructureData?.min_salary_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="min_salary_teaching_staff"
                                    autoComplete="min_salary_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.min_salary_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.min_salary_teaching_staff}</p>}
                              </div>
                              <div className="mt-4 w-full ml-2">
                                <label htmlFor="max_salary" className="block text-sm font-medium text-gray-700 mb-1">
                                  Maximum Salary of Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="max_salary_teaching_staff"
                                    value={salaryFeeStructureData?.max_salary_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="max_salary_teaching_staff"
                                    autoComplete="max_salary_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.max_salary_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.max_salary_teaching_staff}</p>}
                              </div>
                            </div>

                            <div className="flex">
                              <div className="mt-4 w-full mr-2">
                                <label htmlFor="min_salary_non_teaching_staff" className="block text-sm font-medium text-gray-700 mb-1">
                                  Minimum Salary of Non-Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="min_salary_non_teaching_staff"
                                    value={salaryFeeStructureData?.min_salary_non_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="min_salary_non_teaching_staff"
                                    autoComplete="min_salary_non_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.min_salary_non_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.min_salary_non_teaching_staff}</p>}
                              </div>
                              <div className="mt-4 w-full ml-2">
                                <label htmlFor="max_salary_non_teaching_staff" className="block text-sm font-medium text-gray-700 mb-1">
                                  Maximum Salary of Non-Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="max_salary_non_teaching_staff"
                                    value={salaryFeeStructureData?.max_salary_non_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="max_salary_non_teaching_staff"
                                    autoComplete="max_salary_non_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.max_salary_non_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.max_salary_non_teaching_staff}</p>}
                              </div>
                            </div>

                            <div className="flex">
                              <div className="mt-4 w-full mr-2">
                                <label htmlFor="min_fee" className="block text-sm font-medium text-gray-700 mb-1">
                                  Minimum Fee
                                </label>
                                  <input
                                    type="number"
                                    name="min_fee"
                                    value={salaryFeeStructureData?.min_fee}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="min_fee"
                                    autoComplete="min_fee"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.min_fee && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.min_fee}</p>}
                              </div>
                              <div className="mt-4 w-full ml-2">
                                <label htmlFor="max_fee" className="block text-sm font-medium text-gray-700 mb-1">
                                  Maximum Fee
                                </label>
                                  <input
                                    type="number"
                                    name="max_fee"
                                    value={salaryFeeStructureData?.max_fee}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="max_fee"
                                    autoComplete="max_fee"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.max_fee && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.max_fee}</p>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              {activeStep === 9 && (
                <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="Email"
                                name="email"
                                type="email"
                                value={emailPasswordData?.email}
                                onChange={handleChangeEmailPasswordData}
                              />
                              {emailPasswordErrors?.email && <p className="text-red-500 text-xs italic">{emailPasswordErrors?.email}</p>}
                              <CustomTextBox
                                label="Password"
                                name="password"
                                type="password"
                                value={emailPasswordData?.password}
                                onChange={handleChangeEmailPasswordData}
                              />
                              {emailPasswordErrors?.password && <p className="text-red-500 text-xs italic">{emailPasswordErrors?.password}</p>}
                              <CustomTextBox
                                label="Confirm Password"
                                name="confirm_password"
                                type="password"
                                value={emailPasswordData?.confirm_password}
                                onChange={handleChangeEmailPasswordData}
                              />
                              {emailPasswordErrors?.confirm_password && <p className="text-red-500 text-xs italic">{emailPasswordErrors?.confirm_password}</p>}
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              )}
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2, mt: 4 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={buttonDisabled}
                    sx={{ mt: 1, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                  >
                    {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }}  /> : index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={index === 0 ? { mt: 1, mr: 1 } : { mt: 1, mr: 1, color: "#0369a1 !important" }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography className='text-gray-500 p-2 text-center'>All steps completed - you&apos;re finished</Typography>
          <Typography className='text-green-700 bg-green-100 p-6 text-center rounded'>
           <BiHappyAlt className='text-yellow-300 m-auto text-9xl' /> You have Registered your College Successfully</Typography>
           <div className='text-center'>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
            <Link to="/login">
              <Button sx={{ mt: 1, mr: 1 }}>
                Click here to Login
              </Button>
            </Link>
           </div>
          
          
        </Paper>
      )}
    </Box>
  );
}
