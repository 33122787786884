/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Centralized System',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Quality Education',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: ScaleIcon,
  },
  {
    name: 'Fast Learning',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Explore Different ways of Learning',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: AnnotationIcon,
  },
]

export default function Vision() {
  return (
    <div className="py-12 bg-sky-700">
      <div className="max-w-3xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-50 sm:text-4xl tracking-wider">
            Vision and Mission
          </p>
          <p className="mt-10 max-w-2xl text-left indent-16 text-gray-100 lg:mx-auto">
            To create a prosperous dynamic and inspiring educational environment in Privately Managed Colleges in Sindh.
          </p>
          <p className="mt-4 max-w-2xl text-left indent-16 text-gray-100 lg:mx-auto">
            To stimulate citizens of all ages to trust in, invest in, and benefit from privately managed colleges, so that they can make their best contribution to the country.
          </p>
          <p className="mt-4 max-w-2xl text-left indent-16 text-gray-100 lg:mx-auto">
            To faster student's success in Privately Managed Colleges in the Province by facilitating, monitoring and providing good Environment for better Educational Facilities.
          </p>
        </div>
      </div>
    </div>
  )
}
