import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const BuildingDetails = (props) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const getCollegeBuildingDetails = async (college_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeBuildingDetails/${college_id}`);
    if (res?.data?.data) {
      props.setCollegeBuildingData(res?.data?.data);
    }
  }

  const updateAreaOfPlotVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateAreaOfPlotVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateOwnershipVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateOwnershipVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateTypeOfBuildingVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateTypeOfBuildingVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfRoomsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfRoomsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfClassroomsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfClassroomsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfAdminOfficesVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfAdminOfficesVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfCongestedClassroomsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfCongestedClassroomsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateClassroomsStatusVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateClassroomsStatusVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateTotalSeatingCapacityOfCollegeVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateTotalSeatingCapacityOfCollegeVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateAverageSeatingCapacityOfClassroomsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateAverageSeatingCapacityOfClassroomsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCorridorUsedAsClassroomVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCorridorUsedAsClassroomVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateOfficeVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateOfficeVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateStaffRoomVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateStaffRoomVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateStudentsCommonRoomVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateStudentsCommonRoomVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateStoreVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateStoreVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateLibraryVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateLibraryVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateReadingRoomVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateReadingRoomVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateGymnasiumVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateGymnasiumVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateAuditoriumVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateAuditoriumVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCanteenVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCanteenVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const handleChangeBuildingDataVerified = async (item, college_id) => {
    if (item?.label === "Area of Plot") {
      await updateAreaOfPlotVerified(college_id);
    } else if (item?.label === "Ownership") {
      await updateOwnershipVerified(college_id);
    } else if (item?.label === "Type of Building") {
      await updateTypeOfBuildingVerified(college_id);
    } else if (item?.label === "Total No. of Rooms") {
      await updateNoOfRoomsVerified(college_id);
    } else if (item?.label === "Total No. of Class Rooms") {
      await updateNoOfClassroomsVerified(college_id);
    } else if (item?.label === "Total No. of Admin Office Rooms") {
      await updateNoOfAdminOfficesVerified(college_id);
    } else if (item?.label === "Status of Class Rooms") {
      await updateClassroomsStatusVerified(college_id);
    } else if (item?.label === "No. of Congested Class Rooms") {
      await updateNoOfCongestedClassroomsVerified(college_id);
    } else if (item?.label === "Total Seating Capacity of College") {
      await updateTotalSeatingCapacityOfCollegeVerified(college_id);
    } else if (item?.label === "Average Seating Capacity of Class Rooms") {
      await updateAverageSeatingCapacityOfClassroomsVerified(college_id);
    } else if (item?.label === "Varenda / Corridor used as Class Rooms") {
      await updateCorridorUsedAsClassroomVerified(college_id);
    } else if (item?.label === "Office") {
      await updateOfficeVerified(college_id);
    } else if (item?.label === "Staff Room") {
      await updateStaffRoomVerified(college_id);
    } else if (item?.label === "Students Common Room") {
      await updateStudentsCommonRoomVerified(college_id);
    } else if (item?.label === "Store") {
      await updateStoreVerified(college_id);
    } else if (item?.label === "Library") {
      await updateLibraryVerified(college_id);
    } else if (item?.label === "Reading Room") {
      await updateReadingRoomVerified(college_id);
    } else if (item?.label === "Gymnasium") {
      await updateGymnasiumVerified(college_id);
    } else if (item?.label === "Auditorium") {
      await updateAuditoriumVerified(college_id);
    } else if (item?.label === "Canteen") {
      await updateCanteenVerified(college_id);
    }
    await getCollegeBuildingDetails(college_id);
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      await getCollegeBuildingDetails(college_id);
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">COLLEGE BUILDING DETAILS</h3> 
                <button onClick={() => props.setCurrentTab('editBuildingDetails')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
            </div>
            <div className="border-t border-gray-200">
                <dl>
                    {props.collegeBuildingData?.map((item) => (
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm text-gray-900 font-medium">{item?.label}</dt>
                          <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                            <div className='flex items-center'>
                            {item?.value} {user?.user_role === "collegeUser" && (item?.isVerified ? (
                                <MdVerified className="mt-1 ml-2 mr-2 text-emerald-700" />
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <CgDanger className="ml-4 mr-1 text-red-500" /> Not verified
                                </p>
                                
                              ))}

                              {user?.user_role !== "collegeUser" && (item?.isVerified ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeBuildingDataVerified(item, params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeBuildingDataVerified(item, params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                              {/* <button style={{ width: "30px", height: "30px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-gray-600 hover:text-indigo-900 border flex text-center" onClick={() => setOpen(true)}><MdEdit /></button>  */}
                            </div>
                          </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    </div>
  )
}

export default BuildingDetails;
