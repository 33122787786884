import React, { useState, useEffect } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
  GridRowsProp,
  GridColDef,
} from '@mui/x-data-grid';
import Spinner from './Spinner';

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function ExportSelectorGrid({rows, columns}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // const rows = [
  //   { id: 1, col1: '1', col2: 'Abdul Baseer', col3: 'Abdul Aziz', col4: 'XI', col5: 'Pre-Medical', col6: '12345' },
  //   { id: 2, col1: '2', col2: 'Abdul Baseer', col3: 'Abdul Aziz', col4: 'XI', col5: 'Pre-Medical', col6: '12345' },
  //   { id: 3, col1: '3', col2: 'Abdul Baseer', col3: 'Abdul Aziz', col4: 'XI', col5: 'Pre-Medical', col6: '12345' },
  // ];
  
  // const columns = [
  //   { field: 'col1', headerName: '#', width: 150 },
  //   { field: 'col2', headerName: 'Name', width: 150 },
  //   { field: 'col3', headerName: 'Father Name', width: 150 },
  //   { field: 'col4', headerName: 'Class', width: 150 },
  //   { field: 'col5', headerName: 'Group', width: 150 },
  //   { field: 'col6', headerName: 'Roll No', width: 150 },
  // ];

  if (loading) {
    return (
      <Spinner message={`Loading...`} />
    );
  }

  return (
    <div style={{ height: 300, width: '100%' }}>
      <DataGrid
        rows={rows} columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
