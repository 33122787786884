import React from 'react';
import "./../styles.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const data = [
    {
        s_no: 1,
        title: "",
    },
    {
        s_no: 2,
        title: "",
    },
    {
        s_no: 3,
        title: "",
    },
    {
        s_no: 4,
        title: "",
    },
    {
        s_no: 5,
        title: "",
    },
    {
        s_no: 6,
        title: "",
    },
    {
        s_no: 7,
        title: "",
    },
    {
        s_no: 8,
        title: "",
    },
    {
        s_no: 9,
        title: "",
    },
    {
        s_no: 10,
        title: "",
    },
    {
        s_no: 11,
        title: "",
    },
    {
        s_no: 12,
        title: "",
    },
    {
        s_no: 13,
        title: "",
    },
    {
        s_no: 14,
        title: "",
    },
    {
        s_no: 15,
        title: "",
    },
    {
        s_no: 16,
        title: "",
    },
    {
        s_no: 17,
        title: "",
    },
    {
        s_no: 18,
        title: "",
    },
    {
        s_no: 19,
        title: "",
    },
    {
        s_no: 20,
        title: "",
    },
    {
        s_no: 21,
        title: "",
    },
    {
        s_no: 22,
        title: "",
    },
    {
        s_no: 23,
        title: "",
    },
    {
        s_no: 24,
        title: "",
    },
    {
        s_no: 25,
        title: "",
    },
    {
        s_no: 26,
        title: "",
    },
    {
        s_no: 27,
        title: "",
    },
    {
        s_no: 28,
        title: "",
    },
    {
        s_no: 29,
        title: "",
    },
    {
        s_no: 30,
        title: "",
    },
    {
        s_no: 31,
        title: "",
    },
    {
        s_no: 32,
        title: "",
    },
    {
        s_no: 33,
        title: "",
    },
    {
        s_no: 34,
        title: "",
    },
]

const generatePDF = async () => {
    const input = document.getElementById("print");
    html2canvas(input)
        .then((canvas) => {
            var imgData = canvas.toDataURL('image/png');

            /*
            Here are the numbers (paper width and height) that I found to work. 
            It still creates a little overlap part between the pages, but good enough for me.
            if you can find an official number from jsPDF, use them.
            */
            var imgWidth = 210;
            var pageHeight = 297;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('freeship.pdf');

        });

}

const handleClick = async () => {
    await generatePDF();
}

const Freeship = () => {
    return (
        <>
            <div className="my-application-header">
                <span style={{ marginTop: '1%', fontSize: '2vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                    <u>
                        RECORD&nbsp;&nbsp;OF&nbsp;&nbsp;10%&nbsp;&nbsp;FREESHIP
                    </u>
                </span>
            </div>
            <div style={{ marginTop: '3%' }} className='my-application-header'>
                <div className='proforma-left-70'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Name of Institution:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Name of Institution
                    </span>
                </div>
            </div>
            <div style={{ marginTop: '1%' }} className='my-application-header'>
                <div className='proforma-left-70'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Address:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Address
                    </span>
                </div>
            </div>
            <div style={{ marginTop: '1%' }} className='my-application-header'>
                <div style={{ width: '50%' }}>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Contact No:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Contact No
                    </span>
                </div>
                <div style={{ width: '50%' }}>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Level:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Level
                    </span>
                </div>
            </div>
            <div style={{ marginTop: '1%' }} className='my-application-header'>
                <div style={{ width: '50%' }}>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Total Enrollment:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Total Enrollment
                    </span>
                </div>
                <div style={{ width: '25%' }}>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Boys:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Boys
                    </span>
                </div>
                <div style={{ width: '25%' }}>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Girls:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Girls
                    </span>
                </div>
            </div>
            <div style={{ border: '1px solid #000', marginTop: '2%' }} className="my-application-item">
                <div className="freeship-col1">
                    <h4>S.NO</h4>
                </div>
                <div className="freeship-col2">
                    <h4>NAME OF STUDENT</h4>
                </div>
                <div className="freeship-col3">
                    <h4>FATHER'S NAME</h4>
                </div>
                <div className="freeship-col4">
                    <h4>CLASS</h4>
                </div>
                <div className="freeship-col5">
                    <h4>CONCESSION %</h4>
                </div>
                <div className="freeship-col6">
                    <h4 style={{ fontSize: '0.7vw' }}>CONTACT # OF PARENTS OF FREESHIP STUDENTS</h4>
                </div>
            </div>
            {data?.map((item, index) => (
                <div key={index} className="my-application-item">
                    <div className="freeship-col1">
                        <p>{item?.s_no}</p>
                    </div>
                    <div className="freeship-col2">
                        <p>{item?.title}</p>
                    </div>
                    <div className="freeship-col3">
                        <p></p>
                    </div>
                    <div className="freeship-col4">
                        <p></p>
                    </div>
                    <div className="freeship-col5">
                        <p></p>
                    </div>
                    <div className="freeship-col6">
                        <p></p>
                    </div>
                </div>
            ))}
            <div style={{ marginTop: "5%" }} className="my-application-footer1">
                <div className="my-application-footer1-left">
                    <p style={{ fontWeight: '400', fontSize: '1.3vw' }}>
                        Name {"&"} Signature with stamp of the<br />
                        General Secretary / Owner of the Institution
                    </p>
                </div>
                <div className="my-application-footer1-right">
                    <p style={{ fontWeight: '400', fontSize: '1.3vw' }}>
                        Name {"&"} Signature with stamp of the<br />
                        Principal / Administrator of the Institution
                    </p>
                </div>
            </div>

        </>
    )
}

export default Freeship;