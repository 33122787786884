import { LockClosedIcon } from '@heroicons/react/solid'
import Navbar2 from '../Navbar2/Navbar2'
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Spinner from '../common/Spinner';
import VerticalLinearStepper from '../common/VerticalLinearStepper';

export default function Register() {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [step]);

  if (loading) {
    return (
      <>
        <Navbar2 />
        <Spinner message={`Loading...`} />
      </>
    );
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <Navbar2 />
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl w-full space-y-8">
          <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-3xl">
            <span className="block text-sky-800 xl:inline">Please Complete your Registration</span>
          </h1>
        <VerticalLinearStepper />
          {step === 1 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">Profiling of College</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="mt-2 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Name of College
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Level of College
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Intermediate</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Shift of College
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Morning</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Gender
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Co-Education</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Address
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-8 mb-6 w-full flex">
                        <div className="flex items-center mr-6">
                          <input
                            id="push-nothing"
                            name="push-notifications"
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                            Urban
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="push-nothing"
                            name="push-notifications"
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                            Rural
                          </label>
                        </div>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Division
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Karachi</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          District
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">East</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Tehsil / Town
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Gulshan-e-Iqbal</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Muncipal Committee / Town Committee / Union Committee / Union Council
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Managed by
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Society</option>
                          <option value="">Trust</option>
                          <option value="">Company</option>
                          <option value="">Individual</option>
                          <option value="">NGO</option>
                          <option value="">Other</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Name of Society / Individual
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Owner / Gen. Secretary's Name
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Name of Principal
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Contact no
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          College Phone no
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          College Email
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-8 mb-6 w-full flex">
                        <div className="mr-6 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Longitude
                          </label>
                          <input
                            type="text"
                            name="title"
                            id="title"
                            autoComplete="title"
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                          />
                        </div>
                        <div className="w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Latitude
                          </label>
                          <input
                            type="text"
                            name="title"
                            id="title"
                            autoComplete="title"
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Year of Establishment
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => setStep(2)}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save & Next
                  </button>
                  <Link to="/" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cancel
                  </Link>
                </div>
                </dl>
              </div>
            </div>
          )}

          {step === 1 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">BUILDING</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="mt-2 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Area of Plot
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Ownership
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Rented</option>
                          <option value="">Own</option>
                          <option value="">Society</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Type of Building
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Commercial Plot</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Total No. of Rooms
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Total No. of Classrooms
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Total No. of Admin Office rooms
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          No. of Other rooms
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Status of Classrooms
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Air Conditioned</option>
                          <option value="">Airy</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          No. of Congested Classrooms
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Total Seating Capacity of College
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                          Average Seating Capacity of Classrooms
                        </label>
                        <input
                          type="text"
                          name="title"
                          id="title"
                          autoComplete="title"
                          className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Varenda / Corridor used as Classrooms
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Available</option>
                          <option value="">Not Available</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Office
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Staff Room
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Students Common Room
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Store
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Library
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Reading Room
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Gymnasium
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Auditorium
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Canteen
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Biology Laboratory
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>
                      <div className="mt-4 w-full">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                          Chemistry Laboratory
                        </label>
                        <select
                          id="category"
                          name="category"
                          autoComplete="category-name"
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
                        >
                          <option aria-label="None" value="" />
                          <option value="">Yes</option>
                          <option value="">No</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => setStep(2)}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save & Next
                  </button>
                  <Link to="/" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cancel
                  </Link>
                </div>
                </dl>
              </div>
            </div>
          )}
          
          
          {step === 2 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">ENROLLMENT</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Boys
                          </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Girls
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setStep(3)}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Save & Next
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(1)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          )}


          {step === 3 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">TEACHING STAFF</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Male
                          </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Female
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setStep(4)}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Save & Next
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(2)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          )}


          {step === 4 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">NON-TEACHING STAFF</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Male
                          </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Female
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setStep(5)}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Save & Next
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(3)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          )}


          {step === 5 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">STUDENTS ADMITTED IN LAST 3 YEARS</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Boys
                          </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Girls
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setStep(6)}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Save & Next
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(4)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          )}


          {step === 6 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">STUDENTS APPEARED IN BOARD EXAMINATION IN LAST 3 YEARS</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Boys
                          </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Girls
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setStep(7)}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Save & Next
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(5)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          )}


          {step === 7 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">STUDENTS PASSED IN LAST 3 YEARS</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Boys
                          </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Girls
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setStep(8)}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Save & Next
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(6)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          )}


          {step === 8 && (
            <div className="bg-sky-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-white">CHOOSE EMAIL AND PASSWORD</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div className="mt-2 w-full">
                          <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                            Email
                          </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Password
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                        <div className="mt-4 w-full">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
                              Confirm Password
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              autoComplete="title"
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-800 text-base font-medium text-white hover:bg-sky-900 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(7)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Back
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          )}



        </div>
      </div>
    </>
  )
}
