/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import CollegeProfile from '../CollegeProfile/CollegeProfile';
import Stats from '../Stats/Stats';
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Colleges from "../Colleges/Colleges";
import AddStaff from "../AddStaff/AddStaff";
import { HiMenu } from 'react-icons/hi'
import jwt_decode from "jwt-decode";
import { getUser } from '../../functions/functions';
import Form from '../Docs';

const sortOptions = [
  { name: 'Most Popular', href: '#', current: true },
  { name: 'Best Rating', href: '#', current: false },
  { name: 'Newest', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
]

const sideNavCollege = [
  { name: 'Home', href: '/home', active: true },
  { name: 'College Profile', href: '/home/college-profile', active: false },
  { name: 'Add new staff', href: '/home/staffs/new', active: false },
  { name: 'Staffs', href: '/home/staffs', active: false },
  { name: 'Registration Form', href: '/home/form', active: false },
]

const sideNavAdmin = [
  { name: 'Home', href: '/home', active: true },
  { name: 'Colleges', href: '/home/colleges', active: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



export default function Main() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [sideNav, setSideNav] = useState([]);

  const navigate = useNavigate();

  const handleSideNav = (category) => {
    const { name } = category;
    const s = sideNav?.map(item => item?.name === name ? { ...item, active: true } : { ...item, active: false });

    setSideNav(s);
  }

  useEffect(() => {
    // Check for token
    if (localStorage?.jwtToken) {
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout user
        localStorage.removeItem("jwtToken");
        // Redirect to login
        window.location.href = "/#/";
      }

      const user = getUser(localStorage?.getItem('jwtToken'));

      if (user?.user_role === "collegeUser") {
        setSideNav(sideNavCollege);
      } else {
        setSideNav(sideNavAdmin);
      }


    } else {
      navigate('/login');
    }
  }, [])

  return (
    <div className="bg-gray-50">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900"></h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  <ul role="list" className="font-medium text-gray-900">
                    {sideNav?.map((category) => (
                      <li key={category.name}>
                        <Link onClick={() => handleSideNav(category)} to={category.href} className="block border-t border-gray-200 px-4 py-6">
                          {category.name}
                        </Link>
                      </li>
                    ))}
                    <li
                      className="block border-t border-gray-200 px-4 py-6 cursor-pointer"
                      onClick={() => {
                        // Remove token from localStorage
                        localStorage.removeItem("jwtToken");
                        // Redirect to login
                        navigate("/login");
                      }}>
                      Logout
                    </li>
                  </ul>

                </form>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative z-10 flex items-baseline justify-between pt-6 pb-6 border-b border-gray-200">
            <Link to="/home">
              <h1 className="text-4xl font-bold tracking-tight text-sky-800">Dashboard</h1>
            </Link>


            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                {/* <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div> */}

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* <button type="button" className="p-2 -m-2 ml-5 sm:ml-7 text-gray-400 hover:text-gray-500">
                <span className="sr-only">View grid</span>
                <ViewGridIcon className="w-5 h-5" aria-hidden="true" />
              </button> */}
              <button
                type="button"
                className="p-2 -m-2 ml-4 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Menu</span>
                <HiMenu className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-5 gap-x-8 gap-y-10">
              {/* Filters */}
              <div className="lg:col-span-1">
                <form className="hidden lg:block">
                  <ul role="list" className="text-sm font-medium text-gray-700 pb-6">
                    {sideNav?.map((category) => (
                      <li key={category.name} className={category?.active ? "border-b text-sky-700 border-gray-200 py-6" : "border-b border-gray-200 py-6"}>
                        <Link onClick={() => handleSideNav(category)} to={category.href}>{category.name}</Link>
                      </li>
                    ))}
                    <li
                      className="py-6 cursor-pointer"
                      onClick={() => {
                        // Remove token from localStorage
                        localStorage.removeItem("jwtToken");
                        // Redirect to login
                        navigate("/login");
                      }}>
                      Logout
                    </li>
                  </ul>
                </form>
              </div>

              {/* Product grid */}
              <div className="lg:col-span-4">
                {/* Replace with your content */}
                <Routes>
                  <Route path="/college-profile" element={<CollegeProfile />} />
                  <Route path="/college-profile/:id" element={<CollegeProfile />} />
                  <Route path="/colleges" element={<Colleges />} />
                  <Route path="/staffs/new" element={<AddStaff />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/" element={<Stats />} />
                </Routes>
                {/* /End replace */}
              </div>
            </div>
          </section>
        </main>

      </div>
    </div>
  )
}
