import React from 'react';
import "./../styles.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const generatePDF = async () => {
    const input = document.getElementById("print");
    html2canvas(input)
        .then((canvas) => {
            var imgData = canvas.toDataURL('image/png');

            /*
            Here are the numbers (paper width and height) that I found to work. 
            It still creates a little overlap part between the pages, but good enough for me.
            if you can find an official number from jsPDF, use them.
            */
            var imgWidth = 210;
            var pageHeight = 297;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('authority-letter.pdf');

        });

}

const handleClick = async () => {
    await generatePDF();
}

const AuthorityLetter = () => {
    return (
        <>
            <div className="my-application-header">
                <span className="my-application-header-title-full">
                    <h4>The Director General,
                        <br />
                        Directorate of Inspection / Registration
                        <br />
                        of Private Institutions Sindh,
                        <br />
                        Karachi.
                    </h4>
                </span>
            </div>
            <div className="my-application-header-subject">
                <span className='my-application-subject-title'>
                    <h4 style={{ fontSize: '1.5vw' }}>Subject:</h4>
                </span>
                <span className='my-application-subject'>
                    <h4 style={{ fontSize: '1.7vw' }}>AUTHORITY LETTER</h4>
                </span>
            </div>

            <div className="my-application-header-subject2">
                <span className='my-application-subject2-title'>
                    <h4 style={{ fontSize: '1.5vw' }}>Reference:</h4>
                </span>
                <span className='my-application-subject2'>
                    <h4 style={{ fontSize: '1.5vw' }}>Application for Registration / Renewal of Registration vide No.</h4>
                </span>
            </div>

            <div className='my-application-header-subject3'>
                <span className='my-application-subject3'>
                    <span className='my-application-subject3-1'></span>
                    <span className='my-application-subject3-2'>Karachi.</span>
                    <span className='my-application-subject3-3'>Dated</span>
                    <span className='my-application-subject3-4'></span>
                </span>
            </div>

            <div style={{ marginTop: '10%' }} className="my-application-header-subject">
                <span className='my-application-subject-title'>
                    <h4 style={{ fontSize: '1.3vw' }}>Respected Sir,</h4>
                </span>
                <span className='my-application-subject'>
                    <h4></h4>
                </span>
            </div>
            <div style={{ marginTop: "2%" }} className="my-application-body">
                <div className="my-application-body-text">
                    <p style={{ fontSize: '1.3vw' }}>With reference to our application for Registration / Renewal of Registration on the Subject cited above Mr. /Ms. ____________________ Bearing C.N.I.C. No. ____________________ an employee of our institution is authorized to submit and collect the Registration / Renewal of Registration Certificate of our School issued by the Directorate of Inspection / Registration of Private Institution Sindh, Karachi. On behalf of the undersigned specimen signature of Mr. ____________________ are attested below.</p>
                </div>
            </div>
            <div style={{ marginTop: '30%' }} className="margin-bottom my-application-footer1">
                <div className="my-application-footer1-left2">
                    <div style={{ width: "70%" }}>
                        <div className='display-flex'>
                            <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>SIGNATURE <br /> OF AUTHORIZED PERSON</h4>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>.</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>.</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>.</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>.</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                    </div>
                </div>
                <div className="my-application-footer1-right2">
                    <div style={{ width: "70%" }}>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>SIGNATURE</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>NAME:</h4> <div style={{ width: "100%", 
                            borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ width: "35%", fontSize: '1.3vw' }}>FATHER NAME:</h4> <div style={{ width: "65%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>DESIGNATION:</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>C.N.I.C.NO:</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                        <div className='display-flex'>
                            <h4 style={{ fontSize: '1.3vw' }}>DATE:</h4> <div style={{ width: "100%", borderBottom: '1px solid #000' }}></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AuthorityLetter;