import React, { useEffect } from "react";
import Login from "./components/Login/Login";
import Main from "./components/Main/Main";
import Dashboard from "./components/Dashboard/Dashboard"
import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Register from "./components/Register/Register";
import FormA from "./components/Docs/FormA";
import AuthorityLetter from "./components/Docs/AuthorityLetter";
import Proforma from "./components/Docs/Proforma";
import Undertaking from "./components/Docs/Undertaking";
import Freeship from "./components/Docs/Freeship";
import OpenDoc from "./components/Docs/OpenDoc";
import Challan from "./components/Docs/Challan";
import Form from "./components/Docs";

export default function App() {

  const navigate = useNavigate();

  // useEffect(() => {
  //   const User = localStorage.getItem('jwtToken') !== 'undefined' ? localStorage.getItem('jwtToken') : localStorage.clear();
  //   console.log(User);

  //   if (!User) {
  //     navigate('/login');
  //   } else {
  //     navigate('/');
  //   }
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="forma" element={<FormA />} />
        <Route path="authority-letter" element={<AuthorityLetter />} />
        <Route path="proforma" element={<Proforma />} />
        <Route path="undertaking" element={<Undertaking />} />
        <Route path="freeship" element={<Freeship />} />
        <Route path="open" element={<OpenDoc />} />
        <Route path="challan" element={<Challan />} />
        <Route path="form" element={<Form />} />
        <Route path="/home/*" element={<Main />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Layout />} />
        {/* <Route path="/*" element={<Dashboard />} /> */}
      </Routes>
    </>
  )
}