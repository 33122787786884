import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from "../../helpers/validation";

const EditSalaryFeeStructure = ({ setCurrentTab, collegeStaffStudentsData }) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [salaryFeeStructureData, setSalaryFeeStructureData] = React.useState({
    min_salary_teaching_staff: '',
    max_salary_teaching_staff: '',
    min_salary_non_teaching_staff: '',
    max_salary_non_teaching_staff: '',
    min_fee: '',
    max_fee: '',
  });

  const [salaryFeeStructureErrors, setSalaryFeeStructureErrors] = React.useState({
    min_salary_teaching_staff: '',
    max_salary_teaching_staff: '',
    min_salary_non_teaching_staff: '',
    max_salary_non_teaching_staff: '',
    min_fee: '',
    max_fee: '',
  });

  const handleBack = () => {
    setCurrentTab('main');
  };

  const updateSalaryFeeStructure = async (college_id, salaryFeeStructureData) => {
    const data = {
        college_id,
        salaryFeeStructureData,
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateSalaryFeeStructureData`, data);
    return res;
  }

  const handleClick = async () => {
    setButtonDisabled(true);
    setSalaryFeeStructureErrors({});
      if (isEmpty(salaryFeeStructureData?.min_salary_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                min_salary_teaching_staff: "Minimum Salary of Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.max_salary_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                max_salary_teaching_staff: "Maximum Salary of Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.min_salary_non_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                min_salary_non_teaching_staff: "Minimum Salary of Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.max_salary_non_teaching_staff?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                max_salary_non_teaching_staff: "Maximum Salary of Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.min_fee?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                min_fee: "Minimum Fee is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(salaryFeeStructureData?.max_fee?.trim())) {
        setSalaryFeeStructureErrors((preValue) => {
            return {
                ...preValue,
                max_fee: "Maximum Fee is required",
            }
        });
        setButtonDisabled(false);
      } else {

        const college_id = params?.id;

        await updateSalaryFeeStructure(params?.id, salaryFeeStructureData);
        await setButtonDisabled(false);
        await setCurrentTab('main');
        
      }
    
  };

  const handleChangeSalaryFeeStructureData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setSalaryFeeStructureData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      setSalaryFeeStructureData({
        min_salary_teaching_staff: collegeStaffStudentsData[0]?.min_salary_of_teaching_staff,
        max_salary_teaching_staff: collegeStaffStudentsData[0]?.max_salary_of_teaching_staff,
        min_salary_non_teaching_staff: collegeStaffStudentsData[0]?.min_salary_of_non_teaching_staff,
        max_salary_non_teaching_staff: collegeStaffStudentsData[0]?.max_salary_of_non_teaching_staff,
        min_fee: collegeStaffStudentsData[0]?.min_fee,
        max_fee: collegeStaffStudentsData[0]?.max_fee,
      });
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">EDIT SALARY {"&"} FEE STRUCTURE</h3> 
                <button onClick={() => setCurrentTab('main')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1">
                    X
                </button> 
            </div>
            <div className="border-t border-gray-200">
              <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <div className="flex">
                              <div className="mt-4 w-full mr-2">
                                <label htmlFor="min_salary" className="block text-sm font-medium text-gray-700 mb-1">
                                  Minimum Salary of Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="min_salary_teaching_staff"
                                    value={salaryFeeStructureData?.min_salary_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="min_salary_teaching_staff"
                                    autoComplete="min_salary_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.min_salary_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.min_salary_teaching_staff}</p>}
                              </div>
                              <div className="mt-4 w-full ml-2">
                                <label htmlFor="max_salary" className="block text-sm font-medium text-gray-700 mb-1">
                                  Maximum Salary of Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="max_salary_teaching_staff"
                                    value={salaryFeeStructureData?.max_salary_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="max_salary_teaching_staff"
                                    autoComplete="max_salary_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.max_salary_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.max_salary_teaching_staff}</p>}
                              </div>
                            </div>

                            <div className="flex">
                              <div className="mt-4 w-full mr-2">
                                <label htmlFor="min_salary_non_teaching_staff" className="block text-sm font-medium text-gray-700 mb-1">
                                  Minimum Salary of Non-Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="min_salary_non_teaching_staff"
                                    value={salaryFeeStructureData?.min_salary_non_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="min_salary_non_teaching_staff"
                                    autoComplete="min_salary_non_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.min_salary_non_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.min_salary_non_teaching_staff}</p>}
                              </div>
                              <div className="mt-4 w-full ml-2">
                                <label htmlFor="max_salary_non_teaching_staff" className="block text-sm font-medium text-gray-700 mb-1">
                                  Maximum Salary of Non-Teaching Staff
                                </label>
                                  <input
                                    type="number"
                                    name="max_salary_non_teaching_staff"
                                    value={salaryFeeStructureData?.max_salary_non_teaching_staff}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="max_salary_non_teaching_staff"
                                    autoComplete="max_salary_non_teaching_staff"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.max_salary_non_teaching_staff && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.max_salary_non_teaching_staff}</p>}
                              </div>
                            </div>

                            <div className="flex">
                              <div className="mt-4 w-full mr-2">
                                <label htmlFor="min_fee" className="block text-sm font-medium text-gray-700 mb-1">
                                  Minimum Fee
                                </label>
                                  <input
                                    type="number"
                                    name="min_fee"
                                    value={salaryFeeStructureData?.min_fee}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="min_fee"
                                    autoComplete="min_fee"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.min_fee && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.min_fee}</p>}
                              </div>
                              <div className="mt-4 w-full ml-2">
                                <label htmlFor="max_fee" className="block text-sm font-medium text-gray-700 mb-1">
                                  Maximum Fee
                                </label>
                                  <input
                                    type="number"
                                    name="max_fee"
                                    value={salaryFeeStructureData?.max_fee}
                                    onChange={handleChangeSalaryFeeStructureData}
                                    id="max_fee"
                                    autoComplete="max_fee"
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                                  />
                                  {salaryFeeStructureErrors?.max_fee && <p className="text-red-500 text-xs italic">{salaryFeeStructureErrors?.max_fee}</p>}
                                  <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleClick}
                                        disabled={buttonDisabled}
                                        sx={{ mt: 5, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                                    >
                                        {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }}  /> : 'Save'}
                                    </Button>
                                    <Button
                                        disabled={buttonDisabled}
                                        onClick={handleBack}
                                        sx={{ mt: 5, mr: 1, color: "#0369a1 !important" }}
                                    >
                                        Back
                                    </Button>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </dl>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default EditSalaryFeeStructure;
