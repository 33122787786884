import React from 'react';
import "./../styles.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const data = [
    {
        s_no: 1,
        title: "Keep and maintain record of all visitors strictly.",
    },
    {
        s_no: 2,
        title: "Visiting cards be issued to every visitors.",
    },
    {
        s_no: 3,
        title: "Every employee should display his/her Identity card on the Shoulder.",
    },
    {
        s_no: 4,
        title: "Raise the Boundary wall of the Institute upto 10 feet.",
    },
    {
        s_no: 5,
        title: "Ensure the safety/security of water as well as foods sell in the canteens of the schools, so that no one can mix/put the dangerous chemical/poison in the same.",
    },
    {
        s_no: 6,
        title: "All schools administration should know the names and addresses of the nearby hospitals so that they approach the same in emergency situation in less/minimum time period.",
    },
    {
        s_no: 7,
        title: "All schools administration must display the numbers of C.PI.C, Police help line, Rangers, Fire Brigade etc at Notice Boards of the school in order to contact an emergency.",
    },
    {
        s_no: 8,
        title: "All schools administration must furnish complete information regarding the number of students, number of teachers and Non-teaching staff to the relevant Police stations.",
    },
    {
        s_no: 9,
        title: "Get Rehearsal/Practices to the students and staff on emergency Alarm.",
    },
    {
        s_no: 10,
        title: "Appointment of General Staff and Chowkidar be made in the school after Police Verification.",
    },
    {
        s_no: 11,
        title: "Arrange installation of Walk through Gate at the main entrance of the schools.",
    },
    {
        s_no: 12,
        title: "Parking should not be allowed around outside the school premises.",
    },
    {
        s_no: 13,
        title: "C.C.TV Camera should be installed at schools by the experts.",
    },
    {
        s_no: 14,
        title: "Bio-Metric/Turnstile be arranges at the schools.",
    },
    {
        s_no: 15,
        title: "Get use of Weapon Training by the Police/Rangers.",
    },
    {
        s_no: 16,
        title: "Parents Teachers Association may be functionalize.",
    },
]

const generatePDF = async () => {
    const input = document.getElementById("print");
    html2canvas(input)
        .then((canvas) => {
            var imgData = canvas.toDataURL('image/png');

            /*
            Here are the numbers (paper width and height) that I found to work. 
            It still creates a little overlap part between the pages, but good enough for me.
            if you can find an official number from jsPDF, use them.
            */
            var imgWidth = 210;
            var pageHeight = 297;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('proforma.pdf');

        });

}

const handleClick = async () => {
    await generatePDF();
}

const Proforma = () => {
    return (
        <>
            <div className="my-application-header">
                <span className="my-application-header-logo"><img className="my-main-logo" src="images/logo.png" /></span>
                <span className="my-application-header-title">
                    <h4>
                        <u>
                            DIRECTORATE OF INSPECTION / REGISTRATION OF PRIVATE INSTITUTIONS SINDH SCHOOL EDUCATION {"&"} LITERACY DEPARTMENT, GOVERNMENT OF SINDH
                        </u>
                    </h4>
                    <p style={{ fontSize: "1vw" }}>
                        <u>
                            Ground Floor Govt. Woman College, Shahrah-e-Liaquat, Karachi.
                        </u>
                    </p>
                </span>
            </div>
            <div className="my-application-header">
                <span style={{ marginTop: '3%', fontSize: '1vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                    <u>
                        NO.DIR/RI/PIS/EDU/GOS {"("}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{")"} 2018 DATE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </u>
                </span>
            </div>
            <div className="my-application-header">
                <span style={{ marginTop: '1%', fontSize: '2vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                    <u>
                        P R O F O R M A
                    </u>
                </span>
            </div>
            <div className="my-application-header">
                <span style={{ width: '100%', marginTop: '1%', fontSize: '1.5vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                    <u>
                        FOR SECURITY MEASURES OF PRIVATE INSTITUTIONS IN SINDH
                    </u>
                </span>
            </div>
            <div style={{ marginTop: '3%', lineHeight: '1' }} className='my-application-header'>
                <div className='proforma-left-70'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Name of Institution:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Name of Institution
                    </span>
                </div>
                <div className='proforma-right-30'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Level:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Level
                    </span>
                </div>
            </div>
            <div style={{ lineHeight: '1' }} className='my-application-header'>
                <div className='proforma-left-70'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Mail Address:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Mail Address
                    </span>
                </div>
                <div className='proforma-right-30'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Town:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Town
                    </span>
                </div>
            </div>
            <div style={{ lineHeight: '1', marginBottom: '1%' }} className='my-application-header'>
                <div className='proforma-left-70'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Email:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Email
                    </span>
                </div>
            </div>
            <div style={{ lineHeight: '1' }} className='my-application-header'>
                <div className='proforma-left-70'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Contact No:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Contact No
                    </span>
                </div>
            </div>
            <div style={{ lineHeight: '2' }} className='my-application-header'>
                <div className='proforma-left-70'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Registration No:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Registration No
                    </span>
                </div>
                <div className='proforma-right-30'>
                    <span style={{ fontWeight: "bold", fontSize: '1.3vw', marginRight: '2%' }}>
                        Dated:
                    </span>
                    <span style={{ fontSize: '1.3vw' }}>
                        Dated
                    </span>
                </div>
            </div>
            <div style={{ marginTop: '1%' }} className='my-application-header'>
                <p style={{ fontWeight: '500', fontSize: '1.1vw' }}>
                    The following safety measures is to be taken to safeguard the privately Managed Educational Institutions
                </p>
            </div>
            <div style={{ marginTop: '1%', borderTop: '1px solid #000' }} className="my-application-item">
                <div className="my-application-item-col1">
                    <h4 style={{ fontSize: '1.3vw' }}>S NO.</h4>
                </div>
                <div className="my-application-item-col2">
                    <h4 style={{ fontSize: '1.3vw' }}>SAFETY MEASURES TAKEN</h4>
                </div>
                <div className="my-application-item-col3">
                    <h4 style={{ fontSize: '1.3vw' }}>YES</h4>
                </div>
                <div className="my-application-item-col4">
                    <h4 style={{ fontSize: '1.3vw' }}>NO</h4>
                </div>
                <div className="my-application-item-col5">
                    <h4 style={{ fontSize: '1.3vw' }}>SUGGESSIONS / REMARKS</h4>
                </div>
            </div>
            {data?.map((item, index) => (
                <div key={index} className="my-application-item">
                    <div className="my-application-item-col1">
                        <p style={{ fontSize: '1.2vw' }}>{item?.s_no}</p>
                    </div>
                    <div className="my-application-item-col2">
                        <p style={{ fontSize: '1.2vw' }}>{item?.title}</p>
                    </div>
                    <div className="my-application-item-col3">
                        <p></p>
                    </div>
                    <div className="my-application-item-col4">
                        <p></p>
                    </div>
                    <div className="my-application-item-col5">
                        <p></p>
                    </div>
                </div>
            ))}
            <div style={{ marginTop: "5%" }} className="my-application-footer1">
                <div className="my-application-footer1-left">
                    <p style={{ fontWeight: '400', fontSize: '1.3vw' }}>
                        Name {"&"} Signature with stamp of the<br />
                        General Secretary / Owner of the Institution
                    </p>
                </div>
                <div className="my-application-footer1-right">
                    <p style={{ fontWeight: '400', fontSize: '1.3vw' }}>
                        Name {"&"} Signature with stamp of the<br />
                        Principal / Administrator of the Institution
                    </p>
                </div>
            </div>

        </>
    )
}

export default Proforma;