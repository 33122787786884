import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const ProfilingOfCollege = (props) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const getCollegeProfileData = async (college_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeProfileData/${college_id}`);
    if (res?.data?.data) {
      props.setCollegeProfileData(res?.data?.data);
    }
  }

  const updateCollegeNameVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeNameVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeLevelVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeLevelVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeShiftVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeShiftVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeGenderVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeGenderVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeAddressVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeAddressVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeLocationVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeLocationVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeRegionVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeRegionVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeDistrictVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeDistrictVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeTownVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeTownVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeCommitteeNameVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeCommitteeNameVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeManagedByNameVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeManagedByNameVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeManagedByVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeManagedByVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeOwnerNameVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeOwnerNameVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegePrincipalNameVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegePrincipalNameVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateContactNoVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateContactNoVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegePhoneNoVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegePhoneNoVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeEmailVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeEmailVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeLongitudeVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeLongitudeVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeLatitudeVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeLatitudeVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeYearOfEstablishmentVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeYearOfEstablishmentVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateCollegeDateOfRegistrationVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeDateOfRegistrationVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const handleChangeVerified = async (item, college_id) => {
    if (item?.label === "Name of College") {
      await updateCollegeNameVerified(college_id);
    } else if (item?.label === "Level of College") {
      await updateCollegeLevelVerified(college_id);
    } else if (item?.label === "Shift of College") {
      await updateCollegeShiftVerified(college_id);
    } else if (item?.label === "Gender of College") {
      await updateCollegeGenderVerified(college_id);
    } else if (item?.label === "Address of College") {
      await updateCollegeAddressVerified(college_id);
    } else if (item?.label === "Location of College") {
      await updateCollegeLocationVerified(college_id);
    } else if (item?.label === "Region of College") {
      await updateCollegeRegionVerified(college_id);
    } else if (item?.label === "District of College") {
      await updateCollegeDistrictVerified(college_id);
    } else if (item?.label === "Subdivision of College") {
      await updateCollegeTownVerified(college_id);
    } else if (item?.label === "Muncipal Committee / Town Committee / Union Committee / Union Council") {
      await updateCollegeCommitteeNameVerified(college_id);
    } else if (item?.label === "Managed by") {
      await updateCollegeManagedByVerified(college_id);
    } else if (item?.label === "Name of Society / Trust / Company / Individual / NGO / Other") {
      await updateCollegeManagedByNameVerified(college_id);
    } else if (item?.label === "Owner / Gen. Secretary's Name") {
      await updateCollegeOwnerNameVerified(college_id);
    } else if (item?.label === "Name of Principal") {
      await updateCollegePrincipalNameVerified(college_id);
    } else if (item?.label === "Contact no") {
      await updateContactNoVerified(college_id);
    } else if (item?.label === "College Phone no") {
      await updateCollegePhoneNoVerified(college_id);
    } else if (item?.label === "College Email") {
      await updateCollegeEmailVerified(college_id);
    } else if (item?.label === "Longitude") {
      await updateCollegeLongitudeVerified(college_id);
    } else if (item?.label === "Latitude") {
      await updateCollegeLatitudeVerified(college_id);
    } else if (item?.label === "Year of Establishment") {
      await updateCollegeYearOfEstablishmentVerified(college_id);
    } else if (item?.label === "Date of Registration") {
      await updateCollegeDateOfRegistrationVerified(college_id);
    }
    await getCollegeProfileData(college_id);
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      await getCollegeProfileData(college_id);
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">PROFILING OF COLLEGE</h3> 
                <button onClick={() => props.setCurrentTab('editCollegeProfile')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
            </div>
            <div className="border-t border-gray-200">
                <dl>
                    {props.collegeProfileData?.map((item) => (
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm text-gray-900 font-medium">{item?.label}</dt>
                          <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                            <div className='flex items-center'>
                              {item?.value} {user?.user_role === "collegeUser" && (item?.isVerified ? (
                                <MdVerified className="mt-1 ml-2 mr-2 text-emerald-700" />
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <CgDanger className="ml-4 mr-1 text-red-500" /> Not verified
                                </p>
                                
                              ))}

                              {user?.user_role !== "collegeUser" && (item?.isVerified ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeVerified(item, params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeVerified(item, params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                            </div>
                          </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    </div>
  )
}

export default ProfilingOfCollege;
