import React from 'react';
import "./../styles.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const generatePDF = async () => {
    const input = document.getElementById("print");
    html2canvas(input)
        .then((canvas) => {
            var imgData = canvas.toDataURL('image/png');

            /*
            Here are the numbers (paper width and height) that I found to work. 
            It still creates a little overlap part between the pages, but good enough for me.
            if you can find an official number from jsPDF, use them.
            */
            var imgWidth = 210;
            var pageHeight = 297;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('form.pdf');

        });

}

const handleClick = async () => {
    await generatePDF();
}

const FormA = () => {
    return (
        <>
            <div className="my-application-header">
                <span className="my-application-header-logo"><img className="my-main-logo" src="images/logo.png" /></span>
                <span className="my-application-header-title">
                    <h4>FORM "A"</h4>
                </span>
                <span className="my-application-header-title">
                    <h4>Serial No. 337</h4>
                </span>
            </div>
            <div className="my-application-header-middle">
                <div className="my-application-header-middle-title">
                    <h4>GOVERNMENT OF SINDH COLLEGE EDUCATION DEPARTMENT</h4>
                </div>
            </div>
            <div className="my-application-header-middle">
                <div className="my-application-header-middle-title">
                    <h4>PROFORMA FOR REGISTRATION / RENEWAL OF REGISTRATION OF PRIVATE INSTITUTIONS</h4>
                </div>
            </div>
            <div className="my-application-header-middle2">
                <div className="my-application-header-middle2-title">
                    <h4>COURSES OFFERED:</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>STATUS OF THE INSTITUTIONS</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>1. GENERAL INFORMATION</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>NAME OF INSTITUTION</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NAME OF INSTITUTION</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>ADDRESS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>ADDRESS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>TELEPHONE NO</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>TELEPHONE NO</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>EMAIL ADDRESS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>EMAIL ADDRESS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>YEAR OF ESTABLISHMENT</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>YEAR OF ESTABLISHMENT</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>DATE OF LAST INSPECTION</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>DATE OF LAST INSPECTION</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>NATURE OF THE INSTITUTION</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NATURE OF THE INSTITUTION</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>2. BUILDING INFORMATION</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"a)"} AREA OF THE PLOT</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>AREA OF PLOT</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"b)"} OWNERSHIP {"(Attach Evidence)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>OWNERSHIP</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"c)"} TYPE OF BUILDING</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>TYPE OF BUILDING</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- RESIDENTIAL</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>RESIDENTIAL</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4 style={{ fontSize: '0.9vw' }}>- A RESIDENTIAL BUT MODIFIED AS EDUCATION <br /> INSTITUTION.</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>A RESIDENTIAL BUT MODIFIED AS EDUCATION INSTITUTION</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"d)"} CLASS ROOMS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>CLASS ROOMS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- NO. OF CLASS ROOMS WITH CAPACITY</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NO OF CLASS ROOMS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- STATUS OF CLASS ROOMS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>STATUS OF CLASS ROOMS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- NO. OF CONGESTED ROOM{"(S)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NO OF CONGESTED ROOMS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- ARE VERANDAHS {"&"} CORRIDOR USED AS CLASS ROOMS?</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>VERANDAHS / CORRIDOR USED AS CLASS ROOMS?</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>TOTAL SEATING CAPACITY IN THE INSTITUTION</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>TOTAL SEATING CAPACITY IN THE INSTITUTION</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"e)"} SPACE PROVIDED FOR</h4>
                </div>
                <div className="my-application-item-value">
                    <h4></h4>
                </div>
            </div>
            <div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- PRINCIPAL OFFICE</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>PRINCIPAL OFFICE</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- STAFF ROOM</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>STAFF ROOM</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- STORE</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>STORE</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- STUDENT'S COMMON ROOM</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>STUDENT'S COMMON ROOM</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- LIBRARY</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>LIBRARY</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- NO. OF BOOKS</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NO. OF BOOKS</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- READING ROOM</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>READING ROOM</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- GYMNASIUM</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>GYMNASIUM</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- HALL</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>HALL</h4>
                </div>
            </div><div style={{ marginBottom: '20%' }} className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- AUDITORIUM</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>AUDITORIUM</h4>
                </div>
            </div><div style={{ borderTop: '1px solid #000' }} className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- SEPARATE TOILET FOR BOYS {"&"} GIRLS</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>SEPARATE TOILET FOR BOYS {"&"} GIRLS</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- LABORATORIES</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>LABORATORIES</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- CANTEEN</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>CANTEEN</h4>
                </div>
            </div><div className="my-application-item">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="my-application-item-key">
                    <h4>- PLAY GROUND</h4>
                    <h4>{"(Yes/No)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>PLAY GROUND</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"f"} HOW MANY STUDENTS CAN BE ACCOMMODATED IN EACH CLASS FROM THE EXAMINATION POINT OF VIEW?</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>HOW MANY STUDENTS CAN BE ACCOMMODATED IN EACH CLASS FROM THE EXAMINATION POINT OF VIEW?</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>3. FURNITURE</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"a)"} AVAILABLE QUANTITY OF FURNITURE {"(Attach List)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>AVAILABLE QUANTITY OF FURNITURE</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- NO. OF DUAL DESKS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NO. OF DUAL DESKS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- NO. OF STUDENTS CHAIRS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NO. OF STUDENTS CHAIRS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- LIBRARY</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>LIBRARY</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- LABORATORIES</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>LABORATORIES</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- READING ROOM</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>READING ROOM</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- ARTS ROOM</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>ARTS ROOM</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>4. EQUIPMENT</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"a)"} AVAILABLE QUANTITY OF EQUIPMENT {"(Attach List"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>AVAILABLE QUANTITY OF EQUIPMENT</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- TEACHING AIDS LIKE CHARTS, MODELS {"&"} PROJECTOR ETC</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>TEACHING AIDS LIKE CHARTS, MODELS {"&"} PROJECTOR ETC</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- AUDIO VISUAL AIDS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>AUDIO VISUAL AIDS</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>5. FEE STRUCTURE</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"a)"} CLASS WISE APPROVED FEE SCHEDULE:</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>CLASS WISE APPROVED FEE SCHEDULE</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- LAST REVISION OF FEE SCHEDULE {"(if any, with amount & year)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>LAST REVISION OF FEE SCHEDULE {"(if any, with amount & year)"}</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"b)"} ANY OTHER CHARGES:</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>ANY OTHER CHARGES</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- TOTAL NO. OF STUDENTS</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>TOTAL NO. OF STUDENTS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- CLASS WISE {"&"} GENDER WISE</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>CLASS WISE {"&"} GENDER WISE</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>6. STAFF</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"a)"} Teaching {"&"} Non-Teaching Staff With Their Scales: {"(Attach List)"}.</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>TEACHING {"&"} NON-TEACHING STAFF WITH THEIR SCALES: {"(ATTACH LIST)"}</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>7. FINANCIAL STATUS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"a)"} NAME OF BANK WITH BRANCH:</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NAME OF BANK WITH BRANCH</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"b)"} NAME OF ACCOUNT {"(Single / Joint)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NAME OF ACCOUNT {"(SINGLE / JOINT)"}</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"c"} NAME OF SIGNATORIES OPERATING THE ACCOUNT</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>NAME OF SIGNATORIES OPERATING THE ACCOUNT</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"d)"} WHETHER THE A/C ARE AUDITED <br />
                        {"(If yes attach copy of the last audit report)"}
                    </h4>
                </div>
                <div className="my-application-item-value">
                    <h4>WHETHER THE A/C ARE AUDITED</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"e)"} Attach Bank Statement of Current Financial year.</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>Attach Bank Statement of Current Financial year.</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"f)"} SOURCE OF INCOME</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>SOURCE OF INCOME</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- Donation</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>Donation</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- Fixed Assests</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>Fixed Assests</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- Contribution by Executive Member of Society.</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>Contribution by Executive Member of Society</h4>
                </div>
            </div>
            <div style={{ marginBottom: '20%' }} className="my-application-item">
                <div className="my-application-item-key">
                    <h4>- Fee</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>Fee</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>8. OFFICE RECORD</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"a)"} MUSTER ROLL OF TEACHING {"&"} NON-TEACHING STAFF.</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>MUSTER ROLL OF TEACHING {"&"} NON-TEACHING STAFF.</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"b)"} PERSONAL FILES OF STAFF</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>PERSONAL FILES OF STAFF</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"c)"} PAY ROLL</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>PAY ROLL</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"d)"} CASH BOOK {"(Head-Wise Expenditure)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>CASH BOOK {"(Head-Wise Expenditure)"}</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"e"} DEMAND / FEE REGISTER</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>DEMAND / FEE REGISTER</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"f)"} DEAD STOCK REGISTER</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>DEAD STOCK REGISTER</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"g)"} STOCK REGISTER</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>STOCK REGISTER</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"h)"} LIBRARY - ACCESSION REGISTER</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>LIBRARY - ACCESSION REGISTER</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"i)"} RECEIPT BOOKS REGISTER</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>RECEIPT BOOKS REGISTER</h4>
                </div>
            </div>
            <div className="my-application-item-header">
                <div className="my-application-item-header-title">
                    <h4>9. RESULT OF LAST 3 YEARS</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"A)"} STUDENTS ADMITTED</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>STUDENTS ADMITTED</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"B)"} APPEARED IN BOARD EXAMINATION</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>APPEARED IN BOARD EXAMINATION</h4>
                </div>
            </div>
            <div className="my-application-item">
                <div className="my-application-item-key">
                    <h4>{"C)"} PASSED PERCENTAGE {"(%)"}</h4>
                </div>
                <div className="my-application-item-value">
                    <h4>PASSED PERCENTAGE {"(%)"}</h4>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: 'center', marginTop: '10%', marginBottom: '20%' }} className="my-application-header">
                <div style={{ textAlign: "center" }}>
                    <h4 style={{ fontSize: '1.7vw', fontWeight: '500' }}>NOTE: ALL PAPERS SHOULD BE ATTESTED BY THE PRINCIPAL/ADMINISTRATOR</h4>
                </div>
            </div>
            <div style={{ marginBottom: '63%' }} className="my-application-footer1">
                <div className="my-application-footer1-left">
                </div>
                <div className="my-application-footer1-right">
                    <h4 style={{ fontSize: '1.3vw' }}>Seal {"&"} Signature</h4>
                </div>
            </div>

        </>
    )
}

export default FormA;