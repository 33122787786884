import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const EditFacilities = ({ setCurrentTab, collegeFacilitiesData }) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [facilities, setFacilities] = useState([]);

  const [data, setData] = React.useState({
    facilities_available: [],
  });

  const [errors, setErrors] = React.useState({
    facilities_available: '',
  });

  const getFacilities = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getFacilities`);
    if (res?.data?.data) {
      await setFacilities(res?.data?.data);
    }
  }

  const updateCollegeFacilities = async (college_id, facilitiesData) => {
    const data = {
      college_id,
      facilitiesData: facilitiesData?.facilities_available,
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeFacilitiesData`, data);
    return res;
  }

  const handleBack = () => {
    setCurrentTab('main');
  };

  const handleClick = async () => {
    setButtonDisabled(true);
    const college_id = params?.id;
    await updateCollegeFacilities(params?.id, data);
    await setButtonDisabled(false);
    await setCurrentTab('main');

  };

  const handleChangeFacilitiesData = (e) => {
    const { name, value } = e.target;

    if (name === 'facilities_available') {
      let facilitiesValues = data?.facilities_available;
      const isExistValue = data?.facilities_available?.includes(value);
      if (isExistValue) {
        facilitiesValues = facilitiesValues.filter((c) => { return c !== value });
      } else {
        facilitiesValues.push(value);
      }

      setData(() => {
        return {
          [name]: facilitiesValues,
        }
      });
    }
  }


  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      await getFacilities();
      setData(() => {
        return {
          facilities_available: collegeFacilitiesData?.map(item => item.facility_id)
        }
      });
    }
    getData();
  }, []);


  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">EDIT FACILITIES</h3>
          <button onClick={() => setCurrentTab('main')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1">
            X
          </button>
        </div>
        <div className="border-t border-gray-200">
          <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
            <div className="">
              <dl>
                <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">

                      <fieldset className="mt-8 w-full">
                        <legend className="text-base font-medium text-gray-700 text-left">Available Facilities</legend>
                        <div className="mt-4 space-y-4">
                          {facilities?.length > 0 && facilities.map((facility, index) => (
                            <div key={index} className="flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id={facility?.facility}
                                  name="facilities_available"
                                  value={facility?.id}
                                  type="checkbox"
                                  checked={data?.facilities_available?.some(item => facility?.id === item)}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                  onChange={handleChangeFacilitiesData}
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label htmlFor={facility?.facility} className="font-medium text-gray-700">
                                  {facility?.facility}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                      {errors?.facilities_available && <p className="text-red-500 text-xs italic">{errors?.facilities_available}</p>}
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleClick}
                          disabled={buttonDisabled}
                          sx={{ mt: 5, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                        >
                          {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }} /> : 'Save'}
                        </Button>
                        <Button
                          disabled={buttonDisabled}
                          onClick={handleBack}
                          sx={{ mt: 5, mr: 1, color: "#0369a1 !important" }}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditFacilities;
