import React, { useState, useEffect } from "react";
import axios from 'axios';
import { MdVerifiedUser } from "react-icons/md";
import { GoUnverified } from "react-icons/go";

function Stats() {
    const [statsData, setStatsData] = useState([]);

    const getCollegesStats = async () => {
        const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegesStats', {
            headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
            }
        });
        if (res?.data?.status === 'success') {
            setStatsData(res?.data?.data);
        }
    }

    useEffect(() => {
        getCollegesStats();
    }, [])
    return (
        <>
            {/* Card is full width. Use in 12 col grid for best view. */}
            {/* Card code block start */}
            <div className="w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8">
                {statsData?.map((item, index) => (
                    <div key={index} className="bg-white dark:bg-gray-800 rounded shadow px-8 py-6 flex items-center">
                        <div className={item?.icon === "GoUnverified" ? "p-4 bg-red-600 rounded text-white" : "p-4 bg-indigo-700 rounded text-white"}>
                            {(() => {
                                switch (item?.icon) {
                                    case "MdVerifiedUser": return (<MdVerifiedUser style={{ width: '32px', height: '32px' }} />);
                                    case "GoUnverified": return (<GoUnverified style={{ width: '32px', height: '32px' }} />);
                                }
                            })()}
                        </div>
                        <div className="ml-6">
                            <h3 className="mb-1 leading-5 text-gray-800 dark:text-gray-100 font-bold text-2xl">{item?.data}</h3>
                            <p className="text-gray-600 dark:text-gray-400 text-sm tracking-normal font-normal leading-5">{item?.heading}</p>
                        </div>
                    </div>
                ))}
            </div>
            {/* Card code block end */}
        </>
    );
}
export default Stats;
