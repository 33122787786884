import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CustomTextBox from '../common/CustomTextBox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from "../../helpers/validation";

const EditStudentsAppeared = ({ setCurrentTab, collegeStaffStudentsData }) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [studentsAppearedData, setStudentsAppearedData] = React.useState({
    no_of_boys_appeared_in_board_in_last_3_years: '',
    no_of_girls_appeared_in_board_in_last_3_years: '',
  });

  const [studentsAppearedErrors, setStudentsAppearedErrors] = React.useState({
    no_of_boys_appeared_in_board_in_last_3_years: '',
    no_of_girls_appeared_in_board_in_last_3_years: '',
  });

  const handleBack = () => {
    setCurrentTab('main');
  };

  const updateStudentsAppeared = async (college_id, studentsAppearedData) => {
    const data = {
        college_id,
        studentsAppearedData,
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateStudentsAppearedData`, data);
    return res;
  }

  const handleClick = async () => {
    setButtonDisabled(true);
    setStudentsAppearedErrors({});
      if (isEmpty(studentsAppearedData?.no_of_boys_appeared_in_board_in_last_3_years?.trim())) {
        setStudentsAppearedErrors((preValue) => {
            return {
                ...preValue,
                no_of_boys_appeared_in_board_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(studentsAppearedData?.no_of_girls_appeared_in_board_in_last_3_years?.trim())) {
        setStudentsAppearedErrors((preValue) => {
            return {
                ...preValue,
                no_of_girls_appeared_in_board_in_last_3_years: "This field is required",
            }
        });
        setButtonDisabled(false);
      } else {

        const college_id = params?.id;

        await updateStudentsAppeared(params?.id, studentsAppearedData);
        await setButtonDisabled(false);
        await setCurrentTab('main');
        
      }
    
  };

  const handleChangeStudentsAppearedData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setStudentsAppearedData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      setStudentsAppearedData({
        no_of_boys_appeared_in_board_in_last_3_years: collegeStaffStudentsData[0]?.no_of_boys_appeared_in_board_in_last_3_years,
        no_of_girls_appeared_in_board_in_last_3_years: collegeStaffStudentsData[0]?.no_of_girls_appeared_in_board_in_last_3_years,
      });
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">EDIT STUDENTS APPEARED IN BOARD EXAMINATION IN LAST 3 YEARS</h3> 
                <button onClick={() => setCurrentTab('main')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1">
                    X
                </button> 
            </div>
            <div className="border-t border-gray-200">
            <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="No. of Boys Appeared in Board Examination in Last 3 years"
                                name="no_of_boys_appeared_in_board_in_last_3_years"
                                type="number"
                                value={studentsAppearedData?.no_of_boys_appeared_in_board_in_last_3_years}
                                onChange={handleChangeStudentsAppearedData}
                              />
                              {studentsAppearedErrors?.no_of_boys_appeared_in_board_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsAppearedErrors?.no_of_boys_appeared_in_board_in_last_3_years}</p>}

                              <CustomTextBox
                                label="No. of Girls Appeared in Board Examination in Last 3 years"
                                name="no_of_girls_appeared_in_board_in_last_3_years"
                                type="number"
                                value={studentsAppearedData?.no_of_girls_appeared_in_board_in_last_3_years}
                                onChange={handleChangeStudentsAppearedData}
                              />
                              {studentsAppearedErrors?.no_of_girls_appeared_in_board_in_last_3_years && <p className="text-red-500 text-xs italic">{studentsAppearedErrors?.no_of_girls_appeared_in_board_in_last_3_years}</p>}
                              <div>
                                <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    disabled={buttonDisabled}
                                    sx={{ mt: 5, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                                >
                                    {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }}  /> : 'Save'}
                                </Button>
                                <Button
                                    disabled={buttonDisabled}
                                    onClick={handleBack}
                                    sx={{ mt: 5, mr: 1, color: "#0369a1 !important" }}
                                >
                                    Back
                                </Button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditStudentsAppeared;
