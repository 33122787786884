import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const StaffStudents = (props) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const getCollegeStaffStudentsDetails = async (college_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeStaffStudentsDetails/${college_id}`);
    if (res?.data?.data) {
      props.setCollegeStaffStudentsData(res?.data?.data);
    }
  }

  const updateNoOfMaleTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfMaleTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfFemaleTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfFemaleTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfMaleNonTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfMaleNonTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfFemaleNonTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfFemaleNonTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfBoysAdmittedInLast3YearsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfBoysAdmittedInLast3YearsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfGirlsAdmittedInLast3YearsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfGirlsAdmittedInLast3YearsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfBoysAppearedInBoardExamsInLast3YearsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfBoysAppearedInBoardExamsInLast3YearsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfGirlsAppearedInBoardExamsInLast3YearsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfGirlsAppearedInBoardExamsInLast3YearsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfBoysPassedInLast3YearsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfBoysPassedInLast3YearsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateNoOfGirlsPassedInLast3YearsVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNoOfGirlsPassedInLast3YearsVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateMinSalaryOfTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateMinSalaryOfTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateMaxSalaryOfTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateMaxSalaryOfTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateMinSalaryOfNonTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateMinSalaryOfNonTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateMaxSalaryOfNonTeachingStaffVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateMaxSalaryOfNonTeachingStaffVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateMinFeeVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateMinFeeVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const updateMaxFeeVerified = async (college_id) => {
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateMaxFeeVerified', {college_id}, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const handleChangeCollegeStaffStudentsDataVerified = async (item, college_id) => {
    if (item === "no_of_male_teaching_staff") {
      await updateNoOfMaleTeachingStaffVerified(college_id);
    } else if (item === "no_of_female_teaching_staff") {
      await updateNoOfFemaleTeachingStaffVerified(college_id);
    } else if (item === "no_of_male_non_teaching_staff") {
      await updateNoOfMaleNonTeachingStaffVerified(college_id);
    } else if (item === "no_of_female_non_teaching_staff") {
      await updateNoOfFemaleNonTeachingStaffVerified(college_id);
    } else if (item === "no_of_boys_admitted_in_last_3_years") {
      await updateNoOfBoysAdmittedInLast3YearsVerified(college_id);
    } else if (item === "no_of_girls_admitted_in_last_3_years") {
      await updateNoOfGirlsAdmittedInLast3YearsVerified(college_id);
    } else if (item === "no_of_boys_appeared_in_board_exams_in_last_3_years") {
      await updateNoOfBoysAppearedInBoardExamsInLast3YearsVerified(college_id);
    } else if (item === "no_of_girls_appeared_in_board_exams_in_last_3_years") {
      await updateNoOfGirlsAppearedInBoardExamsInLast3YearsVerified(college_id);
    } else if (item === "no_of_boys_passed_in_last_3_years") {
      await updateNoOfBoysPassedInLast3YearsVerified(college_id);
    } else if (item === "no_of_girls_passed_in_last_3_years") {
      await updateNoOfGirlsPassedInLast3YearsVerified(college_id);
    } else if (item === "min_salary_of_teaching_staff") {
      await updateMinSalaryOfTeachingStaffVerified(college_id);
    } else if (item === "max_salary_of_teaching_staff") {
      await updateMaxSalaryOfTeachingStaffVerified(college_id);
    } else if (item === "min_salary_of_non_teaching_staff") {
      await updateMinSalaryOfNonTeachingStaffVerified(college_id);
    } else if (item === "max_salary_of_non_teaching_staff") {
      await updateMaxSalaryOfNonTeachingStaffVerified(college_id);
    } else if (item === "min_fee") {
      await updateMinFeeVerified(college_id);
    } else if (item === "max_fee") {
      await updateMaxFeeVerified(college_id);
    }
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      await getCollegeStaffStudentsDetails(college_id);
    }
    getData();
  } ,[]);


  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">TEACHING STAFF</h3> 
          <button onClick={() => props.setCurrentTab('editTeachingStaff')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm text-gray-900 font-medium">Male</dt>
              <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                <div className='flex items-center'>
                  {props.collegeStaffStudentsData[0]?.no_of_male_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_male_teaching_staff_verified === '1' ? (
                      <FormGroup className="ml-4 mr-2 text-emerald-700">
                        <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_male_teaching_staff", params?.id)} color="success" />} label="Verified" />
                      </FormGroup>
                    ) : (
                      <p className='flex text-red-500 text-xs italic items-center'>
                        <FormGroup className="ml-4 mr-2 text-red-500">
                          <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_male_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                        </FormGroup>
                      </p>
                    ))}
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm text-gray-900 font-medium">Female</dt>
              <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                <div className='flex items-center'>
                  {props.collegeStaffStudentsData[0]?.no_of_female_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_female_teaching_staff_verified === '1' ? (
                      <FormGroup className="ml-4 mr-2 text-emerald-700">
                        <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_female_teaching_staff", params?.id)} color="success" />} label="Verified" />
                      </FormGroup>
                    ) : (
                      <p className='flex text-red-500 text-xs italic items-center'>
                        <FormGroup className="ml-4 mr-2 text-red-500">
                          <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_female_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                        </FormGroup>
                      </p>
                    ))}
                </div>
              </dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm text-gray-900 font-medium">Total</dt>
              <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                <div className='flex items-center'>
                  {parseInt(props.collegeStaffStudentsData[0]?.no_of_male_teaching_staff) + parseInt(props.collegeStaffStudentsData[0]?.no_of_female_teaching_staff)}
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">NON-TEACHING STAFF</h3> 
          <button onClick={() => props.setCurrentTab('editNonTeachingStaff')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
        </div>
        <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Male</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_male_non_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_male_non_teaching_staff_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_male_non_teaching_staff", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_male_non_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Female</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_female_non_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_female_non_teaching_staff_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_female_non_teaching_staff", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_female_non_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Total</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {parseInt(props.collegeStaffStudentsData[0]?.no_of_male_non_teaching_staff) + parseInt(props.collegeStaffStudentsData[0]?.no_of_female_non_teaching_staff)}
                          </div>
                        </dd>
                      </div>
                    </dl>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">STUDENTS ADMITTED IN LAST 3 YEARS</h3> 
          <button onClick={() => props.setCurrentTab('editStudentsAdmitted')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
        </div>
        <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Boys</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_boys_admitted_in_last_3_years} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_boys_admitted_in_last_3_years_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_boys_admitted_in_last_3_years", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_boys_admitted_in_last_3_years", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Girls</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_girls_admitted_in_last_3_years} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_girls_admitted_in_last_3_years_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_girls_admitted_in_last_3_years", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_girls_admitted_in_last_3_years", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Total</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {parseInt(props.collegeStaffStudentsData[0]?.no_of_boys_admitted_in_last_3_years) + parseInt(props.collegeStaffStudentsData[0]?.no_of_girls_admitted_in_last_3_years)}
                          </div>
                        </dd>
                      </div>
                    </dl>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">STUDENTS APPEARED IN BOARD EXAMINATION IN LAST 3 YEARS</h3> 
          <button onClick={() => props.setCurrentTab('editStudentsAppeared')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
        </div>
        <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Boys</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_boys_appeared_in_board_exams_in_last_3_years} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_boys_appeared_in_board_exams_in_last_3_years_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_boys_appeared_in_board_exams_in_last_3_years", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_boys_appeared_in_board_exams_in_last_3_years", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Girls</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_girls_appeared_in_board_exams_in_last_3_years} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_girls_appeared_in_board_exams_in_last_3_years_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_girls_appeared_in_board_exams_in_last_3_years", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_girls_appeared_in_board_exams_in_last_3_years", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Total</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {parseInt(props.collegeStaffStudentsData[0]?.no_of_boys_appeared_in_board_exams_in_last_3_years) + parseInt(props.collegeStaffStudentsData[0]?.no_of_girls_appeared_in_board_exams_in_last_3_years)}
                          </div>
                        </dd>
                      </div>
                    </dl>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">STUDENTS PASSED IN LAST 3 YEARS</h3> 
          <button onClick={() => props.setCurrentTab('editStudentsPassed')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
        </div>
        <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Boys</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_boys_passed_in_last_3_years} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_boys_passed_in_last_3_years_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_boys_passed_in_last_3_years", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_boys_passed_in_last_3_years", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Girls</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.no_of_girls_passed_in_last_3_years} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.no_of_girls_passed_in_last_3_years_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_girls_passed_in_last_3_years", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("no_of_girls_passed_in_last_3_years", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Total</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {parseInt(props.collegeStaffStudentsData[0]?.no_of_boys_passed_in_last_3_years) + parseInt(props.collegeStaffStudentsData[0]?.no_of_girls_passed_in_last_3_years)}
                          </div>
                        </dd>
                      </div>
                    </dl>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">SALARY {"&"} FEE STRUCTURE</h3> 
          <button onClick={() => props.setCurrentTab('editSalaryFeeStructure')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1"><MdEdit /></button> 
        </div>
        <div className="border-t border-gray-200">
                    <dl>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Minimum Salary of Teaching Staff</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.min_salary_of_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.min_salary_of_teaching_staff_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("min_salary_of_teaching_staff", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("min_salary_of_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Miximum Salary of Teaching Staff</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.max_salary_of_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.max_salary_of_teaching_staff_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("max_salary_of_teaching_staff", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("max_salary_of_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Minimum Salary of Non-Teaching Staff</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.min_salary_of_non_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.min_salary_of_non_teaching_staff_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("min_salary_of_non_teaching_staff", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("min_salary_of_non_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Miximum Salary of Non-Teaching Staff</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.max_salary_of_non_teaching_staff} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.max_salary_of_non_teaching_staff_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("max_salary_of_non_teaching_staff", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("max_salary_of_non_teaching_staff", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Minimum Fee</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.min_fee} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.min_fee_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("min_fee", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("min_fee", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm text-gray-900 font-medium">Miximum Fee</dt>
                        <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                          <div className='flex items-center'>
                            {props.collegeStaffStudentsData[0]?.max_fee} {user?.user_role !== "collegeUser" && (props.collegeStaffStudentsData[0]?.max_fee_verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeCollegeStaffStudentsDataVerified("max_fee", params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeCollegeStaffStudentsDataVerified("max_fee", params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                          </div>
                        </dd>
                      </div>
                    </dl>
        </div>
      </div>
    </div>
  )
}

export default StaffStudents;
