import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import isEmpty from "../../helpers/validation";
import CustomTextBox from '../common/CustomTextBox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const EditNonTeachingStaff = ({ setCurrentTab, collegeStaffStudentsData}) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [collegeNonTeachingStaffData, setCollegeNonTeachingStaffData] = React.useState({
    no_of_male_non_teaching_staff: '',
    no_of_female_non_teaching_staff: '',
  });

  const [collegeNonTeachingStaffErrors, setCollegeNonTeachingStaffErrors] = React.useState({
    no_of_male_teaching_staff: '',
    no_of_female_teaching_staff: '',
  });

  const handleBack = () => {
    setCurrentTab('main');
  };

  const handleClick = async () => {
    setButtonDisabled(true);
    setCollegeNonTeachingStaffErrors({});
    if (isEmpty(collegeNonTeachingStaffData?.no_of_male_non_teaching_staff?.trim())) {
        setCollegeNonTeachingStaffErrors((preValue) => {
            return {
                ...preValue,
                no_of_male_non_teaching_staff: "No of Male Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
    } else if (isEmpty(collegeNonTeachingStaffData?.no_of_female_non_teaching_staff?.trim())) {
        setCollegeNonTeachingStaffErrors((preValue) => {
            return {
                ...preValue,
                no_of_female_non_teaching_staff: "No of Female Non Teaching Staff is required",
            }
        });
        setButtonDisabled(false);
    } else {

        const college_id = params?.id;

        await updateNonTeachingStaff(params?.id, collegeNonTeachingStaffData);
        await setButtonDisabled(false);
        await setCurrentTab('main');
        
      }
    
  };

  const updateNonTeachingStaff = async (college_id, collegeNonTeachingStaffData) => {
    const data = {
        college_id,
        collegeNonTeachingStaffData,
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateNonTeachingStaffData`, data);
    return res;
  }

  const handleChangeNonTeachingStaffData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    setCollegeNonTeachingStaffData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      setCollegeNonTeachingStaffData({
        no_of_male_non_teaching_staff: collegeStaffStudentsData[0]?.no_of_male_non_teaching_staff,
        no_of_female_non_teaching_staff: collegeStaffStudentsData[0]?.no_of_female_non_teaching_staff,
      });
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">EDIT NON TEACHING STAFF</h3> 
                <button onClick={() => setCurrentTab('main')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1">
                    X
                </button> 
            </div>
            <div className="border-t border-gray-200">
            <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                      <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                        <div className="">
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <CustomTextBox
                                label="Total no. of Male Non-Teaching Staff"
                                name="no_of_male_non_teaching_staff"
                                type="number"
                                value={collegeNonTeachingStaffData?.no_of_male_non_teaching_staff}
                                onChange={handleChangeNonTeachingStaffData}
                              />
                              {collegeNonTeachingStaffErrors?.no_of_male_non_teaching_staff && <p className="text-red-500 text-xs italic">{collegeNonTeachingStaffErrors?.no_of_male_non_teaching_staff}</p>}

                              <CustomTextBox
                                label="Total no. of Female Non-Teaching Staff"
                                name="no_of_female_non_teaching_staff"
                                type="number"
                                value={collegeNonTeachingStaffData?.no_of_female_non_teaching_staff}
                                onChange={handleChangeNonTeachingStaffData}
                              />
                              {collegeNonTeachingStaffErrors?.no_of_female_non_teaching_staff && <p className="text-red-500 text-xs italic">{collegeNonTeachingStaffErrors?.no_of_female_non_teaching_staff}</p>}
                              <div>
                                <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    disabled={buttonDisabled}
                                    sx={{ mt: 5, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                                >
                                    {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }}  /> : 'Save'}
                                </Button>
                                <Button
                                    disabled={buttonDisabled}
                                    onClick={handleBack}
                                    sx={{ mt: 5, mr: 1, color: "#0369a1 !important" }}
                                >
                                    Back
                                </Button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditNonTeachingStaff;
