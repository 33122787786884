import { useState, useEffect } from 'react';
import ExportSelectorGrid from '../common/ExportSelectorGrid';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdAdd, IoMdSearch } from 'react-icons/io';
import Spinner from '../common/Spinner';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { darken, lighten } from '@mui/material/styles';
import axios from 'axios';
import { FaDownload } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai'

let idCounter = 0;
const createRandomRow = (id, college_name, college_gender, district, region, status) => {
  idCounter += 1;
  return { id: id, col1: idCounter, col2: college_name, col3: college_gender, col4: district, col5: region, col6: status };
};

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const stRows = [
  { id: 1, col1: '1', col2: '12345', col3: 'Abdul Baseer', col4: 'Abdul Aziz', col5: 'XI', col6: 'Pre-Medical' },
  { id: 2, col1: '2', col2: '12345', col3: 'Abdul Baseer', col4: 'Abdul Aziz', col5: 'XI', col6: 'Pre-Medical' },
  { id: 3, col1: '3', col2: '12345', col3: 'Abdul Baseer', col4: 'Abdul Aziz', col5: 'XI', col6: 'Rejected' },
];

const stColumns = [
  { field: 'col1', headerName: '#', width: 10 },
  { field: 'col2', headerName: 'College Name', width: 150 },
  { field: 'col3', headerName: 'College Gender', width: 150},
  { field: 'col4', headerName: 'District', width: 150 },
  { field: 'col5', headerName: 'Region', width: 150 },
  { field: 'col6', headerName: 'Status', width: 150 },
  { field: 'col7', headerClassName: 'super-app-theme--header', headerName: 'Action', width: 150, renderCell: (params) => (
    <Link to={`/home/college-profile/${params?.id}`}>
      <span className='flex items-center'>
        <AiFillEye className='mr-2' /> View Profile
      </span>
      
    </Link> )},
]

const staffRows = [
  { id: 1, col1: '1', col2: '12345', col3: 'Abdul Baseer', col4: 'Abdul Aziz', col5: 'Lecturer', col6: '03003030300' },
];

const staffColumns = [
  { field: 'col1', headerName: '#', width: 10 },
  { field: 'col2', headerName: 'Personnel No', width: 150 },
  { field: 'col3', headerName: 'Name', width: 150 },
  { field: 'col4', headerName: 'Father Name', width: 150 },
  { field: 'col5', headerName: 'Designation', width: 150 },
  { field: 'col6', headerName: 'Contact No', width: 150 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Colleges() {
  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(true);

  const [colleges, setColleges] = useState([]);

  const getColleges = async () => {
    
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getColleges`,{
      headers: {
        'Authorization': `${localStorage?.getItem('jwtToken')}`
      }});
    if (res?.data?.data) {
      setColleges(res?.data?.data);
    }
  }

  useEffect(() => {
    setCurrentTab(1);
    setLoading(true);
    async function fetchData() {
      await getColleges();
      await setLoading(false);
    }
    fetchData();
  }, []);

  const rows = colleges?.length > 0 && colleges.map((item) => createRandomRow(item?.college_id, item?.college_name, item?.gender, item?.district, item?.region, item?.verified === '1' ? "Verified" : "Not-Verified"));

  if (loading) {
    return (
      <Spinner message={`Loading...`} />
    );
  }

  return (
    <div className="relative bg-white">
      <div className='pt-14 pb-6 pl-4'>
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">Colleges</h1>
        <div className="absolute top-full inset-x-0 text-sm text-gray-500">
          <div className="absolute inset-0 top-1/2 bg-white shadow">
            <div className="relative bg-white">
              <div className="max-w-7xl mx-auto py-8">
                <div>
                  {currentTab === 1 && (
                    <Box
                    sx={{
                      height: 400,
                      width: 1,
                      '& .super-app-theme--Open': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
                        },
                      },
                      '& .super-app-theme--Filled': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(
                              theme.palette.success.main,
                              theme.palette.mode,
                            ),
                        },
                      },
                      '& .super-app-theme--PartiallyFilled': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(
                              theme.palette.warning.main,
                              theme.palette.mode,
                            ),
                        },
                      },
                      '& .super-app-theme--Not-Verified': {
                        bgcolor: (theme) =>
                          getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                        '&:hover': {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
                        },
                      },
                    }}
                    >
                      <DataGrid rows={rows} columns={stColumns} components={{ Toolbar: GridToolbar }} getRowClassName={(params) => `super-app-theme--${params.row.col6}`} />
                    </Box>
                  )}

                  {currentTab === 2 && (
                    <ExportSelectorGrid rows={staffRows} columns={staffColumns} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
