import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CustomTextBox from '../common/CustomTextBox';
import CustomSelectBox from '../common/CustomSelectBox';
import isEmpty, {isNumber, isFloat, isEmail} from "../../helpers/validation";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const EditCollegeProfile = ({ setCurrentTab, collegeProfileData }) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [collegeLevels, setCollegeLevels] = useState([]);
  const [collegeShifts, setCollegeShifts] = useState([]);
  const [collegeGenders, setCollegeGenders] = useState([]);
  const [collegesManagedBy, setCollegesManagedBy] = useState([]);

  const [collegeProfilingData, setCollegeProfilingData] = useState({
    college_name: '',
    college_level: '',
    college_shift: '',
    college_gender: '',
    college_address: '',
    college_location: '',
    college_region: '',
    college_district: '',
    college_town: '',
    college_committee_name: '',
    college_managed_by: '',
    college_managed_by_name: '',
    college_owner_name: '',
    college_principal_name: '',
    contact_no: '',
    college_phone_no: '',
    college_email: '',
    longitude: '',
    latitude: '',
    year_of_establishment: '',
    date_of_registration: '',
  });

  const [collegeProfileErrors, setCollegeProfileErrors] = useState({
    college_name: '',
    college_level: '',
    college_shift: '',
    college_gender: '',
    college_address: '',
    college_location: '',
    college_region: '',
    college_district: '',
    college_town: '',
    college_committee_name: '',
    college_managed_by: '',
    college_managed_by_name: '',
    college_owner_name: '',
    college_principal_name: '',
    contact_no: '',
    college_phone_no: '',
    college_email: '',
    longitude: '',
    latitude: '',
    year_of_establishment: '',
    date_of_registration: '',
  });

  const handleChange = async (e) => {
    const {name, value} = e.target;

    if (name === 'college_region') {
      await setCollegeProfilingData({...collegeProfilingData, college_district: '', college_town: ''});
      await setDistricts([]);
      await setTowns([]);
      await getDistrictsByRegionId(value);
    }

    if (name === 'college_district') {
      await setCollegeProfilingData({...collegeProfilingData, college_town: ''});
      await setTowns([]);
      await getTownsByDistrictId(value);
    }

    if (name === 'contact_no' || name === 'college_phone_no') {
      if ((isNumber(value) || value === '') && value.length < 12){
        setCollegeProfilingData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else if (name === 'year_of_establishment') {
      if ((isNumber(value) || value === '') && value.length < 5) {
        setCollegeProfilingData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else if (name === 'latitude' || name === 'longitude') {
      if (isFloat(value) || value === '') {
        setCollegeProfilingData((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else {
      setCollegeProfilingData((preValue) => {
        return {
          ...preValue,
          [name]: value,
        }
      });
    }
    
  }

  const handleBack = () => {
    setCurrentTab('main');
  };

  const updateCollegeProfile = async (college_id, college_profile_data) => {
    const data = {
        college_id,
        college_profile_data,
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeProfile`, data);
    return res;
  }

  const checkCollegeName = async (college_id, college_name) => {
    const data = {
        college_id,
        college_name
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegeNameWithCollegeId`, data);
    return res?.data?.data;
  }

  const checkCollegeAddress = async (college_id, college_address) => {
    const data = {
        college_id,
        college_address
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegeAddressWithCollegeId`, data);
    return res?.data?.data;
  }

  const checkContactNo = async (college_id, contact_no) => {
    const data = {
        college_id,
        contact_no
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkContactNoWithCollegeId`, data);
    return res?.data?.data;
  }

  const checkCollegePhoneNo = async (college_id, college_phone_no) => {
    const data = {
        college_id,
        college_phone_no
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegePhoneNoWithCollegeId`, data);
    return res?.data?.data;
  }

  const checkCollegeEmail = async (college_id, college_email) => {
    const data = {
        college_id,
        college_email
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkCollegeEmailWithCollegeId`, data);
    return res?.data?.data;
  }

  const checkLongitudeLatitude = async (college_id, longitude, latitude) => {
    const data = {
        college_id,
        longitude,
        latitude
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/checkLongitudeLatitudeWithCollegeId`, data);
    return res?.data?.data;
  }

  const handleClick = async () => {
    setButtonDisabled(true);
    setCollegeProfileErrors({});
      if (isEmpty(collegeProfilingData?.college_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_name: "College Name is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_level?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_level: "College Level is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_shift?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_shift: "College Shift is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_gender?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_gender: "College Gender is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_address?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_address: "College Address is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_location?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_location: "College Location is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_region?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_region: "College Region is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_district?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_district: "College District is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_town?.trim()) && towns?.length > 0) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_town: "College Subdivision is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_committee_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_committee_name: "This Field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_managed_by?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_managed_by: "College Managed by is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_managed_by_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_managed_by_name: "This Field is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_owner_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_owner_name: "College Owner Name is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_principal_name?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_principal_name: "College Principal Name is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.contact_no?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                contact_no: "Contact no is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_phone_no?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_phone_no: "College Phone no is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.college_email?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_email: "College Email is required",
            }
        });
        setButtonDisabled(false);
      } else if (!isEmail(collegeProfilingData?.college_email?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_email: "College Email should be valid",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.longitude?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                longitude: "College Longitude is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.latitude?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                latitude: "College Latitude is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.year_of_establishment?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                year_of_establishment: "College Year of Establishment is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeProfilingData?.date_of_registration?.trim())) {
        setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                date_of_registration: "College Date of Registration is required",
            }
        });
        setButtonDisabled(false);
      } else {

        const college_id = params?.id;

        const isCollegeName = await checkCollegeName(college_id, collegeProfilingData?.college_name?.trim());
        const isCollegeAddress = await checkCollegeAddress(college_id, collegeProfilingData?.college_address?.trim());
        const isContactNo = await checkContactNo(college_id, collegeProfilingData?.contact_no?.trim());
        const isCollegePhoneNo = await checkCollegePhoneNo(college_id, collegeProfilingData?.college_phone_no?.trim());
        const isCollegeEmail = await checkCollegeEmail(college_id, collegeProfilingData?.college_email?.trim());
        const isLongitudeLatitude = await checkLongitudeLatitude(college_id, collegeProfilingData?.longitude?.trim(), collegeProfilingData?.latitude?.trim());
        if (isCollegeName) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_name: "College Name already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isCollegeAddress) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_address: "College Address already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isContactNo) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                contact_no: "Contact no already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isCollegePhoneNo) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_phone_no: "College Phone no already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isCollegeEmail) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                college_email: "College Email already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isLongitudeLatitude) {
          setCollegeProfileErrors((preValue) => {
            return {
                ...preValue,
                longitude: "College Longitude and Latitude already exists",
            }
          });
          setButtonDisabled(false);
        } else {
          await updateCollegeProfile(params?.id, collegeProfilingData);
          await setButtonDisabled(false);
          await setCurrentTab('main');
          
        }
        
      }
    
  };

  const getCollegeLevels = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeLevels');
    if (res?.data?.data) {
      await setCollegeLevels(res?.data?.data);
    }
  }

  const getCollegeShifts = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeShifts');
    if (res?.data?.data) {
      await setCollegeShifts(res?.data?.data);
    }
  }

  const getCollegeGenders = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeGenders');
    if (res?.data?.data) {
      await setCollegeGenders(res?.data?.data);
    }
  }

  const getCollegesManagedBy = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegesManagedBy');
    if (res?.data?.data) {
      await setCollegesManagedBy(res?.data?.data);
    }
  }

  const getRegions = async () => {
    const res = await axios.get('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getRegions');
    if (res?.data?.data) {
      await setRegions(res?.data?.data);
    }
  }

  const getDistrictsByRegionId = async (region_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getDistrictsByRegionId/${region_id}`);
    if (res?.data?.data) {
      await setDistricts(res?.data?.data);
    }
  }

  const getTownsByDistrictId = async (district_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getTownsByDistrictId/${district_id}`);
    if (res?.data?.data) {
      await setTowns(res?.data?.data);
    }
  }

          
  useEffect(() => {
    async function getData() {
        await getCollegeLevels();
        await getCollegeShifts();
        await getCollegeGenders();
        await getCollegesManagedBy();
        await getRegions();
        setCollegeProfilingData({
            college_name: collegeProfileData[0]?.college_name,
            college_level: collegeProfileData[1]?.college_level,
            college_shift: collegeProfileData[2]?.college_shift,
            college_gender: collegeProfileData[3]?.college_gender,
            college_address: collegeProfileData[4]?.college_address,
            college_location: collegeProfileData[5]?.college_location,
            college_region: collegeProfileData[6]?.college_region,
            college_district: collegeProfileData[7]?.college_district,
            college_town: collegeProfileData[8]?.college_town,
            college_committee_name: collegeProfileData[9]?.college_committee_name,
            college_managed_by: collegeProfileData[10]?.college_managed_by,
            college_managed_by_name: collegeProfileData[11]?.college_managed_by_name,
            college_owner_name: collegeProfileData[12]?.college_owner_name,
            college_principal_name: collegeProfileData[13]?.college_principal_name,
            contact_no: collegeProfileData[14]?.contact_no,
            college_phone_no: collegeProfileData[15]?.college_phone_no,
            college_email: collegeProfileData[16]?.college_email,
            longitude: collegeProfileData[17]?.college_longitude,
            latitude: collegeProfileData[18]?.college_latitude,
            year_of_establishment: collegeProfileData[19]?.college_year_of_establishment,
            date_of_registration: collegeProfileData[20]?.college_date_of_registration,
        });
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">EDIT PROFILING OF COLLEGE</h3> 
                <button onClick={() => setCurrentTab('main')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1">
                    X
                </button> 
            </div>
            <div className="border-t border-gray-200">
            <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                    <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                      <div className="">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <CustomTextBox
                            label="Name of College"
                            name="college_name"
                            type="text"
                            value={collegeProfilingData?.college_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_name}</p>}

                          <CustomSelectBox
                            label="Level of College"
                            name="college_level"
                            value={collegeProfilingData?.college_level}
                            options={collegeLevels}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_level && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_level}</p>}

                          <CustomSelectBox
                            label="Shift of College"
                            name="college_shift"
                            value={collegeProfilingData?.college_shift}
                            options={collegeShifts}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_shift && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_shift}</p>}

                          <CustomSelectBox
                            label="Gender"
                            name="college_gender"
                            value={collegeProfilingData?.college_gender}
                            options={collegeGenders}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_gender && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_gender}</p>}

                          <CustomTextBox
                            label="Address of College"
                            name="college_address"
                            type="text"
                            value={collegeProfilingData?.college_address}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_address && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_address}</p>}

                          
                          <div className="mt-8 mb-2 w-full flex">
                            <div className="flex items-center mr-6">
                              <input
                                id="urban"
                                name="college_location"
                                type="radio"
                                value="1"
                                checked={collegeProfilingData?.college_location === "1" ? true:false}
                                onChange={handleChange}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label htmlFor="urban" className="ml-3 block text-sm font-medium text-gray-700">
                                Urban
                              </label>
                              
                            </div>
                            <div className="flex items-center">
                              <input
                                id="rural"
                                name="college_location"
                                type="radio"
                                value="2"
                                checked={collegeProfilingData?.college_location === "2" ? true:false}
                                onChange={handleChange}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                              />
                              <label htmlFor="rural" className="ml-3 block text-sm font-medium text-gray-700">
                                Rural
                              </label>
                            </div>
                          </div>
                          {collegeProfileErrors?.college_location && <p className="mb-4 text-red-500 text-xs italic">{collegeProfileErrors?.college_location}</p>}
                          

                          <CustomSelectBox
                            label="Division / Region"
                            name="college_region"
                            value={collegeProfilingData?.college_region}
                            options={regions}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_region && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_region}</p>}

                          <CustomSelectBox
                            label="District"
                            name="college_district"
                            value={collegeProfilingData?.college_district}
                            options={districts}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_district && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_district}</p>}

                          <CustomSelectBox
                            label="Tehsil / Town / Taluka"
                            name="college_town"
                            value={collegeProfilingData?.college_town}
                            options={towns}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_town && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_town}</p>}

                          <CustomTextBox
                            label="Muncipal Committee / Town Committee / Union Committee / Union Council"
                            name="college_committee_name"
                            type="text"
                            value={collegeProfilingData?.college_committee_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_committee_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_committee_name}</p>}

                          <CustomSelectBox
                            label="Managed by"
                            name="college_managed_by"
                            value={collegeProfilingData?.college_managed_by}
                            options={collegesManagedBy}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_managed_by && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_managed_by}</p>}

                          <CustomTextBox
                            label="Name of Society / Trust / Company / Individual / NGO / Other"
                            name="college_managed_by_name"
                            type="text"
                            value={collegeProfilingData?.college_managed_by_name}
                            onChange={handleChange}
                          />
                           {collegeProfileErrors?.college_managed_by_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_managed_by_name}</p>}

                          <CustomTextBox
                            label="Owner / Gen. Secretary's Name"
                            name="college_owner_name"
                            type="text"
                            value={collegeProfilingData?.college_owner_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_owner_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_owner_name}</p>}

                          <CustomTextBox
                            label="Name of Principal"
                            name="college_principal_name"
                            type="text"
                            value={collegeProfilingData?.college_principal_name}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_principal_name && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_principal_name}</p>}

                          <CustomTextBox
                            label="Contact no"
                            name="contact_no"
                            type="text"
                            value={collegeProfilingData?.contact_no}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.contact_no && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.contact_no}</p>}

                          <CustomTextBox
                            label="College Phone no"
                            name="college_phone_no"
                            type="text"
                            value={collegeProfilingData?.college_phone_no}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_phone_no && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_phone_no}</p>}
                          
                          <CustomTextBox
                            label="College Email"
                            name="college_email"
                            type="text"
                            value={collegeProfilingData?.college_email}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.college_email && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.college_email}</p>}
                          
                          <div className="mt-8 mb-6 w-full flex">
                            <div className="mr-6 w-full">
                              <label htmlFor="longitude" className="block text-sm font-medium text-gray-700 mb-1">
                                Longitude
                              </label>
                              <input
                                type="text"
                                name="longitude"
                                id="longitude"
                                autoComplete="longitude"
                                value={collegeProfilingData?.longitude}
                                onChange={handleChange}
                                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                              />
                              {collegeProfileErrors?.longitude && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.longitude}</p>}
                            </div>
                            
                            <div className="w-full">
                              <label htmlFor="latitude" className="block text-sm font-medium text-gray-700 mb-1">
                                Latitude
                              </label>
                              <input
                                type="text"
                                name="latitude"
                                id="latitude"
                                autoComplete="latitude"
                                value={collegeProfilingData?.latitude}
                                onChange={handleChange}
                                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                              />
                              {collegeProfileErrors?.latitude && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.latitude}</p>}
                            </div>
                            
                          </div>
                          
                          

                          <CustomTextBox
                            label="Year of Establishment"
                            name="year_of_establishment"
                            type="text"
                            value={collegeProfilingData?.year_of_establishment}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.year_of_establishment && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.year_of_establishment}</p>}

                          <CustomTextBox
                            label="Date of Registration"
                            name="date_of_registration"
                            type="date"
                            value={collegeProfilingData?.date_of_registration}
                            onChange={handleChange}
                          />
                          {collegeProfileErrors?.date_of_registration && <p className="text-red-500 text-xs italic">{collegeProfileErrors?.date_of_registration}</p>}
                          
                          <div>
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                disabled={buttonDisabled}
                                sx={{ mt: 5, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                            >
                                {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }}  /> : 'Save'}
                            </Button>
                            <Button
                                disabled={buttonDisabled}
                                onClick={handleBack}
                                sx={{ mt: 5, mr: 1, color: "#0369a1 !important" }}
                            >
                                Back
                            </Button>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    </dl>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditCollegeProfile;
