import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

export const setTokenInLocalStorage = (token) => {
  if (token) {
    // Set token to ls
    localStorage?.setItem("jwtToken", `Bearer ${token}`);
    // Set token to Auth header
    setAuthToken(`Bearer ${token}`);
  }
  
}

export const getUser = (token) => {
  if (token) {
    const decoded = jwt_decode(token);
    return decoded;
  }
  
}