import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Labs = ({ setCurrentTab, collegeLabsData, setCollegeLabsData }) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const getCollegeLabs = async (college_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegeLabs/${college_id}`);
    if (res?.data?.data) {
      setCollegeLabsData(res?.data?.data);
    }
  }

  const updateLabsVerified = async (college_id, id) => {
    const data = {
      college_id,
      id,
    }
    await axios.post('https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateLabsVerified', data , {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
  }

  const handleChangeLabsVerified = async (item, college_id) => {
    await updateLabsVerified(college_id, item?.id);
    await getCollegeLabs(college_id);
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      await getCollegeLabs(college_id);
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">LABS</h3>
                <button onClick={() => setCurrentTab('editLabs')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1" ><MdEdit /></button> 
            </div>
            <div className="border-t border-gray-200">
                <dl>
                    {collegeLabsData?.map((data, index) => (
                        <div key={index} className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          
                          <dd className="mt-1 text-md text-gray-500 sm:mt-0 sm:col-span-2 font-medium">
                            <div className='flex items-center'>
                              {data?.lab} {user?.user_role === "collegeUser" && (data?.verified === '1' ? (
                                <MdVerified className="mt-1 ml-2 mr-2 text-emerald-700" />
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <CgDanger className="ml-4 mr-1 text-red-500" /> Not verified
                                </p>
                                
                              ))}

                              {user?.user_role !== "collegeUser" && (data?.verified === '1' ? (
                                <FormGroup className="ml-4 mr-2 text-emerald-700">
                                  <FormControlLabel control={<Switch defaultChecked onChange={() => handleChangeLabsVerified(data, params?.id)} color="success" />} label="Verified" />
                                </FormGroup>
                              ) : (
                                <p className='flex text-red-500 text-xs italic items-center'>
                                  <FormGroup className="ml-4 mr-2 text-red-500">
                                    <FormControlLabel control={<Switch onChange={() => handleChangeLabsVerified(data, params?.id)} color="success" />} label="Not Verified" />
                                  </FormGroup>
                                </p>
                                
                              ))}
                            </div>
                          </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    </div>
  )
}

export default Labs;
