import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import OpenDoc from "./OpenDoc";
import FormA from "./FormA";
import AuthorityLetter from "./AuthorityLetter";
import Proforma from "./Proforma";
import Undertaking from './Undertaking';
import Freeship from './Freeship';
import "./styles.css";

const Form = () => {

  const generatePDF = async () => {
    const input = document.getElementById("page");
    // const page1Container = document.querySelector(".page1-container");
    // let doc = new jsPDF("potrait", "pt", "a4", "false");
    // input.style.boxSizing = "border-box";
    // input.style.width = "600px";
    // input.style.height = "840px";
    // input.style.padding = "auto";
    // input.style.display = "flex";
    // input.style.justifyContent = "center";
    // input.style.alignItems = "center";
    // page1Container.style.width = "550px";
    // page1Container.style.height = "740px";
    // page1Container.style.border = "2px solid #000";
    // doc.html(document.querySelector("#print"), {
    //   autoPaging: 'text',
    //   callback: function (pdf) {
    //     pdf.save("seccap.pdf");
    //   },

    // });

    html2canvas(input)
      .then((canvas) => {
        var imgData = canvas.toDataURL('image/png');

        /*
        Here are the numbers (paper width and height) that I found to work. 
        It still creates a little overlap part between the pages, but good enough for me.
        if you can find an official number from jsPDF, use them.
        */
        var imgWidth = 210;
        var pageHeight = 297;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save('formA.pdf');

      });

  }

  const handleClick = async () => {
    await generatePDF();
  }

  return (
    <>
      <button onClick={handleClick}>
        Download
      </button>

      <div id="order">
        <div className="my-application-container">
          <div id="print" className="my-application-tempelate">
            {/* <div id="page">
              <div className='page1-container'>
                <h1>
                  COLLEGE EDUCATION DEPARTMENT <br />
                  GOVERNMENT OF SINDH
                </h1>
                <h2>
                  R E G I S T R A T I O N <br />
                  F O R M "A"
                </h2>
                <h3>
                  DIRECTORATE OF INSPECTION / REGISTRATION <br />
                  OFPRIVATEINSTITUTIONS SINDH
                </h3>
                <h4>
                  Ground Floor Govt. Woman College, Shahrah-e-Liaquat Karachi.
                </h4>
                <h4>
                  Contact # 021-99217489-90
                </h4>
              </div>
            </div> */}
            <OpenDoc />
            <FormA />
            <AuthorityLetter />
            <Proforma />
            <Undertaking />
            <Freeship />
          </div>
        </div>
      </div>

    </>
  )
}

export default Form;