const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

const isNumber = (value) => {
  var pattern = /^[0-9]+$/;
  return pattern.test(value);
};

const isFloat = (value) => {
  var pattern = /^[0-9.]*$/;
  return pattern.test(value);
}

const isName = (value) => {
  var pattern = /^[a-zA-Z-' ]*$/;
  return pattern.test(value);
}

const isEmail = (value) => {
  var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return pattern.test(value);
};

const isCNIC = (value) => {
  var pattern = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/;
  return pattern.test(value);
};

const isNumberOrDash = (value) => {
  var pattern = /^[0-9-]*$/;
  return pattern.test(value);
}

export default isEmpty;

export { isNumber, isFloat, isName, isEmail, isCNIC, isNumberOrDash };