import React from 'react';

const CustomTextBox = (props) => {
  return (
    <div className={props?.className ? props?.className : "mt-4 w-full"}>
      <label htmlFor={props?.name} className="block text-sm font-medium text-gray-700 mb-1">
        {props?.label}
      </label>
      <input
        type={props?.type}
        name={props?.name}
        id={props?.name}
        autoComplete={props?.name}
        value={props?.value}
        onChange={props?.onChange}
        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
      />
      {props?.error && <p className="text-red-500 text-xs italic">{props?.error}</p>}
    </div>
  )
}

export default CustomTextBox;