import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CustomTextBox from '../common/CustomTextBox';
import CustomSelectBox from '../common/CustomSelectBox';
import isEmpty, {isNumber, isFloat} from "../../helpers/validation";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const EditBuildingDetails = ({ setCurrentTab, collegeBuildingData }) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [buildingOwnerships, setBuildingOwnerships] = useState([]);
  const [buildingTypes, setBuildingTypes] = useState([]);
  const [classroomsStatus, setClassroomsStatus] = useState([]);

  const [collegeBuildingDetails, setCollegeBuildingDetails] = React.useState({
    area_of_plot: '',
    ownership: '',
    type_of_building: '',
    total_no_of_rooms: '',
    total_no_of_class_rooms: '',
    total_no_of_admin_office_rooms: '',
    no_of_congested_class_rooms: '',
    total_seating_capacity_of_college: '',
    average_seating_capacity_of_class_rooms: '',
    status_of_class_rooms: '',
    corridor_used_as_class_rooms: '',
    office: '',
    staff_room: '',
    students_common_room: '',
    store: '',
    library: '',
    reading_room: '',
    gymnasium: '',
    auditorium: '',
    canteen: '',
  });

  const [collegeBuildingErrors, setCollegeBuildingErrors] = React.useState({
    area_of_plot: '',
    ownership: '',
    type_of_building: '',
    total_no_of_rooms: '',
    total_no_of_class_rooms: '',
    total_no_of_admin_office_rooms: '',
    no_of_congested_class_rooms: '',
    total_seating_capacity_of_college: '',
    average_seating_capacity_of_class_rooms: '',
    status_of_class_rooms: '',
    corridor_used_as_class_rooms: '',
    office: '',
    staff_room: '',
    students_common_room: '',
    store: '',
    library: '',
    reading_room: '',
    gymnasium: '',
    auditorium: '',
    canteen: '',
    labs_available: '',
    facilities_available: '',
  });

  const getBuildingOwnerships = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getBuildingOwnerships`);
    if (res?.data?.data) {
      await setBuildingOwnerships(res?.data?.data);
    }
  }

  const getBuildingTypes = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getBuildingTypes`);
    if (res?.data?.data) {
      await setBuildingTypes(res?.data?.data);
    }
  }

  const getClassroomsStatus = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getClassroomsStatus`);
    if (res?.data?.data) {
      await setClassroomsStatus(res?.data?.data);
    }
  }

  const handleBack = () => {
    setCurrentTab('main');
  };

  const updateCollegeBuildingData = async (college_id, college_building_data) => {
    const data = {
        college_id,
        college_building_data,
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeBuildingData`, data);
    return res;
  }

  const handleClick = async () => {
    setButtonDisabled(true);
    setCollegeBuildingErrors({});

      if (isEmpty(collegeBuildingDetails?.area_of_plot?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                area_of_plot: "Area of Plot is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.ownership?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                ownership: "Ownership is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.type_of_building?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                type_of_building: "Type of Building is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.total_no_of_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_no_of_rooms: "Total no of Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.total_no_of_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_no_of_class_rooms: "Total no of Class Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.total_no_of_admin_office_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_no_of_admin_office_rooms: "Total no of Admin Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.no_of_congested_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                no_of_congested_class_rooms: "Total no of Congested Class Rooms are required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.total_seating_capacity_of_college?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                total_seating_capacity_of_college: "Total Seating Capacity of College is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.average_seating_capacity_of_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                average_seating_capacity_of_class_rooms: "Average Seating Capacity of College is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.status_of_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                status_of_class_rooms: "Status of Classrooms is required",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.corridor_used_as_class_rooms?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                corridor_used_as_class_rooms: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.office?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                office: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.staff_room?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                staff_room: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.students_common_room?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                students_common_room: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.store?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                store: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.library?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                library: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.reading_room?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                reading_room: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.gymnasium?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                gymnasium: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.auditorium?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                auditorium: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else if (isEmpty(collegeBuildingDetails?.canteen?.trim())) {
        setCollegeBuildingErrors((preValue) => {
            return {
                ...preValue,
                canteen: "Please select one option",
            }
        });
        setButtonDisabled(false);
      } else {
        await updateCollegeBuildingData(params?.id, collegeBuildingDetails);
        await setButtonDisabled(false);
        await setCurrentTab('main');
      }
  };

  const handleChangeBuildingData = (e) => {
    const {name, value} = e.target;
    console.log(name, value);

    if (name === "area_of_plot") {
      if (isFloat(value)) {
        setCollegeBuildingDetails((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else if (name === 'labs_available' || name === 'facilities_available') {
      setCollegeBuildingDetails((preValue) => {
        return {
          ...preValue,
          [name]: [...preValue[name], value],
        }
      });
    } else {
      setCollegeBuildingDetails((preValue) => {
        return {
          ...preValue,
          [name]: value,
        }
      });
    }
  }

          
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      await getBuildingOwnerships();
      await getBuildingTypes();
      await getClassroomsStatus();

      setCollegeBuildingDetails({
        area_of_plot: collegeBuildingData[0]?.area_of_plot,
        ownership: collegeBuildingData[1]?.ownership,
        type_of_building: collegeBuildingData[2]?.type_of_building,
        total_no_of_rooms: collegeBuildingData[3]?.no_of_rooms,
        total_no_of_class_rooms: collegeBuildingData[4]?.no_of_classrooms,
        total_no_of_admin_office_rooms: collegeBuildingData[5]?.no_of_admin_offices,
        no_of_congested_class_rooms: collegeBuildingData[7]?.no_of_congested_classrooms,
        total_seating_capacity_of_college: collegeBuildingData[8]?.total_seating_capacity_of_college,
        average_seating_capacity_of_class_rooms: collegeBuildingData[9]?.average_seating_capacity_of_classrooms,
        status_of_class_rooms: collegeBuildingData[6]?.status_of_class_rooms,
        corridor_used_as_class_rooms: collegeBuildingData[10]?.corridor_used_as_classroom,
        office: collegeBuildingData[11]?.office,
        staff_room: collegeBuildingData[12]?.staff_room,
        students_common_room: collegeBuildingData[13]?.students_common_room,
        store: collegeBuildingData[14]?.store,
        library: collegeBuildingData[15]?.library,
        reading_room: collegeBuildingData[16]?.reading_room,
        gymnasium: collegeBuildingData[17]?.gymnasium,
        auditorium: collegeBuildingData[18]?.auditorium,
        canteen: collegeBuildingData[19]?.canteen,
      });
    }
    getData();
  } ,[]);


  return (
    <div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6 bg-sky-800 flex justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">EDIT COLLEGE BUILDING DETAILS</h3> 
                <button onClick={() => setCurrentTab('main')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1">
                    X
                </button> 
            </div>
            <div className="border-t border-gray-200">
            <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
                  <div className="">
                    <dl>
                    <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                      <div className="">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <CustomTextBox
                            label="Area of Plot"
                            name="area_of_plot"
                            type="text"
                            value={collegeBuildingDetails?.area_of_plot}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.area_of_plot && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.area_of_plot}</p>}

                          <CustomSelectBox
                            label="Ownership"
                            name="ownership"
                            value={collegeBuildingDetails?.ownership}
                            options={buildingOwnerships}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.ownership && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.ownership}</p>}

                          <CustomSelectBox
                            label="Type of Building"
                            name="type_of_building"
                            value={collegeBuildingDetails?.type_of_building}
                            options={buildingTypes}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.type_of_building && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.type_of_building}</p>}

                          <CustomTextBox
                            label="Total No. of Rooms"
                            name="total_no_of_rooms"
                            type="number"
                            value={collegeBuildingDetails?.total_no_of_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_no_of_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_no_of_rooms}</p>}

                          <CustomTextBox
                            label="Total No. of Class Rooms"
                            name="total_no_of_class_rooms"
                            type="number"
                            value={collegeBuildingDetails?.total_no_of_class_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_no_of_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_no_of_class_rooms}</p>}

                          <CustomTextBox
                            label="Total No. of Admin Office Rooms"
                            name="total_no_of_admin_office_rooms"
                            type="number"
                            value={collegeBuildingDetails?.total_no_of_admin_office_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_no_of_admin_office_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_no_of_admin_office_rooms}</p>}

                          <CustomSelectBox
                            label="Status of Class Rooms"
                            name="status_of_class_rooms"
                            value={collegeBuildingDetails?.status_of_class_rooms}
                            options={classroomsStatus}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.status_of_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.status_of_class_rooms}</p>}

                          <CustomTextBox
                            label="No. of Congested Class Rooms"
                            name="no_of_congested_class_rooms"
                            type="number"
                            value={collegeBuildingDetails?.no_of_congested_class_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.no_of_congested_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.no_of_congested_class_rooms}</p>}

                          <CustomTextBox
                            label="Total Seating Capacity of College"
                            name="total_seating_capacity_of_college"
                            type="number"
                            value={collegeBuildingDetails?.total_seating_capacity_of_college}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.total_seating_capacity_of_college && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.total_seating_capacity_of_college}</p>}

                          <CustomTextBox
                            label="Average Seating Capacity of Class Rooms"
                            name="average_seating_capacity_of_class_rooms"
                            type="number"
                            value={collegeBuildingDetails?.average_seating_capacity_of_class_rooms}
                            onChange={handleChangeBuildingData}
                          />
                          {collegeBuildingErrors?.average_seating_capacity_of_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.average_seating_capacity_of_class_rooms}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Varenda / Corridor used as Class Rooms</legend>
                            </div>
                            <div className="mt-4 flex">
                              <div className="flex items-center mr-4">
                                <input
                                  id="corridor_used_as_class_rooms_yes"
                                  name="corridor_used_as_class_rooms"
                                  value="1"
                                  checked={collegeBuildingDetails?.corridor_used_as_class_rooms === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="corridor_used_as_class_rooms_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Available
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="corridor_used_as_class_rooms_no"
                                  name="corridor_used_as_class_rooms"
                                  value="2"
                                  checked={collegeBuildingDetails?.corridor_used_as_class_rooms === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="corridor_used_as_class_rooms_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  Not Available
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.corridor_used_as_class_rooms && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.corridor_used_as_class_rooms}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <div className="text-base font-medium text-gray-700 text-left">Office</div>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="office_yes"
                                  name="office"
                                  value="1"
                                  checked={collegeBuildingDetails?.office === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="office_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="office_no"
                                  name="office"
                                  value="2"
                                  checked={collegeBuildingDetails?.office === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="office_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.office && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.office}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Staff Room</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="staff_room_yes"
                                  name="staff_room"
                                  value="1"
                                  checked={collegeBuildingDetails?.staff_room === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="staff_room_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="staff_room_no"
                                  name="staff_room"
                                  value="2"
                                  checked={collegeBuildingDetails?.staff_room === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="staff_room_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.staff_room && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.staff_room}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Students Common Room</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="students_common_room_yes"
                                  name="students_common_room"
                                  value="1"
                                  checked={collegeBuildingDetails?.students_common_room === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="students_common_room_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="students_common_room_no"
                                  name="students_common_room"
                                  value="2"
                                  checked={collegeBuildingDetails?.students_common_room === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="students_common_room_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.students_common_room && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.students_common_room}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Store</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="store_yes"
                                  name="store"
                                  value="1"
                                  checked={collegeBuildingDetails?.store === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="store_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="store_no"
                                  name="store"
                                  value="2"
                                  checked={collegeBuildingDetails?.store === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="store_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.store && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.store}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Library</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="library_yes"
                                  name="library"
                                  value="1"
                                  checked={collegeBuildingDetails?.library === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="library_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="library_no"
                                  name="library"
                                  value="2"
                                  checked={collegeBuildingDetails?.library === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="library_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.library && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.library}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Reading Room</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="reading_room_yes"
                                  name="reading_room"
                                  type="radio"
                                  value="1"
                                  checked={collegeBuildingDetails?.reading_room === '1' ? true : false}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="reading_room_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="reading_room_no"
                                  name="reading_room"
                                  type="radio"
                                  value="2"
                                  checked={collegeBuildingDetails?.reading_room === '2' ? true : false}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="reading_room_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.reading_room && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.reading_room}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Gymnasium</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="gymnasium_yes"
                                  name="gymnasium"
                                  value="1"
                                  checked={collegeBuildingDetails?.gymnasium === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="gymnasium_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="gymnasium_no"
                                  name="gymnasium"
                                  value="2"
                                  checked={collegeBuildingDetails?.gymnasium === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="gymnasium_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.gymnasium && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.gymnasium}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Auditorium</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="auditorium_yes"
                                  name="auditorium"
                                  value="1"
                                  checked={collegeBuildingDetails?.auditorium === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="auditorium_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="auditorium_no"
                                  name="auditorium"
                                  value="2"
                                  checked={collegeBuildingDetails?.auditorium === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="auditorium_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.auditorium && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.auditorium}</p>}

                          <fieldset className="mt-8 w-full">
                            <div>
                              <legend className="text-base font-medium text-gray-700 text-left">Canteen</legend>
                            </div>
                            <div className="mt-4 flex items-center">
                              <div className="flex items-center mr-4">
                                <input
                                  id="canteen_yes"
                                  name="canteen"
                                  value="1"
                                  checked={collegeBuildingDetails?.canteen === '1' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="canteen_yes" className="ml-3 block text-sm font-medium text-gray-700">
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center ml-4">
                                <input
                                  id="canteen_no"
                                  name="canteen"
                                  value="2"
                                  checked={collegeBuildingDetails?.canteen === '2' ? true : false}
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  onChange={handleChangeBuildingData}
                                />
                                <label htmlFor="canteen_no" className="ml-3 block text-sm font-medium text-gray-700">
                                  No
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          {collegeBuildingErrors?.canteen && <p className="text-red-500 text-xs italic">{collegeBuildingErrors?.canteen}</p>}

                          <div>
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                disabled={buttonDisabled}
                                sx={{ mt: 5, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                            >
                                {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }}  /> : 'Save'}
                            </Button>
                            <Button
                                disabled={buttonDisabled}
                                onClick={handleBack}
                                sx={{ mt: 5, mr: 1, color: "#0369a1 !important" }}
                            >
                                Back
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    </dl>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditBuildingDetails;
