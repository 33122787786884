import React, { useState, useEffect } from 'react';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import ProfilingOfCollege from './ProfilingOfCollege';
import BuildingDetails from './BuildingDetails';
import Enrollment from './Enrollment';
import Labs from './Labs';
import Facilities from './Facilities';
import StaffStudents from './StaffStudents';
import EditCollegeProfile from './EditCollegeProfile';
import EditBuildingDetails from './EditBuildingDetails';
import EditEnrollment from './EditEnrollment';
import EditLabs from './EditLabs';
import EditFacilities from './EditFacilities';
import EditTeachingStaff from './EditTeachingStaff';
import EditNonTeachingStaff from './EditNonTeachingStaff';
import EditStudentsAdmitted from './EditStudentsAdmitted';
import EditStudentsAppeared from './EditStudentsAppeared';
import EditStudentsPassed from './EditStudentsPassed';
import EditSalaryFeeStructure from './EditSalaryFeeStructure';

const CollegeProfile = () => {
  const params = useParams();
  const [currentTab, setCurrentTab] = useState('main');
  const user = getUser(localStorage?.jwtToken);

  const [collegeProfileData, setCollegeProfileData] = useState([]);
  const [collegeBuildingData, setCollegeBuildingData] = useState([]);
  const [collegeClassEnrollmentData, setCollegeClassEnrollmentData] = useState([]);
  const [collegeDegreeEnrollmentData, setCollegeDegreeEnrollmentData] = useState([]);
  const [collegeFacilitiesData, setCollegeFacilitiesData] = useState([]);
  const [collegeLabsData, setCollegeLabsData] = useState([]);
  const [collegeStaffStudentsData, setCollegeStaffStudentsData] = useState([]);
       
  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
    }
    getData();
  } ,[]);


  return (
    <div>
      {currentTab === 'main' && (
        <>
          <ProfilingOfCollege
            setCurrentTab={setCurrentTab}
            collegeProfileData={collegeProfileData}
            setCollegeProfileData={setCollegeProfileData}
          />
          <BuildingDetails
            setCurrentTab={setCurrentTab}
            collegeBuildingData={collegeBuildingData}
            setCollegeBuildingData={setCollegeBuildingData}
          />
          <Enrollment
            setCurrentTab={setCurrentTab}
            collegeClassEnrollmentData={collegeClassEnrollmentData}
            setCollegeClassEnrollmentData={setCollegeClassEnrollmentData}
            collegeDegreeEnrollmentData={collegeDegreeEnrollmentData}
            setCollegeDegreeEnrollmentData={setCollegeDegreeEnrollmentData}
            />
          <Labs
            setCurrentTab={setCurrentTab}
            collegeLabsData={collegeLabsData}
            setCollegeLabsData={setCollegeLabsData}
          />
          <Facilities
            setCurrentTab={setCurrentTab}
            collegeFacilitiesData={collegeFacilitiesData}
            setCollegeFacilitiesData={setCollegeFacilitiesData}
          />
          <StaffStudents
            setCurrentTab={setCurrentTab}
            collegeStaffStudentsData={collegeStaffStudentsData}
            setCollegeStaffStudentsData={setCollegeStaffStudentsData}
          />
        </>
      )}

      {currentTab === 'editCollegeProfile' && (
        <>
          <EditCollegeProfile
            setCurrentTab={setCurrentTab}
            collegeProfileData={collegeProfileData}
            setCollegeProfileData={setCollegeProfileData}
          />
        </>
      )}

      {currentTab === 'editBuildingDetails' && (
        <>
          <EditBuildingDetails
            setCurrentTab={setCurrentTab}
            collegeBuildingData={collegeBuildingData}
            setCollegeBuildingData={setCollegeBuildingData}
          />
        </>
      )}

      {currentTab === 'editEnrollment' && (
        <>
          <EditEnrollment
            setCurrentTab={setCurrentTab}
            genderId={collegeProfileData[3]?.college_gender}
            collegeClassEnrollmentData={collegeClassEnrollmentData}
            setCollegeClassEnrollmentData={setCollegeClassEnrollmentData}
            collegeDegreeEnrollmentData={collegeDegreeEnrollmentData}
            setCollegeDegreeEnrollmentData={setCollegeDegreeEnrollmentData}
          />
        </>
      )}

      {currentTab === 'editLabs' && (
        <>
          <EditLabs
            setCurrentTab={setCurrentTab}
            collegeLabsData={collegeLabsData}
            setCollegeLabsData={setCollegeLabsData}
          />
        </>
      )}

      {currentTab === 'editFacilities' && (
        <>
          <EditFacilities
            setCurrentTab={setCurrentTab}
            collegeFacilitiesData={collegeFacilitiesData}
            setCollegeFacilitiesData={setCollegeFacilitiesData}
          />
        </>
      )}

      {currentTab === 'editTeachingStaff' && (
        <>
          <EditTeachingStaff
            setCurrentTab={setCurrentTab}
            collegeStaffStudentsData={collegeStaffStudentsData}
            setCollegeFacilitiesData={setCollegeStaffStudentsData}
          />
        </>
      )}

      {currentTab === 'editNonTeachingStaff' && (
        <>
          <EditNonTeachingStaff
            setCurrentTab={setCurrentTab}
            collegeStaffStudentsData={collegeStaffStudentsData}
            setCollegeFacilitiesData={setCollegeStaffStudentsData}
          />
        </>
      )}

      {currentTab === 'editStudentsAdmitted' && (
        <>
          <EditStudentsAdmitted
            setCurrentTab={setCurrentTab}
            collegeStaffStudentsData={collegeStaffStudentsData}
            setCollegeFacilitiesData={setCollegeStaffStudentsData}
          />
        </>
      )}

      {currentTab === 'editStudentsAppeared' && (
        <>
          <EditStudentsAppeared
            setCurrentTab={setCurrentTab}
            collegeStaffStudentsData={collegeStaffStudentsData}
            setCollegeFacilitiesData={setCollegeStaffStudentsData}
          />
        </>
      )}

      {currentTab === 'editStudentsPassed' && (
        <>
          <EditStudentsPassed
            setCurrentTab={setCurrentTab}
            collegeStaffStudentsData={collegeStaffStudentsData}
            setCollegeFacilitiesData={setCollegeStaffStudentsData}
          />
        </>
      )}

      {currentTab === 'editSalaryFeeStructure' && (
        <>
          <EditSalaryFeeStructure
            setCurrentTab={setCurrentTab}
            collegeStaffStudentsData={collegeStaffStudentsData}
            setCollegeFacilitiesData={setCollegeStaffStudentsData}
          />
        </>
      )}
    </div>
  )
}

export default CollegeProfile;
