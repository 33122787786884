import React from 'react';

const CustomSelectBox = (props) => {
  return (
    <div className={props?.className ? props?.className : "mt-4 w-full"}>
      <label htmlFor={props?.name} className="block text-sm font-medium text-gray-700 mb-1">
        {props?.label}
      </label>
      <select
        id={props?.name}
        name={props?.name}
        autoComplete={props?.name}
        value={props?.value}
        onChange={props?.onChange}
        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 sm:text-sm"
      >
        <option aria-label="None" value="" />
        {props?.options.map((option, index) => (
          <option key={index} value={option?.value}>{option?.label}</option>
        ))}
      </select>
      {props?.error && <p className="text-red-500 text-xs italic">{props?.error}</p>}
    </div>
  )
}

export default CustomSelectBox;