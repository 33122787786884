import React from 'react';
import "./../styles.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const data = [
    {
        s_no: "(1)",
        title: "The Institution shall run in accordance with the provisions of the Sindh Private Educational Institutions (Regulation and Control) Ordinance, 2001/Amended Act 2003, and rules 2005.",
    },
    {
        s_no: "(2)",
        title: "No person of foreign origin shall be appointed to any post in or admitted as a student to the institution without prior approval of the Government.",
    },
    {
        s_no: "(3)",
        title: "The Institution shall not teach or cause to be taught any subject or matter, which repugnan to the generally accepted social, moral, ideological norms of the society and religious values of Islam.",
    },
    {
        s_no: "(4)",
        title: "The institution shall:",
    },
    {
        s_no: "I",
        title: "Ensure transparent and judicious policy of admission without discrimination on the basis of religion, sex, color and creed;",
    },
    {
        s_no: "II",
        title: "Ensure admission to class 1 on the basis of a birth certificate of the child and to other classes on the basis of the certificate of last school attended by the student;",
    },
    {
        s_no: "III",
        title: "Ensure that admission fee is charged from the student only at the time of his first admission into the institution and such fee shall not be more than three months tuition fees of the respective class in which the student is given admission.",
    },
    {
        s_no: "IV",
        title: "Maintain General Registers and Accounts Books as may be specified by the Registering Authority;",
    },
    {
        s_no: "V",
        title: "Ensure that no amendment in school register is made without the approval of the Registering Authority.",
    },
    {
        s_no: "VI",
        title: "Ensure regular audit of account annually by recognized auditor and submission of authenticated copy of audited accounts to the Registering authority at the end of each academic year;",
    },
    {
        s_no: "VII",
        title: "Ensure that no corporal punishment in any form is inflicted on the students;",
    },
    {
        s_no: "VIII",
        title: "Ensure maximum participation of the students in various extracurricular activities organized in schools of district/provincial and national level;",
    },
    {
        s_no: "IX",
        title: "Ensure payment of remuneration of its staff every month including the vacation period through cross cheque;",
    },
    {
        s_no: "X",
        title: "Regulate the service matter of its staff under the service regulations made by the institution on the basis of guidelines issued by the Registering Authority and services of any employee shall not be terminated without affording an opportunity of being heard;",
    },
    {
        s_no: "XI",
        title: "Observe the time table and duration of studies submitted along with the application of registration and where any change is made, the same shall be immediately notified to the registering authority for information and approval.",
    },
    {
        s_no: "XII",
        title: "Ensure Eetablishment of endowment fund or furnishing a bank security to run the institution and meet the expenses liabilities for at least one academic year in case institution suffers any loss on account of any legal, managerial or financial issue;",
    },
    {
        s_no: "XIII",
        title: "Ensure teaching through prescribed books duly approved by the department of Education and Literacy in the institution, along with such supplementary reading materials as approved by the Registering Authority.",
    },
    {
        s_no: "XIV",
        title: "Constitute the Parent-Teacher Association (PTA) with representative of parents, teachers and the management;",
    },
    {
        s_no: "XV",
        title: "Ensure that the policy of Admission is communicated to the Education Department before the commencement of the academic year;",
    },
    {
        s_no: "XVI",
        title: "Observe all holidays and vacations as approved, announced or notified by the Department of Education and Literacy or Government.",
    },
    {
        s_no: "XVII",
        title: "Ensure that the institution shall provide and maintain required infrastructure including building, class rooms, laboratory, library, play ground, canteen and safe drinking water facilities as prescribed and approved by Registration Authority:",
    },
    {
        s_no: "XVIII",
        title: "Ensure that pay scales, allowances, leaves and other benefits admissible to the teachers and other staff shall be commensurate with the institution's fee structure and in accordance with the existing law and rules;",
    },
    {
        s_no: "XIX",
        title: "Ensure that the curriculum taught in an Institution shall be at least, at par with the curriculum approved by Government; and",
    },
    {
        s_no: "XX",
        title: "That the institution shall ensure teaching of Sindhi language in accordance with the law and rules.",
    },
    {
        s_no: "XXI",
        title: "Security at school is to be provided and no charges for this would be charged from students.",
    },
    {
        s_no: "XXII",
        title: "That the Institution shall ensure teaching of Nazra Quran in accordance with the law and rules.",
    },
    {
        s_no: "XXIII",
        title: "Ensure all security measure inside & outside school premises without extra charges.",
    },
    {
        s_no: "XXIV",
        title: 'As per provision of the clause 3.(3) of THE SINDH RIGHT OF CHILDREN TO FREE AND COMPULSORY EDUCATION ACT, 2013 "Privately owned or managed schools shall also provide free education to such students of the age of five to sixteen years at least ten percent of their actual strength of students".',
    },
]

const generatePDF = async () => {
    const input = document.getElementById("print");
    html2canvas(input)
        .then((canvas) => {
            var imgData = canvas.toDataURL('image/png');

            /*
            Here are the numbers (paper width and height) that I found to work. 
            It still creates a little overlap part between the pages, but good enough for me.
            if you can find an official number from jsPDF, use them.
            */
            var imgWidth = 210;
            var pageHeight = 297;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var doc = new jsPDF('p', 'mm');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('undertaking.pdf');

        });

}

const handleClick = async () => {
    await generatePDF();
}

const Undertaking = () => {
    return (
        <>
            <div className="my-application-header">
                <div style={{ marginTop: '1%', fontSize: '2.3vw', fontWeight: 'bold' }} className="my-application-header-title-full">
                    U N D E R&nbsp;&nbsp;&nbsp;&nbsp;T A K I N G
                </div>
            </div>
            <div style={{ marginTop: '1%' }} className='my-application-header'>
                <div style={{ width: '5%' }}></div>
                <p style={{ fontWeight: '500', fontSize: '1.2vw' }}>
                    <u>
                        We Undertake To Abide By The Following Conditions For Registration Of School:-
                    </u>
                </p>
            </div>
            {data?.map((item, index) => (
                <>
                    <div style={{ marginBottom: '1%', display: 'flex', width: '100%', fontWeight: '500', fontSize: '1.1vw' }} key={index}>
                        <div style={{ width: '5%' }}>{item?.s_no}</div>
                        <div style={{ width: '95%' }}>{item?.title}</div>
                    </div>
                </>
            ))}
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ width: '5%' }}></div>
                <h4 style={{ fontWeight: '600', fontSize: '1.3vw' }}>
                    Note: Please ensure photo copy of the CNIC of the President / G. Secretary / CEO / Owner and Principal of the Institution
                </h4>
            </div>
            <div style={{ marginTop: "5%" }} className="my-application-footer1">
                <div className="my-application-footer1-left">
                    <p style={{ fontWeight: '400' }}>
                        Seal {"&"} Signature Of The General Secretary <br /> Owner Of The Institution
                    </p>
                    <div style={{ fontSize: '1.1vw', marginTop: '3%', fontWeight: '400' }}>
                        CNIC: ______________________________
                    </div>
                </div>
                <div className="my-application-footer1-right">
                    <p style={{ fontWeight: '400' }}>
                        Seal {"&"} Signature Of the Principal
                    </p>
                    <div style={{ fontSize: '1.1vw', marginTop: '5%', fontWeight: '400' }}>
                        CNIC: ______________________________
                    </div>
                </div>
            </div>

        </>
    )
}

export default Undertaking;