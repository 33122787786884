import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import addWeeks from 'date-fns/addWeeks';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import CircularProgress from '@mui/material/CircularProgress';
import CustomTextBox from '../common/CustomTextBox';
import CustomSelectBox from '../common/CustomSelectBox';
import isEmpty, {isCNIC, isNumber, isFloat, isEmail, isNumberOrDash} from "../../helpers/validation";
import axios from 'axios';

function getWeeksAfter(date, amount) {
  return date ? addWeeks(date, amount) : undefined;
}

export default function AddStaff() {
  const [value, setValue] = useState([null, null]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [genders, setGenders] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [domicileDistricts, setDomicileDistricts] = useState([]);
  const [teachingStatus, setTeachingStatus] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);

  const [profileImageView, setProfileImageView] = useState();
  const [joiningReportImageView, setJoiningReportImageView] = useState();
  const [appOrderImageView, setAppOrderImageView] = useState();
  const [degreeImageView, setDegreeImageView] = useState();

  const [imageData, setImageData] = useState({
    profile_image_name: '',
    profile_image: '',
    joining_report_image_name: '',
    joining_report_image: '',
    app_order_image_name: '',
    app_order_image: '',
    degree_image_name: '',
    degree_image: '',
  });

  const [imageDataErrors, setImageDataErrors] = useState({
    profile_image: '',
    joining_report_image: '',
    app_order_image: '',
    degree_image: '',
  });

  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    father_name: '',
    family_name: '',
    cnic: '',
    date_of_birth: '',
    email: '',
    contact_no: '',
    home_contact_no: '',
    relative_contact_no: '',
    gender: '',
    marital_status: '',
    disability: '',
    languages: '',
  });

  const [personalInfoErrors, setPersonalInfoErrors] = useState({
    name: '',
    father_name: '',
    family_name: '',
    cnic: '',
    date_of_birth: '',
    email: '',
    contact_no: '',
    home_contact_no: '',
    relative_contact_no: '',
    gender: '',
    marital_status: '',
    disability: '',
    languages: '',
  });

  const [addressInfo, setAddressInfo] = useState({
    region: '',
    district: '',
    town: '',
    address: '',
  });

  const [addressInfoErrors, setAddressInfoErrors] = useState({
    region: '',
    district: '',
    town: '',
    address: '',
  });

  const [academicInfo, setAcademicInfo] = useState({
    qualification: '',
    passing_year: '',
    domicile_district: '',
    domicile_type: '',
  });

  const [academicInfoErrors, setAcademicInfoErrors] = useState({
    qualification: '',
    passing_year: '',
    domicile_district: '',
    domicile_type: '',
  });

  const [jobInfo, setJobInfo] = useState({
    joining_date: '',
    teaching_status: '',
    subject: '',
  });

  const [jobInfoErrors, setJobInfoErrors] = useState({
    joining_date: '',
    teaching_status: '',
    subject: '',
  });

  const handleImage = async (e) => {
    const {name, value} = e.target;
    if (name === "profile_image") {
      const img = e.target.files[0];
      setProfileImageView(URL.createObjectURL(img));
      setImageData({
        ...imageData,
        profile_image_name: e.target.files[0].name,
        profile_image: e.target.files[0],
      });
    } else if (name === "joining_report_image") {
      const img = e.target.files[0];
      setJoiningReportImageView(URL.createObjectURL(img));
      setImageData({
        ...imageData,
        joining_report_image_name: e.target.files[0].name,
        joining_report_image: e.target.files[0],
      })
    } else if (name === "app_order_image") {
      const img = e.target.files[0];
      setAppOrderImageView(URL.createObjectURL(img));
      setImageData({
        ...imageData,
        app_order_image_name: e.target.files[0].name,
        app_order_image: e.target.files[0],
      })
    } else if (name === "degree_image") {
      const img = e.target.files[0];
      setDegreeImageView(URL.createObjectURL(img));
      setImageData({
        ...imageData,
        degree_image_name: e.target.files[0].name,
        degree_image: e.target.files[0],
      })
    }
  }

  const handleChangePersonalInfo = (e) => {
    const {name, value} = e.target;

    if (name === 'contact_no' || name === 'home_contact_no' || name === 'relative_contact_no') {
      if ((isNumber(value) || value === '') && value.length < 12){
        setPersonalInfo((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else if (name === 'cnic') {
      if ((isNumberOrDash(value) || value === '') && value.length < 16){
        setPersonalInfo((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else {
      setPersonalInfo((preValue) => {
        return {
          ...preValue,
          [name]: value,
        }
      });
    }

    
  }

  const handleChangeAddressInfo = async (e) => {
    const {name, value} = e.target;

    if (name === 'region') {
      await setAddressInfo({...addressInfo, district: '', town: ''});
      await setDistricts([]);
      await setTowns([]);
      await getDistrictsByRegionId(value);
    }

    if (name === 'district') {
      await setAddressInfo({...addressInfo, town: ''});
      await setTowns([]);
      await getTownsByDistrictId(value);
    }

    setAddressInfo((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const handleChangeAcademicInfo = (e) => {
    const {name, value} = e.target;

    if (name === 'passing_year') {
      if ((isNumber(value) || value === '') && value.length < 5){
        setAcademicInfo((preValue) => {
          return {
            ...preValue,
            [name]: value,
          }
        });
      }
    } else {
      setAcademicInfo((preValue) => {
        return {
          ...preValue,
          [name]: value,
        }
      });
    }

  }

  const handleChangeJobInfo = (e) => {
    const {name, value} = e.target;

    setJobInfo((preValue) => {
      return {
        ...preValue,
        [name]: value,
      }
    });
  }

  const getGenders = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getGenders`);
    if (res?.data?.data) {
      await setGenders(res?.data?.data);
    }
  }

  const getMaritalStatus = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getMaritalStatus`);
    if (res?.data?.data) {
      await setMaritalStatus(res?.data?.data);
    }
  }

  const getQualifications = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getQualifications`);
    if (res?.data?.data) {
      await setQualifications(res?.data?.data);
    }
  }

  const getDistricts = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getDistricts`);
    if (res?.data?.data) {
      await setDomicileDistricts(res?.data?.data);
    }
  }

  const getTeachingStatus = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getTeachingStatus`);
    if (res?.data?.data) {
      await setTeachingStatus(res?.data?.data);
    }
  }

  const getSubjects = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getSubjects`);
    if (res?.data?.data) {
      await setSubjects(res?.data?.data);
    }
  }

  const getRegions = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getRegions`);
    if (res?.data?.data) {
      await setRegions(res?.data?.data);
    }
  }

  const getDistrictsByRegionId = async (region_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getDistrictsByRegionId/${region_id}`);
    if (res?.data?.data) {
      await setDistricts(res?.data?.data);
    }
  }

  const getTownsByDistrictId = async (district_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/getTownsByDistrictId/${district_id}`);
    if (res?.data?.data) {
      await setTowns(res?.data?.data);
    }
  }

  const checkCnic = async (cnic) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/checkCnic/${cnic}`);
    return res?.data?.data;
  }

  const checkContactNo = async (contact_no) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/checkContactNo/${contact_no}`);
    return res?.data?.data;
  }

  const checkEmail = async (email) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/staffs/checkEmail/${email}`);
    return res?.data?.data;
  }

  const handleClick = async (e) => {
    e.preventDefault();
    await setButtonDisabled(true);
    await setPersonalInfoErrors({});
    await setAddressInfoErrors({});
    await setAcademicInfoErrors({});
    await setJobInfoErrors({});
    await setImageDataErrors({});

    if (isEmpty(personalInfo?.name?.trim())) {
      setPersonalInfoErrors((preValue) => {
          return {
              ...preValue,
              name: "Name is required",
          }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.father_name?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            father_name: "Father name is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.family_name?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            family_name: "Family name is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.cnic?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            cnic: "Cnic is required",
        }
      });
      setButtonDisabled(false);
    } else if (!isCNIC(personalInfo?.cnic?.trim())) {
      setPersonalInfoErrors((preValue) => {
          return {
              ...preValue,
              cnic: "Cnic is not valid",
          }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.date_of_birth?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            date_of_birth: "Date of Birth is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.email?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            email: "Email is required",
        }
      });
      setButtonDisabled(false);
    } else if (!isEmail(personalInfo?.email?.trim())) {
        setPersonalInfoErrors((preValue) => {
            return {
                ...preValue,
                email: "Email is not valid",
            }
        });
        setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.contact_no?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            contact_no: "Contact no is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.home_contact_no?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            home_contact_no: "Home contact no is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.relative_contact_no?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            relative_contact_no: "Relative contact no is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.gender?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            gender: "Gender is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.marital_status?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            marital_status: "Marital status is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.disability?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            disability: "Disability is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(personalInfo?.languages?.trim())) {
      setPersonalInfoErrors((preValue) => {
        return {
            ...preValue,
            languages: "Languages field is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(imageData?.profile_image?.trim())) {
      setImageDataErrors((preValue) => {
        return {
            ...preValue,
            profile_image: "Profile picture is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(addressInfo?.region?.trim())) {
      setAddressInfoErrors((preValue) => {
        return {
            ...preValue,
            region: "Region is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(addressInfo?.district?.trim())) {
      setAddressInfoErrors((preValue) => {
        return {
            ...preValue,
            district: "District is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(addressInfo?.town?.trim())) {
      setAddressInfoErrors((preValue) => {
        return {
            ...preValue,
            town: "Subdivision is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(addressInfo?.address?.trim())) {
      setAddressInfoErrors((preValue) => {
        return {
            ...preValue,
            address: "Address is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(jobInfo?.joining_date?.trim())) {
      setJobInfoErrors((preValue) => {
        return {
            ...preValue,
            joining_date: "Joining date is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(jobInfo?.teaching_status?.trim())) {
      setJobInfoErrors((preValue) => {
        return {
            ...preValue,
            teaching_status: "Teaching status is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(jobInfo?.subject?.trim()) && jobInfo?.teaching_status === '1') {
      setJobInfoErrors((preValue) => {
        return {
            ...preValue,
            subject: "Subject is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(imageData?.joining_report_image?.trim())) {
      setImageDataErrors((preValue) => {
        return {
            ...preValue,
            joining_report_image: "Joining Report is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(imageData?.app_order_image?.trim())) {
      setImageDataErrors((preValue) => {
        return {
            ...preValue,
            app_order_image: "Appointment Order is required",
        }
      });
      setButtonDisabled(false);
    } else if (isEmpty(imageData?.degree_image?.trim())) {
      setImageDataErrors((preValue) => {
        return {
            ...preValue,
            degree_image: "Degree is required",
        }
      });
      setButtonDisabled(false);
    } else {
        const isCnic = await checkCnic(personalInfo?.cnic?.trim());
        const isContactNo = await checkContactNo(personalInfo?.contact_no?.trim());
        const isEmail = await checkEmail(personalInfo?.email?.trim());

        if (isCnic) {
          setPersonalInfoErrors((preValue) => {
            return {
                ...preValue,
                cnic: "Cnic already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isContactNo) {
          setPersonalInfoErrors((preValue) => {
            return {
                ...preValue,
                contact_no: "Contact no already exists",
            }
          });
          setButtonDisabled(false);
        } else if (isEmail) {
          setPersonalInfoErrors((preValue) => {
            return {
                ...preValue,
                email: "Email already exists",
            }
          });
          setButtonDisabled(false);
        } else {
          let formdata = new FormData();
          formdata.append('profile_image', imageData?.profile_image, imageData?.profile_image_name);
          formdata.append('joining_report_image', imageData?.joining_report_image, imageData?.joining_report_image_name);
          formdata.append('app_order_image', imageData?.app_order_image, imageData?.app_order_image_name);
          formdata.append('degree_image', imageData?.degree_image, imageData?.degree_image_name);
          formdata.append('personal_info', personalInfo);
          formdata.append('address_info', addressInfo);
          formdata.append('academic_info', academicInfo);
          formdata.append('job_info', jobInfo);

          const res = await axios.post('https://www.dgcs.gos.pk/rndibackend/documents/addStaff', formdata, {
              headers: {
                'Authorization': `${localStorage?.getItem('jwtToken')}`
              }});
          // console.log(res.error);
          if (res.data.data[0]) {
            setButtonDisabled(false);
          } else {
            setButtonDisabled(false);
          }
          setButtonDisabled(false);
        }
    }
    
  };

  useEffect(() => {
    getGenders();
    getMaritalStatus();
    getQualifications();
    getDistricts();
    getTeachingStatus();
    getSubjects();
    getRegions();
  }, []);



  return (
    <>

      <div className="mt-10 sm:mt-0">
      <h1 className="text-3xl font-bold mt-5 mb-10 tracking-tight text-sky-800">Add new staff</h1>
        {/* <Link to="/lms/users" className="mt-5 shadow-sm border bg-white text-gray-500 rounded-lg w-40 h-8 md:w-40 md:h-8 flex justify-center items-center mb-10">
          <IoIosArrowBack /> Back
        </Link> */}
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-3">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
              {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <CustomTextBox
                      label="Name"
                      name="name"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.name}
                      error={personalInfoErrors?.name}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Father name"
                      name="father_name"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.father_name}
                      error={personalInfoErrors?.father_name}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Family name"
                      name="family_name"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.family_name}
                      error={personalInfoErrors?.family_name}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Cnic"
                      name="cnic"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.cnic}
                      error={personalInfoErrors?.cnic}
                      onChange={handleChangePersonalInfo}
                    />
                    
                    <CustomTextBox
                      label="Date of Birth"
                      name="date_of_birth"
                      type="date"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.date_of_birth}
                      error={personalInfoErrors?.date_of_birth}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Email address"
                      name="email"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.email}
                      error={personalInfoErrors?.email}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Contact no"
                      name="contact_no"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.contact_no}
                      error={personalInfoErrors?.contact_no}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Home Contact no"
                      name="home_contact_no"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.home_contact_no}
                      error={personalInfoErrors?.home_contact_no}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Relative Contact no"
                      name="relative_contact_no"
                      type="text"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.relative_contact_no}
                      error={personalInfoErrors?.relative_contact_no}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomSelectBox
                      label="Gender"
                      name="gender"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.gender}
                      error={personalInfoErrors?.gender}
                      options={genders}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomSelectBox
                      label="Marital Status"
                      name="marital_status"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.marital_status}
                      error={personalInfoErrors?.marital_status}
                      options={maritalStatus}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomSelectBox
                      label="Disability"
                      name="disability"
                      className="col-span-6 sm:col-span-3"
                      value={personalInfo?.disability}
                      error={personalInfoErrors?.disability}
                      options={[
                        {label: 'Yes', value: 1},
                        {label: 'No', value: 2},
                      ]}
                      onChange={handleChangePersonalInfo}
                    />

                    <CustomTextBox
                      label="Languages"
                      name="languages"
                      type="text"
                      className="col-span-6"
                      value={personalInfo?.languages}
                      error={personalInfoErrors?.languages}
                      onChange={handleChangePersonalInfo}
                    />

                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">Photo</label>
                      <div className="mt-1 flex items-center">
                        {profileImageView ? (
                          <img className="inline-block h-14 w-14 rounded-full overflow-hidden" src={profileImageView} alt="profile-image" />
                        ) : (
                          <span className="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        )}
                        <button
                          htmlFor="profile_image"
                          type="button"
                          className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <input
                            id="profile_image"
                            name="profile_image"
                            type="file"
                            onChange={handleImage}
                          />
                        </button>
                      </div>
                      {imageDataErrors?.profile_image && <p className="text-red-500 text-xs italic">{imageDataErrors?.profile_image}</p>}
                    </div>
                  </div>
                </div>
                {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="mt-10 sm:mt-10">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-3">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Address Information</h3>
              {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <CustomSelectBox
                      label="Address Region"
                      name="region"
                      className="col-span-6"
                      value={addressInfo?.region}
                      error={addressInfoErrors?.region}
                      options={regions}
                      onChange={handleChangeAddressInfo}
                    />

                    <CustomSelectBox
                      label="Address District"
                      name="district"
                      className="col-span-6"
                      value={addressInfo?.district}
                      error={addressInfoErrors?.district}
                      options={districts}
                      onChange={handleChangeAddressInfo}
                    />

                    <CustomSelectBox
                      label="Address Town / Taluka / Sub Division"
                      name="town"
                      className="col-span-6"
                      value={addressInfo?.town}
                      error={addressInfoErrors?.town}
                      options={towns}
                      onChange={handleChangeAddressInfo}
                    />

                    <CustomTextBox
                      label="Address"
                      name="address"
                      type="text"
                      className="col-span-6"
                      value={personalInfo?.address}
                      error={addressInfoErrors?.address}
                      onChange={handleChangeAddressInfo}
                    />

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="mt-10 sm:mt-10">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-3">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Academic Information</h3>
              {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <CustomSelectBox
                      label="Qualification"
                      name="qualification"
                      className="col-span-6"
                      value={academicInfo?.qualification}
                      error={academicInfoErrors?.qualification}
                      options={qualifications}
                      onChange={handleChangeAcademicInfo}
                    />

                    <CustomTextBox
                      label="Passing Year"
                      name="passing_year"
                      type="text"
                      className="col-span-6"
                      value={academicInfo?.passing_year}
                      error={academicInfoErrors?.passing_year}
                      onChange={handleChangeAcademicInfo}
                    />

                    <CustomSelectBox
                      label="Domicile District"
                      name="domicile_district"
                      className="col-span-6"
                      value={academicInfo?.domicile_district}
                      error={academicInfoErrors?.domicile_district}
                      options={domicileDistricts}
                      onChange={handleChangeAcademicInfo}
                    />

                    <CustomSelectBox
                      label="Domicile Type"
                      name="domicile_type"
                      className="col-span-6"
                      value={academicInfo?.domicile_type}
                      error={academicInfoErrors?.domicile_type}
                      options={[
                        {label: 'Urban', value: 1},
                        {label: 'Rural', value: 2},
                      ]}
                      onChange={handleChangeAcademicInfo}
                    />

                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="mt-10 sm:mt-10">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-3">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Job Information</h3>
              {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <CustomTextBox
                      label="Joining Date"
                      name="joining_date"
                      type="date"
                      className="col-span-6"
                      value={jobInfo?.joining_date}
                      error={jobInfoErrors?.joining_date}
                      onChange={handleChangeJobInfo}
                    />

                    <CustomSelectBox
                      label="Teaching Status"
                      name="teaching_status"
                      className="col-span-6"
                      value={jobInfo?.teaching_status}
                      error={jobInfoErrors?.teaching_status}
                      options={teachingStatus}
                      onChange={handleChangeJobInfo}
                    />

                    {jobInfo?.teaching_status === '1' && (
                      <CustomSelectBox
                        label="Subject"
                        name="subject"
                        className="col-span-6"
                        value={jobInfo?.subject}
                        error={jobInfoErrors?.subject}
                        options={subjects}
                        onChange={handleChangeJobInfo}
                      />
                    )}
                    

                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="mt-10 sm:mt-10">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-3">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Upload Documents</h3>
              {/* <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive mail.</p> */}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className='col-span-6'>
                      <label className="block text-sm font-medium text-gray-700">Joining Report</label>
                          <div className={imageDataErrors?.joining_report_image ? "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-red-500 border-dashed rounded-md" : "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"}>
                            <div className="space-y-1 text-center">
                              {joiningReportImageView ? (
                                <img src={joiningReportImageView} alt="joining-report" />
                              ) : (
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                </svg>
                              )}
                                
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="joining_report_image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Upload file</span>
                                  <input
                                    id="joining_report_image"
                                    name="joining_report_image"
                                    type="file"
                                    className="sr-only"
                                    onChange={handleImage}
                                  />
                                </label>
                              </div>
                              <p className="text-xs text-gray-500">jpg, png up to 1MB</p>
                            </div>
                          </div>
                          {imageDataErrors.joining_report_image && <p className="text-red-500 text-xs italic">{imageDataErrors.joining_report_image}</p>}
                    </div>

                    <div className='col-span-6'>
                      <label className="block text-sm font-medium text-gray-700">Appointment Order</label>
                          <div className={imageDataErrors?.app_order_image ? "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-red-500 border-dashed rounded-md" : "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"}>
                            <div className="space-y-1 text-center">
                              {appOrderImageView ? (
                                <img src={appOrderImageView} alt="appointment-order" />
                              ) : (
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                </svg>
                              )}
                                
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="app_order_image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Upload file</span>
                                  <input
                                    id="app_order_image"
                                    name="app_order_image"
                                    type="file"
                                    className="sr-only"
                                    onChange={handleImage}
                                  />
                                </label>
                              </div>
                              <p className="text-xs text-gray-500">jpg, png up to 1MB</p>
                            </div>
                          </div>
                          {imageDataErrors.app_order_image && <p className="text-red-500 text-xs italic">{imageDataErrors.app_order_image}</p>}
                    </div>

                    <div className='col-span-6'>
                      <label className="block text-sm font-medium text-gray-700">Degree</label>
                          <div className={imageDataErrors?.degree_image ? "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-red-500 border-dashed rounded-md" : "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"}>
                            <div className="space-y-1 text-center">
                              {degreeImageView ? (
                                <img src={degreeImageView} alt="degree" />
                              ) : (
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                </svg>
                              )}
                                
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="degree_image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Upload file</span>
                                  <input
                                    id="degree_image"
                                    name="degree_image"
                                    type="file"
                                    className="sr-only"
                                    onChange={handleImage}
                                  />
                                </label>
                              </div>
                              <p className="text-xs text-gray-500">jpg, png up to 1MB</p>
                            </div>
                          </div>
                          {imageDataErrors.degree_image && <p className="text-red-500 text-xs italic">{imageDataErrors.degree_image}</p>}
                    </div>
                    
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    disabled={buttonDisabled}
                    onClick={handleClick}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }}  /> : 'Save'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
