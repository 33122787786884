import React from 'react';
import "./../styles.css";

const Challan = () => {
  return (
    <div style={{ padding: '10%' }}>
      <img className="challan" src="images/challan.jpeg" />
    </div>
  )
}

export default Challan;