import React, { useState, useEffect } from 'react';
import { MdVerified, MdEdit } from 'react-icons/md';
import { CgDanger } from 'react-icons/cg';
import axios from 'axios';
import { getUser } from '../../functions/functions';
import { useParams } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const EditEnrollment = ({ genderId, setCurrentTab, collegeClassEnrollmentData, collegeDegreeEnrollmentData }) => {
  const params = useParams();
  const user = getUser(localStorage?.jwtToken);

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [facultyClassGenderEnrollment, setFacultyClassGenderEnrollment] = useState([]);
  const [collegesDegrees, setCollegesDegrees] = useState([]);
  const [collegeEnrollmentDegreeData, setCollegeEnrollmentDegreeData] = React.useState([]);
  const [collegeEnrollmentData, setCollegeEnrollmentData] = React.useState([]);

  const getCollegesDegrees = async () => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getCollegesDegrees`);
    if (res?.data?.data) {
      await setCollegesDegrees(res?.data?.data);
    }
  }

  const getFacultyClassGenderEnrollment = async (gender_id) => {
    const res = await axios.get(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/getFacultyClassGenderEnrollment/${gender_id}`);
    if (res?.data?.data) {
      await setFacultyClassGenderEnrollment(res?.data?.data);
    }
  }

  const handleChangeCollegeEnrollmentDegreeData = async (e, item) => {
    item.enrollment = e.target.value;
    let name = `${item.id}`;
    await setCollegeEnrollmentDegreeData((preValue) => {
      return {
        ...preValue,
        [name]: item,
      }
    });
  }

  const updateCollegeEnrollment = async (college_id, collegeEnrollmentData, collegeEnrollmentDegreeData) => {
    const data = {
      college_id,
      collegeEnrollmentData: Object.values(collegeEnrollmentData),
      collegeEnrollmentDegreeData: Object.values(collegeEnrollmentDegreeData),
    }
    const res = await axios.post(`https://www.dirpc.gos.pk/privatecollegesbackend/colleges/updateCollegeEnrollmentData`, data);
    return res;
  }

  const handleBack = () => {
    setCurrentTab('main');
  };

  const handleClick = async () => {
    setButtonDisabled(true);
    const college_id = params?.id;
    await updateCollegeEnrollment(params?.id, collegeEnrollmentData, collegeEnrollmentDegreeData);
    await setButtonDisabled(false);
    await setCurrentTab('main');

  };

  const handleChangeCollegeEnrollmentData = async (e, item, index) => {
    item.enrollment = e.target.value;
    let name = `${item.class_id}_${item.faculty_id}_${item.gender_id}`;
    await setCollegeEnrollmentData((preValue) => {
      return {
        ...preValue,
        [name]: item,
      }
    });
  }


  useEffect(() => {
    async function getData() {
      const college_id = params?.id || user?.college_id;
      await getCollegesDegrees();
      await getFacultyClassGenderEnrollment(genderId);
      await collegeClassEnrollmentData?.map((item, index) => {
        let name = `${item.class_id}_${item.faculty_id}_${item.gender_id}`;
        setCollegeEnrollmentData((preValue) => {
          return {
            ...preValue,
            [name]: item,
          }
        });
      });

      await collegeDegreeEnrollmentData?.map((item, index) => {
        let name = `${item.degree_id}`;
        setCollegeEnrollmentDegreeData((preValue) => {
          return {
            ...preValue,
            [name]: item,
          }
        });
      });
    }
    getData();
  }, []);


  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 bg-sky-800 flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-50 mr-4">EDIT ENROLLMENT</h3>
          <button onClick={() => setCurrentTab('main')} style={{ width: "28px", height: "28px", borderRadius: "50%", justifyContent: "center", alignItems: "center" }} className="text-white border flex text-center p-1">
            X
          </button>
        </div>
        <div className="border-t border-gray-200">
          <div className="bg-sky-800 overflow-hidden sm:rounded-lg">
            <div className="">
              <dl>
                <div className="bg-white px-4 pt-5 pb-8 sm:p-6 sm:pb-8">
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="md:grid md:grid-cols-2 md:gap-6">

                        {facultyClassGenderEnrollment?.map((item, index) => (
                          <div key={index} className="mt-2 w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              {`${item?.class} ${item?.faculty} ${item?.gender}`}
                            </label>
                            <input
                              type="number"
                              name={`${item?.class}_${item?.faculty}_${item?.gender}`}
                              value={collegeEnrollmentData[`${item?.class_id}_${item?.faculty_id}_${item?.gender_id}`]?.enrollment}
                              onChange={(e) => handleChangeCollegeEnrollmentData(e, item, index)}
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                          </div>
                        ))}

                        {collegesDegrees?.map((item, index) => (
                          <div key={index} className="mt-2 w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              {`${item?.degree}`}
                            </label>
                            <input
                              type="number"
                              value={collegeEnrollmentDegreeData[`${item?.id}`]?.enrollment}
                              name={`${item?.degree}}`}
                              onChange={(e) => handleChangeCollegeEnrollmentDegreeData(e, item)}
                              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-sky-800 focus:border-sky-800 focus:z-10 sm:text-sm"
                            />
                          </div>
                        ))}

                        <div>
                          <Button
                            variant="contained"
                            onClick={handleClick}
                            disabled={buttonDisabled}
                            sx={{ mt: 5, mr: 1, color: 'white !important', backgroundColor: '#0369a1 !important' }}
                          >
                            {buttonDisabled ? <CircularProgress size={22} sx={{ color: '#fff' }} /> : 'Save'}
                          </Button>
                          <Button
                            disabled={buttonDisabled}
                            onClick={handleBack}
                            sx={{ mt: 5, mr: 1, color: "#0369a1 !important" }}
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditEnrollment;
